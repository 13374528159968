import { getUser, isTeacher } from "../../../core";

import "./style.css";

const updateEduplacesShopID = async () => {
	const user = getUser();
	if (isTeacher()) {
		const schoolId = user.schoolId;
		if(schoolId){
			document.querySelectorAll<HTMLElement>(".buyWithEduplaces").forEach(e => {
				const href =  `https://forms.eduplaces.de/form/TYtqpK6n?ep_school_id=${schoolId}&utm_source=partner`;
				e.setAttribute("href", href);
			});
		}
	}
};

window.addEventListener( "ugm-logged-in", updateEduplacesShopID);
setTimeout(updateEduplacesShopID, 0);
