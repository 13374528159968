import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import mailIconWhite from "../../svg/netbook-mail.svg";
import phoneIconWhite from "../../svg/netbook-phone.svg";
import mailIconBlue from "../../svg/hamburg-mail.svg";
import phoneIconBlue from "../../svg/hamburg-phone.svg";

import netbookLicenseLogo from "../../svg/netbook-license-logo.svg";
import netbookLogoWhite from "../../svg/netbook-deutsch-logo.svg";

@customElement('netbook-header')
export class NetbookHeader extends LitElement {
	static styles = css`
		header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-inline: 3rem;
			padding-top: 16px;
			padding-bottom: 16px;
			font-family: "Livvic", sans-serif;
			color: #2AB0D7;
		}

		.netbook-header {
			color: #fff;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		.blue {
			color: white;
		}

		header > div {
			flex-shrink: 0;
			flex-basis: 300px;
		}

		p {
			margin-top: 0;
			margin-bottom: 8px;
		}

		.header-text {
			font-family: "Livvic", sans-serif;
			font-size: 23px;
			font-weight: bolder;
		}

		.header-icon-text {
			font-family: "Montserrat", sans-serif;
			font-size: 20px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 12px;
		}

		.header-icon-text > span {
			height: 32px !important;
		}

		.netbook-logo {
			width: 100%;
			max-width: 300px;
			height: fit-content;
		}

		@media screen and (max-width: 1100px) {
			.first-col {
				display: none;
			}
		}

		@media screen and (max-width: 750px) {
			header {
				display: block;
			}

			header > div {
				margin: 0 auto 2rem;
				width: fit-content;
			}
		}
		`;

	@property()
		headerType: 'netbook' | 'netbook-license' = 'netbook';

	render() {
		const logo = (this.headerType === 'netbook')
			? netbookLogoWhite
			: netbookLicenseLogo;
		const phoneIcon = (this.headerType === 'netbook-license')
			? phoneIconBlue
			: phoneIconWhite;
		const mailIcon = (this.headerType === 'netbook-license')
			? mailIconBlue
			: mailIconWhite;
		return html`
			<header class="${this.headerType === "netbook" ? "netbook-header" : "netbook-license-header"}">
				<div class="first-col"></div>
				<div class="netbook-logo"><img src="${logo}"/></div>
				<div>
					<p class="header-text">Fragen? Hinweise?</p>
					<p class="header-text">Gesprächsbedarf?</p>
					<p class="header-icon-text"><span><img src="${mailIcon}" /></span><a href="mailto:info@dilewe.de" target="_blank">info@dilewe.de</a></p>
					<p class="header-icon-text"><span><img src="${phoneIcon}" /></span><a href="tel:+4984219862148" target="_blank">08421 / 9862148</a></p>
				</div>
		</header>
		`;
	}
}
