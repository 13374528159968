import { closeFullscreen } from "../_helper";
import { showOverlay, hideOverlay, overlayCloseHandlers, isOverlayActive, canUserCloseOverlay } from "../overlay/overlay";
import "./modal.css";

export const showModal = (content: HTMLElement | string, className = "", userCantClose = false) : HTMLElement => {
	if(isOverlayActive() && !canUserCloseOverlay()){
		userCantClose = true;
		//throw new Error("User can't close overlay");
	}
	closeFullscreen();
	if (typeof content === 'string' || content instanceof String){
		const tempWrapper = document.createElement("TEMPORARY-MODAL");
		if (className) {
			tempWrapper.classList.add(`modal-${className}`);
		}
		tempWrapper.innerHTML = `<modal-content>${content}</modal-content>`;
		document.body.append(tempWrapper);
		return showModal(tempWrapper, className, userCantClose);
	}
	let buttonCloseModal = content.querySelector<HTMLElement>("MODAL-CLOSE");
	if(buttonCloseModal === null){ // Only add a new button of there currently is no button
		buttonCloseModal = document.createElement("MODAL-CLOSE");
		buttonCloseModal.classList.add("MODAL-CLOSE");
		content.prepend(buttonCloseModal);
		buttonCloseModal.onclick = hideOverlay;
	}
	if(userCantClose){
		buttonCloseModal.remove();
	}
	content.classList.add("modal");
	content.offsetWidth; // Sync CSS <-> JS
	content.classList.add("show-modal");
	content.offsetWidth; // Sync CSS <-> JS
	content.classList.add("visible");
	showOverlay(content, userCantClose);
	return content;
};

export const hideModal = () => {
	const modals = document.querySelectorAll(".show-modal.visible");
	modals.forEach(modal => {
		modal.classList.remove("visible");
		setTimeout(() => {
			const parent = modal.parentElement;
			if (parent?.tagName === "DETAILS") {
				parent.removeAttribute("open");
			}
			modal.classList.remove("show-modal");
			if(modal.tagName === "TEMPORARY-MODAL"){
				modal.remove();
			}
		}, 510);
	});
};
overlayCloseHandlers.push(hideModal);
