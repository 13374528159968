import { apiCreateSubmission, apiGetStudentSubmissions, apiUpdateSubmission, ISubmission, IStudent } from "@digitale-lernwelten/ugm-client-lib";
import { lockExercise, unlockExercise } from "../../content_types/exercise/exercise-textarea";
import { getElementHref } from "../../_helper";
import { ICorrection, ICorrectionEvent, flushCorrections, emitCorrectionChanged } from "../corrections/corrections";
import { DataStore } from "../datastore";
import { getUser, isFakeStudent, isLoggedIn, isStudent, isTeacher } from "../user-data";
import { KvGet, KvGetAll, KvSet } from "../user-kv";
import { showModal } from "../../modal/modal";
import { hideOverlay } from "../../overlay/overlay";

export const getAllSubmissions = () => {
	if (isLoggedIn() && (isFakeStudent() || isTeacher())){
		return Array.from(KvGetAll()).filter((d:any) =>
			(d[0] as string).startsWith('teacher-sub-')
		).map((d:any) => d[1]);
	}
	return Array.from(store.values());
};

const getDraftByExerciseId = (exerciseId:string) => {
	if (isLoggedIn() && (isFakeStudent() || isTeacher())){
		return KvGet(`teacher-sub-${exerciseId}`);
	}
	return store.find(s => (s.state === "draft") && (s.exerciseId === exerciseId));
};

export const getSubmission = (exerciseId:string) => {
	if (isLoggedIn() && (isFakeStudent() || isTeacher())){
		return KvGet(`teacher-sub-${exerciseId}`);
	}
	const arr = getAllSubmissions()
		.filter(s => !s.deletedAt && (s.exerciseId === exerciseId))
		.sort((a,b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

	return arr[0] || undefined;
};

export const getCorrectedSubmission = (exerciseId:string) => {
	if (isLoggedIn() && (isFakeStudent() || isTeacher())){
		return undefined;
	}
	const arr = getAllSubmissions()
		.filter(s => !s.deletedAt && (s.state !== "draft") && (s.exerciseId === exerciseId))
		.sort((a,b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
	return arr[0] || undefined;
};

export const getCorrection = (exerciseId: string) => {
	if (isLoggedIn() && (isFakeStudent() || isTeacher())){
		return undefined;
	}
	const sub = getCorrectedSubmission(exerciseId);
	if(!sub || !sub.correction){return undefined;}
	const cor:ICorrection = {
		exerciseId: sub.exerciseId,
		text: sub.correction.text, // used to be generalText
		// annotatedText is undefined if the teacher hasn't added any annotations
		// so just fallback to the submitted text
		annotatedText: sub.correction.annotatedText ?? sub.text
	};
	return cor;
};

export const addOrUpdateSubmission = async (sub:ISubmission) => {
	if(!sub.correction){return;}
	setTimeout(() => {
		emitCorrectionChanged(sub.exerciseId, getCorrection(sub.exerciseId));
	});
};

const doPoll = async (after?: Date) => {
	if(!isStudent()){return [];}
	const submissions = await apiGetStudentSubmissions(true, after);
	return submissions;
};

const handleCorrection = (ev:Event) => {
	const e = ev as CustomEvent;
	const correctionEvent = e.detail as ICorrectionEvent;
	unlockExercise(correctionEvent.exerciseId);
};
window.addEventListener("ugm-correction", handleCorrection);

const flushSubmissions = () =>
	setTimeout(() => {
		flushCorrections();
		document.querySelectorAll<HTMLElement>(`section[content-type="exercise"]`).forEach(exercise => {
			const exerciseId = exercise.getAttribute("content-type-id");
			if(!exerciseId){return;}
			unlockExercise(exerciseId);
		});
	});

const store:DataStore<ISubmission> = new DataStore("ugm-correction", addOrUpdateSubmission, flushSubmissions, doPoll);

const newSubmission = (exerciseId:string):ISubmission => {
	const chapter = document.querySelector<HTMLElement>(`header #header-center h3`);
	const exerciseChapter = chapter?.innerText || "";

	const exercise = document.querySelector<HTMLElement>(`[content-type-id="${exerciseId}"] exercise-content > h3 `);
	const exerciseTitle = exercise?.innerText || "";

	const exerciseSection = document.querySelector<HTMLElement>(`[content-type-id="${exerciseId}"]`);
	const exerciseHref = exerciseSection ? getElementHref(exerciseSection) : `${document.location.origin}${document.location.pathname}`;

	return {
		id: "",
		deletedAt: null,
		updatedAt: new Date(),
		createdAt: new Date(),
		exerciseId,
		exerciseChapter,
		exerciseTitle,
		exerciseHref,
		state: 'draft',
		text: '',
		correction: null,
		student: getUser() as IStudent
	};
};

const saveNewSubmission = async (sub:ISubmission) : Promise<ISubmission> => {
	if(isLoggedIn()){
		if(sub.state === "corrected"){
			throw new Error("Can't save a corrected submission");
		}
		const ret = await apiCreateSubmission(
			sub.exerciseId,
			sub.exerciseChapter,
			sub.exerciseTitle,
			sub.exerciseHref,
			sub.state,
			sub.text
		);
		addOrUpdateSubmission(sub);
		return ret;
	} else {
		addOrUpdateSubmission(sub);
		return sub;
	}
};

const updateSubmission = async (sub:ISubmission) : Promise<ISubmission> => {
	if(sub.state === "corrected"){
		return sub;
	}
	addOrUpdateSubmission(sub);
	if(isLoggedIn()){
		await apiUpdateSubmission(sub.id, sub.state as "draft" | "submitted", sub.text);
	}
	return sub;
};

const exerciseIdCreationsInFlight:Set<string> = new Set();
const exerciseIdCreationCallbackQueue:Map<string, (() => void)> = new Map();

export const setSubmission = async (exerciseId:string, data:any) => {
	if (isLoggedIn() && (isFakeStudent() || isTeacher())){
		const key = `teacher-sub-${exerciseId}`;
		KvSet(key, data);
		return;
	}
	const baseSubmission = getDraftByExerciseId(exerciseId);
	if(!baseSubmission){
		if (exerciseIdCreationsInFlight.has(exerciseId)){
			exerciseIdCreationCallbackQueue.set(exerciseId, () => {
				setSubmission(exerciseId, data);
			});
		}else{
			exerciseIdCreationsInFlight.add(exerciseId);
			const r = await saveNewSubmission({...newSubmission(exerciseId), ...data});
			store.set(r);
			exerciseIdCreationsInFlight.delete(exerciseId);
			const cb = exerciseIdCreationCallbackQueue.get(exerciseId);
			if(cb){
				exerciseIdCreationCallbackQueue.delete(exerciseId);
				cb();
			}
		}
	} else {
		const newSubmission = {...baseSubmission, ...data};
		if (JSON.stringify(newSubmission) !== JSON.stringify(baseSubmission)) {
			const r = await updateSubmission(newSubmission); // Only send an update when things have actually changed
			store.set(r);
		}
	}
};

export const submitConfirm = () => new Promise((resolve) => {
	const html = `<div class="submit-confirm-modal">
		<span class="errors"></span>
		<p>Möchtest du deine Antwort abschicken? Du kannst sie danach nicht mehr bearbeiten.</p>
		<btn-container>
		<button button-type="accept">Abschicken</button>
		<button button-type="cancel">Abbrechen</button>
		</btn-container>
	</div>`;
	const modal = showModal(html, "centered-box");
	const submitBtn = <HTMLInputElement | null>modal.querySelector('[button-type="accept"]');
	const cancelBtn = <HTMLInputElement | null>modal.querySelector('[button-type="cancel"]');
	const closeBtn  = <HTMLInputElement | null>modal.querySelector('modal-close');
	if(!submitBtn || !cancelBtn|| !closeBtn){throw new Error("Buttons not found");}
	submitBtn.addEventListener("click", () => {
		hideOverlay();
		resolve(true);
	});
	cancelBtn.addEventListener("click", () => {
		hideOverlay();
		resolve(false);
	});
	closeBtn.addEventListener( "click", () => resolve(false));
});

export const submitExercise = async (exerciseId:string, text:string, submissionKey: string) => {
	try {
		if(!text.trim()){
			return;
		}
		if (await submitConfirm()) {
			lockExercise(exerciseId);
			const state = "submitted";
			await setSubmission(submissionKey, {state, text});
			setTimeout(() => {
				emitCorrectionChanged(submissionKey, getCorrection(submissionKey));
			});
		}
	} catch {
		unlockExercise(exerciseId); // Unlock if the request failed, so the student can try again later
	}
};

export const getUnreadCorrectionCount = () => {
	const set = new Set();
	store.forEach(sub => {
		if(!sub.deletedAt && sub.state === "corrected" && sub.correction && !sub.correction.read){
			set.add(sub.exerciseId);
		}
	});
	return set.size;
};

export const loadSubmissions = () => store.load();
export const pollSubmissions = () => store.poll();
