import {customElement, property, query, queryAll} from "lit/decorators.js";
import {html, css, LitElement, unsafeCSS} from "lit";
import { showModal } from "../../modal/modal";
import { IInstitutionData, apiRegisterInstitution, apiSetInstitution } from "@digitale-lernwelten/ugm-client-lib";
import { getUser } from "../../user-group-management";
import { NetbookLicenseCard } from "./netbook-license-card";

import netbookLicenseBG from "../../img/netbook-license-bg.jpg";
import { getParam } from "../../_helper";

@customElement("netbook-license-page")
export class NetbookLicensePage extends LitElement {
	static styles = css`
		*  {
			font-family: "Livvic", sans-serif;
			box-sizing: border-box;
		}

		p {
			line-height: 1.4em;
		}

		.top {
			background: linear-gradient(0deg, rgba(42, 176, 215, 0.83), rgba(42, 176, 215, 0.83)), url(${unsafeCSS(netbookLicenseBG)});
			background-size: cover;
			padding: 2rem 4rem 3rem;
		}

		.top > p {
			padding-top: 1rem;
			color: #fff;
			text-align: center;
		}

		.top > div {
			width: fit-content;
			margin-inline: auto;
		}

		h2 {
			text-align: center;
			color: #FF586D;
			font-size: 40px;
		}

		h3 {
			padding-right: 4em;
		}

		.license-count-container {
			padding-bottom: 2rem;
			text-align: center;
		}

		.license-count-label {
			font-size: 45px;
		}

		.license-count {
			background: transparent;
			border: 3px solid #2AB0D7;
			font-weight: bold;
			width: 80px;
			position: relative;
			top:-4px;
			left:8px;
			font-size: 2rem;
			text-align: center;
			padding: 0.5rem;
		}

		.payment-info {
			width: 50%;
			margin-inline: auto;
		}

		input[type=number] {
			-moz-appearance: textfield;
			font-family: "Segoe UI", sans-serif;
			font-weight: regular;
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		.card-container {
			display: flex;
			flex-flow: row;
			justify-content: center;
			gap: 48px;
		}

		.form-container {
			background: #69C8E3;
		}

		.place-wrapper {
			display: flex;
			flex-flow: row nowrap;
			width: calc(100%);
			margin-inline: auto;
			gap: 1rem;
			box-sizing: border-box;
		}

		.place-wrapper > input {
			flex-grow: 1;
		}

		.form-container {
			padding-block: 3rem;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
		}

		.form-inner-content > p {
			color: white;
			font-size: 2rem;
			width: 100%;
			margin-inline: auto;
			margin-bottom: 8px;
		}

		input {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			background-color: #FFFFFF;
			margin-top: 32px;
			width: 100%;
			margin-inline: auto;
			border: solid 2px #008BCF;
			border-radius: 2px;
			outline: none;
			box-sizing: border-box;
		}

		input[type=checkbox] {
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.5rem;
		}

		.checkbox-wrapper {
			width: 100%;
			max-width: var(--content-width);
			padding: 0 var(--content-padding);
			margin: 0 auto 3rem;
			text-align: center;
		}

		.form-container input[type="number"],
		input[type="text"],
		input[type="email"],
		input[type="password"] {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			border: none;
			background-color: #FFFFFF;
			margin: 0 auto 2rem;
			outline: none;
			width: 100%;
		}

		input[type="submit"] {
			font-family: "Livvic", sans-serif;
			font-size: 2rem;
			color: white;
			width: fit-content;
			margin: 0 auto;
			padding: 0.5rem 4rem;
			border: none;
			background-color: #008BCF;
			border-radius: 2px;
			box-shadow: 0 3px 6px #00000029;
			cursor: pointer;
			text-decoration: none;
			white-space: normal;
			line-height: 1.4em;
			transition: background-color 200ms;
			width: 100%;
			max-width: fit-content;
		}

		@media screen and (max-width: 900px) {
			.form-container input[type="number"],
			input[type="submit"],
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.5rem;
			}
		}

		@media screen and (max-width: 700px) {
			.form-container input[type="number"],
			input[type="submit"],
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.35rem;
			}
		}

		@media screen and (max-width: 600px) {
			.license-count-label,
			.license-count-container {
				text-align: center;
				padding-top: 2rem;
			}
		}

		@media screen and (max-width: 500px) {
			.form-container input[type="number"],
			input[type="submit"],
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.2rem;
			}

			input[type="submit"] {
				padding: 0.5rem 2rem;
			}

			.license-count-label {
				font-size: 1.8rem;
			}
		}

		.form-inner-content {
			display:block;
			width:100%;
			max-width: var(--content-width);
			padding: 0 2rem;
			margin: 0 auto;
		}

		#invoice-summary {
			margin-top: 4rem;
		}

		.center {
			display:block;
			margin:0 auto 1rem;
			text-align:center;
		}

		.invoice-box {
			width: fit-content;
			margin-inline: auto;
			border: 3px solid #2AB0D7;
			padding: 2rem 3rem;
			margin-bottom: 3rem;
		}

		.right-align {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;
		}

		.amount {
			font-family: "Segoe UI", sans-serif;
			font-weight: bold;
			font-size: 2rem;
		}

		.price {
			font-size: 2rem;
			white-space: nowrap;
		}

		a {
			text-decoration: none;
			color: #008BCF;
		}

		@media screen and (max-width: 1320px) {
			.card-container {
				display:block;
			}

			netbook-license-card {
				display: block;
				margin-bottom: 3rem;
			}
		}

		@media screen and (max-width: 600px) {
			.price,
			.amount {
				font-size: 1.6rem;
			}
		}

	`;

	@property()
		redirectTarget = window.location.origin;

	@property()
		pricingOptions = [{duration: 3, price: 5}, {duration: 6, price: 5}, {duration: 12, price: 5}];

	@property({type: Number})
		licenseCount: number = 1;

	@property()
		city: string = "";

	@property()
		country: string = "";

	@queryAll('netbook-license-card')
		_cards: NetbookLicenseCard[];

	@query("#licenseCountInput")
		_count: HTMLInputElement;

	@query(".form-container")
		_formContainer: HTMLDivElement;

	@query('#invoice-summary')
		_invoiceSummary: HTMLDivElement;

	@query('#name-institution')
		_nameInstitution: HTMLInputElement;

	@query('#email')
		_email: HTMLInputElement;

	@query('#name-contact')
		_nameContact: HTMLInputElement;

	@query('#address')
		_address: HTMLInputElement;

	@query('#city')
		_city: HTMLInputElement;

	@query('#zip')
		_zip: HTMLInputElement;

	@query('#country')
		_country: HTMLInputElement;

	private userChanged: () => void;
	private _userChanged() {
		const user = getUser();
		if(user){
			this._email.value = user.email;
			if(user.institution && user.institution.email){
				this._address.value = user.institution.address;
				this._city.value = user.institution.city;
				this._country.value = user.institution.country;
				this._email.value = user.institution.email;
				this._nameContact.value = user.institution.nameContact;
				this._nameInstitution.value = user.institution.name;
				this._zip.value = user.institution.zip;
			}
		}
	}

	constructor(){
		super();
		this.userChanged = this._userChanged.bind(this);
		if (getParam("hamburg")) {
			this.city = "Hamburg";
			this.country = "Deutschland";
		}
	}

	connectedCallback(): void {
		super.connectedCallback();
		window.addEventListener("ugm-logged-in", this.userChanged);
	}

	disconnectedCallback(): void {
		super.disconnectedCallback();
		window.removeEventListener("ugm-logged-out", this.userChanged);
	}

	private _handleInput() {
		const oldValue = this.licenseCount;

		if(this._count.value === "") {
			this.licenseCount = 0;
			this._count.value = "0";
			return;
		}

		if(this.licenseCount === 0) {
			this._count.value = this._count.value.substring(1);
		}

		const num = parseInt(this._count.value);

		if(isNaN(num)) {
			this.licenseCount = oldValue;
			this._count.value = String(oldValue);
		} else {
			this.licenseCount = num;
		}

		const licenseCount = Math.max(1, this.licenseCount);
		this._cards.forEach(c => {
			c.count = licenseCount;
		});

		const oldCity = this.city;
		const newCity = this._city.value || '';
		if(oldCity !== newCity){
			this.city = newCity;
		}

		const oldCountry = this.country;
		const newCountry = this._country.value || '';
		if(oldCountry !== newCountry){
			this.country = newCountry;
		}
	}

	private _getPaymentMethod() {
		const selectedCard:number = 2;
		switch(selectedCard){
		case 0:
			return 'Invoice3M';
		case 1:
			return 'Invoice6M';
		case 2:
			return 'Invoice12M';
		default:
			throw new Error("Unknown payment method");
		}
	}

	private _getFormFields():IInstitutionData {
		const name = this._nameInstitution.value;
		const nameContact = this._nameContact.value;
		const address = this._address.value;
		const email = this._email.value;
		const city = this._city.value;
		const zip = this._zip.value;
		const country = this._country.value;
		if(!name || !nameContact || !address || !city || !zip || !country){
			throw new Error("Invalid form fields");
		}
		const paymentMethod = this._getPaymentMethod();
		return <IInstitutionData>{
			name,
			nameContact,
			address,
			city,
			zip,
			email,
			country,
			licenseCount: this.licenseCount,
			paymentMethod,
			addInvoice: true
		};
	}

	private async _buy(e: Event) {
		e.preventDefault();
		if(getUser()){
			await apiSetInstitution(this._getFormFields());
		} else {
			await apiRegisterInstitution(this._getFormFields());
		}
		showModal(`<netbook-license-confirmation></netbook-license-confirmation>`, "centered-box", true);
	}

	private calcDiscount() {
		if(!this.city){
			return 1;
		}
		if(this.city.toLowerCase().trim() === "hamburg"){
			return 0.5;
		}
		return 1;
	}

	render() {
		setTimeout(this.userChanged,0);
		const selectedCard = 2;
		const discount = this.calcDiscount();
		const discountHtml = discount === 1
			? html``
			: html`<div class="right-align"><span>Rabatt:</span><span class="price">${(discount * 100)|0} %</span></div>`;
		const pricePerClassPerMonth = this.pricingOptions[selectedCard].price;
		const pricePerClass = this.licenseCount * pricePerClassPerMonth;
		const total = pricePerClass * this.pricingOptions[selectedCard].duration * discount;
		return html`
			<div class="top">
				<div class="center"><netbook-contact color="white"></netbook-contact></div>
			</div>

			<form @submit="${this._buy}">
				<div class="form-container">
					<div class="form-inner-content">
						<p style="font-size: 2.4rem; text-align: center; margin-bottom: 2rem;">Rechnungsanschrift</p>
						<input id="name-institution" type="text" placeholder="Name der Institution" required/>
						<input id="name-contact" type="text" placeholder="Name des Ansprechpartners" required/>
						<input id="email" type="email" placeholder="E-Mail des Rechnungsempfängers" required/>
						<input id="address" type="text" placeholder="Straße, Hausnummer" required/>
						<div class="place-wrapper">
							<input id="zip" type="text" placeholder="PLZ" minlength="5" maxlength="5" required/>
							<input id="city" type="text" placeholder="Ort" required value="${this.city}" @change=${this._handleInput}/>
						</div>
						<input id="country" type="text" placeholder="Land" required value="${this.country}" @change=${this._handleInput}/>
					</div>
				</div>

				<div id="invoice-summary">
					<div class="license-count-container">
						<span class="license-count-label">Klassenanzahl: </span>
						<fancy-number-input id="licenseCountInput" min="1" max="99" step="1" value=${this.licenseCount} @change=${this._handleInput} @input=${this._handleInput}></fancy-number-input>
					</div>
					<div class="invoice-box">
						<h3>Lizenz für ${this.pricingOptions[selectedCard].duration} Monate</h3>
						<div class="right-align"><span>Preis pro Monat pro Klasse:</span><span class="price">${pricePerClassPerMonth} €</span></div>
						<div class="right-align"><span>Preis pro Monat:</span><span class="price">${pricePerClass} €</span></div>
						${discountHtml}
						<hr>
						<div class="right-align"><span><b>Gesamtbetrag:</b></span><span class="price"><b>${total} €</b></span></div>
					</div>
					
					<div class="checkbox-wrapper">
						<input id="agb" type="checkbox" required>
						<label for="agb">Ja, ich habe die <a href="/inhalt/agb" target="_blank">AGB</a> gelesen und akzeptiere diese.</label>
					</div>
					<div class="checkbox-wrapper">
						<input type="submit" value="Zahlungspflichtig bestellen">
					</div>
				</div>
			</form>
		`;
	}
}
