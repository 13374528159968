import { initMedia } from "../media/media";
import type { LearningGoal, TrainingTask } from "./training";
import { getLearningGoals, wordPressH5PUrlToIframeSrc } from "./training";

import "./learningGoalList.css";

const buildTrainingFrame = (rawurl:string) => {
	const iframe = document.createElement("iframe");
	iframe.src=wordPressH5PUrlToIframeSrc(rawurl);
	return iframe;
};

const getDifficulties = (tasks:TrainingTask[]) => {
	const diffs = {"0": 0, "1": 0, "2": 0};
	tasks.forEach(task => diffs[task.difficulty]++);
	return `(${diffs["0"]}/${diffs["1"]}/${diffs["2"]})`;
};

const showLearningGoalExercises = (exerciseContainer:HTMLElement, goal:LearningGoal) => {
	goal.tasks.forEach(task => {
		const container = document.createElement("training-container");

		const trainingPlaceholder = document.createElement("training-placeholder");
		trainingPlaceholder.setAttribute("difficulty", task.difficulty);
		const audio = (task.audio.length <= 0) ? '' : task.audio.map(a => `<audio controls src="${a.url}"></audio>`).join('');
		trainingPlaceholder.innerHTML = `<text-container>${task.introduction || '&nbsp;'}</text-container>${audio}`;
		trainingPlaceholder.append(buildTrainingFrame(task.taskUrl));
		container.append(trainingPlaceholder);

		const editLink = document.createElement("a");
		editLink.target = "_blank";
		editLink.href = task.taskUrl;
		editLink.classList.add("edit");
		container.append(editLink);

		exerciseContainer.append(container);
		container.querySelectorAll<HTMLAudioElement>("audio").forEach(a => initMedia(a));
	});
};

const getLearningGoalRootline = (goal?:LearningGoal, buf = ""):string => {
	if(!goal){return buf;}
	return getLearningGoalRootline(goal.parent, buf ? `${goal.title} > ${buf}` : goal.title);
};

const createLearningGoalSummary = (goal:LearningGoal) => {
	const difficulties = getDifficulties(goal.tasks);

	const item = document.createElement("DIV");
	item.setAttribute("goal-depth", String(goal.level - 1));
	item.classList.add("learning-goal");
	item.title = getLearningGoalRootline(goal);

	if(goal.editLink){
		const editLink = document.createElement("a");
		editLink.target = "_blank";
		editLink.href = goal.editLink;
		editLink.classList.add("edit");
		editLink.classList.add("edit-goal");
		item.append(editLink);
	}

	const desc = document.createElement("span");
	desc.classList.add("learning-goal-description");
	desc.innerHTML = `${goal.title} - ${goal.tasks.length} ${difficulties}`;
	item.append(desc);

	let exercisesVisible = false;
	const exerciseContainer = document.createElement("learning-goal-exercises");
	item.append(exerciseContainer);
	desc.onclick = () => {
		if(exercisesVisible){
			exerciseContainer.innerHTML = '';
			item.classList.remove("open");
		} else {
			showLearningGoalExercises(exerciseContainer, goal);
			item.classList.add("open");
		}
		exercisesVisible = !exercisesVisible;
	};
	return item;
};

const initLearningGoalListRec = async (wrap:HTMLElement, childMap: Map<string, LearningGoal[]>, parentID:string, sortByExercies = false, searchWord:string) => {
	const goals = childMap.get(parentID);
	if(!goals){return;}
	if(sortByExercies){
		goals.sort((a, b) => a.tasks.length - b.tasks.length);
	} else {
		goals.sort((a, b) => a.lft - b.lft);
	}
	for (const goal of goals) {
		if(goal.title.toLowerCase().indexOf(searchWord) >= 0){
			wrap.append(createLearningGoalSummary(goal));
		}
		initLearningGoalListRec(wrap, childMap, goal.id, sortByExercies, searchWord);
	}
	wrap.append(document.createElement("hr"));
};

const refreshLearningGoalList = async (wrap:HTMLElement, sortOrder:string, searchWord = "") => {
	const goals = await getLearningGoals();

	wrap.innerHTML = '';
	const map = new Map();
	for(const goal of Object.values(goals)){
		const id = goal.parent?.id || "";
		const arr = map.get(id) || [];
		arr.push(goal);
		map.set(id, arr);
	}
	initLearningGoalListRec(wrap, map, "", (sortOrder === "exercises"), searchWord);
};

const initLearningGoalList = (wrap:HTMLElement) => {
	const select = document.createElement("select");
	select.innerHTML = `
	<option value="standard">Default order</option>
	<option value="exercises">Sort by amount of exercises</option>
	`;
	let sortOrder = "standard";
	select.onchange = () => {
		const newOrder = select.value;
		if(newOrder === sortOrder){return;}
		sortOrder = newOrder;
		refreshLearningGoalList(goalWrap, sortOrder);
	};

	let lastSearchWord = "";
	let refreshSearchCB: ReturnType<typeof setTimeout> | null = null;
	const refreshSearch = () => {
		refreshSearchCB = null;
		const searchWord = search.value;
		if(lastSearchWord === searchWord){
			return;
		}
		lastSearchWord = searchWord;
		refreshLearningGoalList(goalWrap, sortOrder, searchWord.toLowerCase());
	};

	const search = document.createElement("input");
	search.placeholder = "Search by name";
	const queueRefresh = () => {
		if(refreshSearchCB){
			clearTimeout(refreshSearchCB);
		}
		refreshSearchCB = setTimeout(refreshSearch, 50);
	};
	search.onkeydown = queueRefresh;
	search.onchange = queueRefresh;

	const goalWrap = document.createElement("div");
	wrap.append(select);
	wrap.append(search);
	wrap.append(goalWrap);
	refreshLearningGoalList(goalWrap, sortOrder, "");
};

setTimeout(() => {
	document.querySelectorAll('learning-goals-list').forEach(initLearningGoalList);
},0);
