import {html, css, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

@customElement('fancy-text-input')
export class FancyTextInput extends LitElement {
	static styles = css`
	label {
		display: inline-flex;
		border: solid 2px #bbb;
		border-radius: 4px;
		padding: 0.5rem;
		min-width: 16em;
		position: relative;
		overflow: visible;
		transition: border-color 200ms;
		width: 100%;
		box-sizing: border-box;
	}

	span {
		position: absolute;
		left: 0;
		top: 0;
		transform-origin: left;
		transform: translate(0.5rem, 50%);
		font-size: 1.2rem;
		line-height: 1.2rem;
		color: #bbb;
		padding: 0 0.5rem;
		background-color: #fff;
		z-index: 0;
		transition: color 200ms, transform 200ms ease-in-out;
	}

	label.focus {
		border-color: var(--user-accent-color);
	}
	label.focus span {
		color: var(--user-accent-color);
	}

	span.top {
		transform: translate(0.5rem, -50%) scale(60%);
	}

	input {
		appearance: none;
		border: none;
		padding: 0;
		margin: 0;
		outline: none;
		font-size: 1.2rem;
		line-height: 1.5rem;
		width: 100%;
		background: transparent;
		color: #000;
		position: relative;
		z-index:1;
	}
	input[readonly] {
		color: #bbb;
	}
`;


	@property({type: Boolean, attribute: false})
		isFocused = false;

	@property()
		value = '';

	@property()
		placeholder = '';

	@property({type: Boolean})
		readonly = false;

	@query('input')
		_input: HTMLInputElement;

	private readonly _handleFocus;
	private handleFocus() {
		this.isFocused = true;
	}

	private readonly _handleBlur;
	private handleBlur() {
		this.isFocused = false;
	}

	constructor(){
		super();
		this._handleFocus  = this.handleFocus.bind(this);
		this._handleBlur  = this.handleBlur.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('focus', this._handleFocus);
		this.addEventListener('blur', this._handleBlur);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEventListener('focus', this._handleFocus);
		this.removeEventListener('blur', this._handleBlur);
	}

	_handleInput() {
		this.value = this._input.value;
	}

	render() {
		return html`
		<label class="${(!this.readonly && this.isFocused) ? "focus" : ""}">
			<span class="${(this.isFocused || this.value) ? "top" : ""}">${this.placeholder}</span>
			${this.readonly
		? html`<input value="${this.value}" readonly/>`
		: html`<input value="${this.value}" @input="${this._handleInput}"/>`}
		</label>`;
	}
}
