import Configuration from "../../configuration";
import t from "../i18n";
import { showOverlay, hideOverlay, overlayCloseHandlers, isOverlayActive, canUserCloseOverlay } from "../overlay/overlay";
import { getCourse, isTeacher } from "../user-group-management";
import { ugmSetMeta } from "../user-group-management/user-group-management";

import "./pageViewHistory.css";

export interface PageViewHistoryEntry {
	date: number,
	url: string,
	title: string
}

const refreshPageViewHistoryUI = () => {
	const pageViewHistoryPanel = <HTMLElement | null>document.querySelector("#page-view-history-panel");
	if(!pageViewHistoryPanel){return;}
	pageViewHistoryPanel.innerHTML = `<h3>${t().lastVisitedPages}</h3>` + getHistory().map(v => `<a href="${v.url}">${v.title}</a>`).join("");
};

/* Default to returning an empty history, for when the history is disabled */
let history:PageViewHistoryEntry[] = [];
export const getHistory = () => history;

export const syncPageViewHistory = (userEntries: PageViewHistoryEntry[] | null) => {
	if(userEntries){
		// Merge and Remove duplicates following one another
		history = history.concat(userEntries).sort((a,b) => b.date - a.date);
		const newHistory:PageViewHistoryEntry[] = [];
		let lastUrl = undefined;
		for(const e of history){
			const url = e.url.split("#")[0];
			if(url !== lastUrl){
				newHistory.push(e);
			}
			lastUrl = url;
		}
		history = newHistory.slice(0, Configuration.pageViewHistoryMaxEntries);

		persistHistory();
	}
	// Workaround for pageViewHistory overwriting activeCourse
	if(isTeacher() && getCourse()){
		const activeCourse = getCourse();
		ugmSetMeta({pageViewHistory: history, activeCourse});
	}

	refreshPageViewHistoryUI();
};

const loadHistory = () => {
	history = JSON.parse(window.localStorage["page-view-history"] || "[]");
};

const persistHistory = () => {
	window.localStorage["page-view-history"] = JSON.stringify(history);
};

const getHistoryEntryForCurrentPage = () : PageViewHistoryEntry => ({
	date: Date.now(),
	url: String(window.location),
	title: document.querySelector<HTMLElement>("#header-center h3")?.innerText || String(window.location)
});

const pushHistoryEntry = (entry:PageViewHistoryEntry) => {
	/* First we filter the current URL from the history, this leads to the date being updated
	* and also reorders the history so the most recent entry is at the top
	*/
	history = history.filter(cur => cur.url !== entry.url).reverse();
	history.push(entry);
	/* By slicing the array we make sure that there are at most pageViewHistoryMaxEntries entries */
	history = history.reverse().slice(0, Configuration.pageViewHistoryMaxEntries);
	syncPageViewHistory(history);
};
loadHistory();
setTimeout(() => {
	pushHistoryEntry(getHistoryEntryForCurrentPage());
}, 0);

let pageViewHistoryPanel:HTMLElement | null = null;

const closePageViewUI = () => {
	pageViewHistoryPanel?.classList.remove("active");
};

const togglePageViewUI = () => {
	if(!pageViewHistoryPanel){return;}
	if(pageViewHistoryPanel.classList.contains("active")){
		closePageViewUI();
		hideOverlay();
	}else{
		if(isOverlayActive() && !canUserCloseOverlay()){
			return;
		}
		pageViewHistoryPanel.classList.add("active");
		showOverlay(pageViewHistoryPanel);
	}
};

const initPageViewHistoryUI = () => {
	if(!Configuration.pageViewHistory){return;}
	const pageViewHistoryButton = document.querySelectorAll<HTMLElement>("page-view-history-button");
	if(!pageViewHistoryButton){return;}
	pageViewHistoryButton.forEach((btn:HTMLElement)=>btn.addEventListener("click", togglePageViewUI));

	pageViewHistoryPanel = document.createElement("ASIDE");
	pageViewHistoryPanel.id = "page-view-history-panel";
	document.body.append(pageViewHistoryPanel);
	refreshPageViewHistoryUI();
};
setTimeout(initPageViewHistoryUI,0);
overlayCloseHandlers.push(closePageViewUI);

const deleteLocalHistory = () => {
	window.localStorage.removeItem("page-view-history");
};
window.addEventListener("ugm-logged-out", deleteLocalHistory);
