import { printHTML } from "../_helper";
import { getLang } from "../i18n";

import "./reading-text.css";
import printCSS from "./reading-text-print.css?raw";

export const printReadingText = (section: HTMLElement) => {
	const readingText = section.querySelector<HTMLElement>(`section[content-type="reading-text"]`);
	const boxCaption = section.querySelector<HTMLElement>("box-caption");
	const footer = section.querySelector<HTMLElement>("footer");
	const footerHTML = (footer && footer.innerHTML) || "Digitale Lernwelten";
	if(!readingText || !boxCaption){
		throw new Error("Invalid reading text DOM");
	}
	const title = boxCaption.querySelector<HTMLElement>("h1,h2,h3,h4,h5,h6")?.innerText || "Lesetext";

	printHTML(`<!DOCTYPE html>
	<html lang="${getLang()}">
		<head>
			<meta charset="utf-8">
			<title>${title}</title>
			<style>${printCSS}</style>
		</head>
		<body>
			<header>${boxCaption.innerHTML}</header>
			<main>${readingText.innerHTML}</main>
			<footer>${footerHTML}</footer>
		</body>
	</html>`);
};

export const initReadingText = (section:HTMLElement) => {
	const printButton = section.querySelector<HTMLButtonElement>("button.print-button");
	if(!printButton){
		throw new Error("Can't find print button in reading text section");
	}
	printButton.onclick = () => printReadingText(section);
};
setTimeout(() => document.querySelectorAll<HTMLElement>(`section[box-type="reading-text"]`).forEach(initReadingText), 0);
