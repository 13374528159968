import {customElement} from "lit/decorators.js";
import {html, css, LitElement} from "lit";


@customElement("hint-label")
export class HintLabel extends LitElement {
	static styles = css`
		.hint {
			display: inline-block;
			position: static;
			width: 28px;
			height: 28px;
			margin-left:16px;
			border: 0 solid transparent;
			border-radius: 50%;
			background-color: #eee;
			text-align: center;
			line-height: 28px;
			color: #333;
			font-size: 16px;
			vertical-align: text-bottom;
		}

		.hint summary {
			position: relative;
			width: 100%;
		}

		.hint[open] summary::after {
			content: "";
			border-bottom: 12px solid #eee;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			height: 0;
			position: absolute;
			width: 0;
			margin-left: -10px;
			margin-top: 33px;
		}

		.hint > div {
			cursor: auto;
			background: #eee;
			color: var(--text-color);
			padding: 15px;
			width: 75%;
			position: fixed;
			left: 50%;
			transform: translateX(-50%);
			margin-top: 15px;
			border-radius: 4px;
			max-width: 600px;
			min-height: fit-content;
			z-index: 10;
		}

		.hint summary::marker,
		.hint summary::-webkit-details-marker {
			display: none;
			content: "";
		}
	`;

	render() {
		return html`
			<details class="hint">
				<summary title="Info">i</summary>
				<div style="text-align:left;">
					<slot></slot>
				</div>
			</details>
		`;
	}
}
