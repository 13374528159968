import {customElement, property, query} from "lit/decorators.js";
import {css, html, LitElement} from "lit";
import {ugmLogin} from "../../user-group-management";
import configuration from "../../../configuration";
import t from "../../i18n";

@customElement("hamburg-login")
export class HamburgLogin extends LitElement {
	static styles = css`
		input[type="text"],
		input[type="email"],
		input[type="password"] {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			border: none;
			background-color: #FFFFFF;
			margin-top: 32px;
			width: 35%;
			margin-inline: auto;
		}

		input::placeholder {
			color: #2AB0D799;
		}

		input[data-valid="false"] {
			border: 4px solid #E3000F;
		}

		input[type="submit"] {
			font-family: "Livvic", sans-serif;
			font-size: 2rem;
			color: white;
			width: fit-content;
			margin-inline: auto;
			padding: 0.5rem 4rem;
			border: none;
			background-color: #E3000F;
			margin-top: 48px;
			cursor: pointer;
		}

		input[type="submit"]:hover {
			background-color: #a0000a;
		}

		input[type="submit"]:active {
			background-color: #ff2938;
		}

		form {
			display: flex;
			flex-flow: column nowrap;
		}

		#error-message {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;

			color: #E3000F;
			background-color: #ffe5e7;

			padding: 2rem;
			margin-inline: auto;
			margin-block: 1rem;

			border-left: 5px solid #E3000F;
		}

		#error-message.hide {
			display: none;
		}

		@media screen and (max-width: 900px) {
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.5rem;
				width: 50%;
			}

			input[type="submit"] {
				font-size: 1.5rem;
				padding: 0.5rem 4rem;
				margin-top: 48px;
			}
		}

		@media screen and (max-width: 500px) {
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1rem;
				width: 75%;
			}
		}

		a {
			color: white;
			font-size: 1.5rem;
			margin-inline: auto;
			margin-top: 3rem;
		}
	`;

	@property()
		loginName = '';

	@property()
		password = '';

	@property()
		validLoginName = true;

	@property()
		validPassword = true;

	@property()
		redirectTarget = window.location.origin;

	@query('input[id="username"]')
		_username: HTMLInputElement;

	@query('input[id="pwd"]')
		_pwd: HTMLInputElement;

	@query('#error-message')
		_error: HTMLSpanElement;

	_handleInput() {
		this.loginName = this._username.value;
		this.password = this._pwd.value;
	}

	_loginUser(e: Event) {
		e.preventDefault();

		if(!this.loginName) {
			this.validLoginName = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			this._error.classList.remove("hide");
			return;
		}

		if(!this.password) {
			this.validPassword = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			this._error.classList.remove("hide");
			return;
		}

		ugmLogin(this.loginName, this.password)
			.then((user) => {
				if(user) {
					window.location.replace(this.redirectTarget);
				}
			})
			.catch(() => {
				this._error.innerHTML = "Die Anmeldung war nicht erfolgreich, bitte versuchen Sie es erneut.";
				this._error.classList.remove("hide");
			});
	}

	render() {
		return html`
			<form @submit=${this._loginUser}>
				<input id="username" type="text" placeholder="Kürzel oder Email Adresse" value=${this.loginName} @input=${this._handleInput} data-valid=${this.validLoginName}/>
				<input id="pwd" type="password" placeholder="Passwort" value=${this.password} @input=${this._handleInput} data-valid=${this.validPassword}/>
				<input type="submit" value="Anmelden" />
				<a href="${configuration.ugmEndpoint}/reset-password" target="_blank">${t().loginInfoPasswordLink}</a>
				<span id="error-message" class="hide"></span>
			</form>
		`;
	}
}
