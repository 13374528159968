const de = {
	mvet: {
		myMvet: "<b>Mein</b> mVet",
		goToShop: "Zum Shop",
		goToLibrary: "Zur Bibliothek",
		goToAccount: "Zum Account",
		yourOrders: "Ihre Bestellungen"
	},
	home: "Startseite",
	index: "Inhaltsverzeichnis",
	announcements: "Ankündigungen",
	delete: "Löschen",
	save: "Speichern",
	download: "Download",
	editTask: "Aufgabe bearbeiten",
	title: "Titel",
	addNote: "Notiz anlegen",
	files: "Dateien",
	perFile: "pro Datei",
	supportedFiles: "Unterstützte Dateiformate",
	login: "Anmelden",
	logout: "Abmelden",
	starlevel: "Auf welchem Level willst du das Kapitel bearbeiten?",
	lastVisitedPages: "Zuletzt besuchte Seiten:",
	author: "Urheber",
	upload: "Datei hochladen",
	uploadError: "Leider konnte die ausgew&auml;hlte Datei nicht gespeichert werden, eventuell ist Sie zu gro&szlig;.",
	uploadTooBig: "Leider passen diese Dateien nicht in den lokalen Speicher, bitte versuchen Sie es mit kleineren oder weniger Dateien erneut.",
	notesPublish: "Für die Klasse veröffentlichen",
	notesLogin: "Bitte melden Sie sich an, um Notizen zu verfassen.",
	notesAdd: "Neue Markierung erstellen",
	notesRemove: "Markierung entfernen",
	notesClose: "Markierungsmenü schließen",

	coursePadHeader: "Gruppenarbeit",
	coursePadNew: "Neue Gruppe",
	coursePadError: "Bitte verwende einen Namen, der zwischen 4 und 64 Zeichen lang ist.",

	saveCorrection: "Speichern",
	submitCorrection: "Abgeben",
	correctionSubmitted: "Abgegeben",
	correctionSubmittedModal: "Abgegeben",
	editLocked: "Du kannst den Text wieder bearbeiten, sobald deine Lehrkraft dir Rückmeldung gegeben hat.",
	//optout
	allowExternalEmbeds: "Erlaubnis, externe Inhalte zu laden",
	darkMode: "darkMode",
	page_view_history: "Seitenhistorie",
	LANG: "Spracheinstellung",
	local_only_ugm_notes: "Lokale Notizen",
	user_star_level: "globaler Schwierigkeitsgrad",
	star_data: "Gespeicherte Differenzierungen",
	local_only_ugm_correction: "lokal gespeicherte Eingaben Ihrer Übungen",
	//dynamic Pages
	glossary: "Glossareinträge (Sammlung)",
	//Contenttypes
	//embeds / privacy
	onlyAllowEmbedsForLoggedInUsers: "Um diesen interaktiven Inhalt zu nutzen loggen Sie sich bitte ein.",
	externalEmbed: "Externer Inhalt, hier klicken um fortzufahren",
	externalEmbedModal:`<p>An dieser Stelle finden Sie einen externen Inhalt. Wenn Sie solche Inhalte freischalten, können personenbezogene Daten an Drittplattformen übermittelt werden. </p><p>Mehr dazu in unserer <a href="./datenschutz/" target="_blank">Datenschutzerklaerung</a>.</p>`,
	accept: "Akzeptieren",
	cancel: "Abbrechen",
	privacyContainer: "Hier finden Sie ihre aktuell gespeicherten Einstellungen",
	//ugm login fields
	activationAccess: "Aktiviere deinen Zugang für",
	activationSubmit: "Los geht's!",
	activationSuccessHeader: "Deine Kennung",
	activationSuccessText: "Bitte schreibe dir deine Kennung auf und merke dir dein Passwort gut! Solltest du dein Passwort vergessen, kann es deine Lehrerin oder dein Lehrer für dich zurücksetzen.",
	activationFailedHeader: "Fehler bei der Benutzeraktivierung",
	activationFailedText: "Leider ist ein Fehler bei der Nutzeraktivierung aufgetreten, bitte überprüfen Sie den Link und probieren Sie es später erneut.",
	activationError: "Aktivierung fehlgeschlagen, bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.",

	registerText: "Sie haben noch keine Zugänge für Ihre Klasse? Hier registrieren!",
	registerTextNetbook: "Noch keinen Zugang? Jetzt das netBook hier erwerben!",
	registerTextEnforced: "Sie haben noch keinen Zugang? Hier registrieren!",
	registerTextGeo: "Alle anderen Regionen: Sie haben noch keine Zugänge für Ihre Klasse(n)? Bitte hier registrieren!",

	userName: "Benutzername",
	password: "Passwort",
	passwordInfo: "Das Passwort benötigt eine Mindestlänge von 8 Zeichen.",
	passwordRepeat: "Passwort wiederholen",
	passwordEnter: "Passwort eingeben",
	passwordReset: "Passwort zurücksetzen für",
	passwordError: "Das Passwort ist zu kurz",
	passwordDiffers:"Die Passwörter stimmt nicht überein",

	loginError: "Login fehlgeschlagen, bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
	loginInfoUsername: "Als Lehrperson melden Sie sich mit Ihrer E-Mail-Adresse an.<br /> Als Schülerin oder Schüler melden Sie sich mit Ihrer Kürzel im Format \"xxxx-farbetier\" an.",
	loginInfoUsername365: "<p>Ostbelgien: Nutzen Sie bitte den Button “Weiter mit Office 365” unten.</p><p>Alle übrigen Regionen der Euregio Maas-Rhein: </p><ul><li><p>Als Lehrperson melden Sie sich mit Ihrer Dienst-E-Mail  an. </p></li><li><p>Schülerinnen oder Schüler nutzen Ihr Kürzel im Format “xxxx-farbetier”.</p></li></ul><p></p>",
	loginInfoPassword: `Wenn Sie als Schülerin oder Schüler Ihr Passwort vergessen haben, wenden Sie sich bitte an die verantwortliche Lehrperson.
		Diese kann Ihr Passwort zurücksetzen und Ihnen Ihr temporäres Passwort mitteilen.</br></br>Wenn Sie als Lehrperson Ihr Passwort vergessen haben,`,
	loginInfoPasswordLink: "Passwort zurücksetzen",
	loginInfoPassword365: (link:string) => `<p>Für Ostbelgien: Setzen Sie Ihr Passwort bitte ggf. über MS Office 365 zurück.&nbsp;</p>
		<p>Alle übrigen Regionen der Euregio Maas-Rhein:&nbsp;</p>
		<ul>
			<li>Wenn Sie als Lehrperson Ihr Passwort vergessen haben, klicken Sie bitte auf ${link}</li>
			<li>Wenn Sie als Schülerin oder Schüler Ihr Passwort vergessen haben, wenden Sie sich bitte an Ihre Lehrperson. Diese kann Ihr Passwort zurücksetzen und Ihnen ein temporäres Passwort mitteilen.</li>
		</ul>`,
	login365Label: "Weiter mit Office 365",
	login365Hint: "Hinweis: in Ostbelgien ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit Office 365\".",

	loginVidisLabel: "Weiter mit",
	loginVidisHint: "Hinweis: mit VIDIS ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit VIDIS\".",

	loginEduplacesLabel: "Weiter mit",
	loginEduplacesHint: "Hinweis: mit Eduplaces ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit Eduplaces\".",

	classList: "Klassenliste",
	classManagement: "Klassenverwaltung",
	correctSubmissions: "Aufgaben korrigieren",
	sendMessage: "Nachricht senden",
	messages: "Nachrichten",

	invalidOIDCEmailTitle: "Ungültige E-Mail",
	invalidOIDCEmailBody: "Bitte verwenden Sie eine offizielle E-Mail-Adresse Ihrer Schule.",
	//privacy policy
	acceptAgb: (link:string) => `Ich habe die ${link} gelesen und akzeptiert`,
	privacyPolicy: "Datenschutzerklärung",
};
export default de;
