import { hideOverlay } from "../overlay/overlay";
import { showModal } from "../modal/modal";
import Configuration from "../../configuration";
import t from "../i18n";

import "./privacy.css";
import { checkForClassExternalEmbeds, showClassExternalEmbedsModal } from "../content_types";
import { getCourse, getUser, isStudent, isTeacher } from "../user-group-management";

export const allowAllWaitingEmbeds = () => {
	continuationsWaitingForAllowedEmbeds.forEach(c => c(null));
	continuationsWaitingForAllowedEmbeds.length = 0;
};

export const allowUseOfEmbeds = (v: boolean) => {
	if(v){
		localStorage.setItem('allowExternalEmbeds', 'true');
		allowAllWaitingEmbeds();
		setTimeout(checkForClassExternalEmbeds, 0);
	}else {
		localStorage.removeItem('allowExternalEmbeds');
	}
};

export const embedsAllowed = () => {
	if(Configuration.courseBasedAllowanceOfExternalEmbeds){
		if(isStudent()){
			const user = getUser();
			return user.allowExternalEmbeds;
		} else if(isTeacher()) {
			const course = getCourse();
			return course?.allowExternalEmbeds;
		}
	}
	if (localStorage.getItem('allowExternalEmbeds') === 'true') {
		return true;
	}
	if (Configuration.overridePrivacy) {
		return true;
	}
	return false;
};

const continuationsWaitingForAllowedEmbeds:Array<(value: unknown) => void> = [];
export const continueWhenAllowed = () => new Promise(resolve => {
	continuationsWaitingForAllowedEmbeds.push(resolve);
});

export const canUseEmbeds = () => new Promise(resolve => {
	if(embedsAllowed()){ resolve(true); return; }
	hideOverlay();

	if(Configuration.courseBasedAllowanceOfExternalEmbeds){
		if(isTeacher()){
			showClassExternalEmbedsModal();
			return;
		} else if(isStudent()) {
			const modal = showModal(`<p>Um externe Inhalte nutzen zu d&uuml;rfen muss deine Lehrkraft diesem vorher f&uuml;r die gesamte Klasse zustimmen.</p>
			<div id="privacy-btn-wrap">
			<button button-type="cancel">OK</button>
			</div>`);
			const cancelButton = modal.querySelector<HTMLElement>(`button[button-type="cancel"]`);
			cancelButton?.addEventListener("click", () => {
				hideOverlay();
				return resolve(false);
			});
			cancelButton?.focus();
		}
	}
	const modal = showModal(`${t().externalEmbedModal}
	<div id="privacy-btn-wrap">
	<button button-type="accept">${t().accept}</button>
	<button button-type="cancel">${t().cancel}</button>
	</div>`);
	const cancelButton = modal.querySelector<HTMLElement>(`button[button-type="cancel"]`);
	cancelButton?.addEventListener("click", () => {
		hideOverlay();
		return resolve(false);
	});
	const acceptButton = modal.querySelector<HTMLElement>(`button[button-type="accept"]`);
	acceptButton?.addEventListener("click", async () => {
		await allowUseOfEmbeds(true);
		hideOverlay();
		return resolve(true);
	});
	acceptButton?.focus();
});
