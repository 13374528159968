//this should open the navigation, when someone starts typing into search
import configuration from '../../../configuration';

const initSearchInHeaderInput = (input: HTMLInputElement) => {
	const nav = document.querySelector<HTMLElement>("#navigation");
	const body = document.querySelector<HTMLElement>("body");
	const overlay = document.querySelector<HTMLElement>("page-overlay");
	if (!nav || !body || !overlay) { return;}
	input.addEventListener("keyup", ()=>{
		nav.classList.add("active");
	});
};

const initSearchInHeader = async () => {
	if(!configuration.enableSearch){
		return;
	}
	document.querySelectorAll(`input[name="sword"]`).forEach(initSearchInHeaderInput);
};

setTimeout(initSearchInHeader, 0);
