import { showModal } from "../../modal/modal";
import { showEmbeddingSections } from "../embedding/embedding";
import { initEmbeddingLinks } from "../embedding/video-embedding";
import { initExerciseEditors } from "../exercise";

import "./glossary.css";

interface GlossaryEntry {
	html: string,
	type: string
}

const getAnchorTag = (a:HTMLElement | null) : HTMLAnchorElement|null => {
	if (!a) {
		return null;
	} else if (a instanceof HTMLAnchorElement){
		return a;
	} else {
		return getAnchorTag(a.parentElement);
	}
};

const getLinkPath = (href:string) => {
	return "/"+(String(href).split("/").slice(3).join("/"));
};

const fetchGlossaryEntry = async (rawHref:string) : Promise<GlossaryEntry> => {
	try {
		const getGlossar = await fetch(rawHref);
		if(getGlossar.ok){
			const parser       = new DOMParser();
			const htmlDocument = parser.parseFromString(await getGlossar.text(), "text/html");
			const main         = htmlDocument.documentElement.querySelector("main");
			if(main !== null){
				return {
					html: main.innerHTML,
					type: main.getAttribute("glossary-type") || "unknown"
				};
			}
		}
	} catch(e){ /* Doesn't matter */ }
	return {
		html: `<section content-type="error"><inner-content><h3>Der Glossareintrag konnte nicht geladen werden</h3></inner-content></section>`,
		type: 'error'
	};
};

const loadGlossaryEntry = async (rawHref:string) => {
	const {html, type} = await fetchGlossaryEntry(rawHref);
	const entry = document.createElement("glossary-entry");
	entry.setAttribute("entry-href",getLinkPath(rawHref));
	entry.setAttribute("glossary-type", type);
	entry.innerHTML = `<modal-content>${html}</modal-content>`;
	document.querySelector("main")?.appendChild(entry);
	return showModal(entry);
};

const glossaryClickHandler = async (e:MouseEvent) => {
	e.preventDefault();
	const a = <HTMLAnchorElement | null>e.target;
	if(!a){return;}
	//there can be formatting around
	const anchor = getAnchorTag(a);
	if (!anchor) { return;}
	const href  = getLinkPath(anchor.href);
	const entry = document.querySelector<HTMLElement>(`glossary-entry[entry-href="${href}"]`);
	const modal = entry === null ? await loadGlossaryEntry(href) : showModal(entry);
	setTimeout(initModalRights,0);
	initEmbeddingLinks();
	showEmbeddingSections(modal.querySelector("modal-content"));
	await initExerciseEditors();
};

const initGlossaryLink = (a:HTMLAnchorElement) => {
	a.classList.add("glossary-link");
	a.onclick = glossaryClickHandler;
	a.setAttribute( "glossary-type", getGlossaryType(a.href));
};

const isGlossaryHref = (href:string) => {
	const hrefArr = href.split("/");
	//for multisites, the position of glossar changes to 4
	return hrefArr && (hrefArr.length > 4) && ((hrefArr[3] === "glossar") || (hrefArr[3] === "operatoren") || (hrefArr[4] === "glossar") || (hrefArr[3] === "operatoren"));
};
const getGlossaryType = (href:string) => href.split("/")[3];

const initModalRights = () => {
	for(const license of document.querySelectorAll("modal-content details.license > summary")){
		const licenseContent = license.parentElement?.lastElementChild;
		if((!licenseContent) || (licenseContent.innerHTML.trim().length === 0)){
			license.classList.add("missing-attribution");
		}
		license.parentElement?.setAttribute("open","");
		license.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();
			const target = e.target as HTMLElement | null;
			target?.parentElement?.classList.toggle("active");
		});
	}
};

export const initGlossary = () =>
	document.querySelectorAll("a").forEach(a => isGlossaryHref(a.href) && initGlossaryLink(a));

setTimeout(initGlossary,0);
