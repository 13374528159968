import {html, css, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

@customElement('fancy-number-input')
export class FancyNumberInput extends LitElement {
	static styles = css`
	label {
		display: block;
		margin-top: 2.5rem;
	}

	input {
		font-family: "Livvic", sans-serif;
		font-size: 1.5rem;
		padding: 1rem 1.5rem;
		background-color: #FFFFFF;
		margin: 0 auto 2rem;
		width: 96px;
		margin-inline: auto;
		border: solid 2px #008BCF;
		border-radius: 2px;
		outline: none;
		text-align: center;
		box-sizing: border-box;
	}

	.number-button {
		font-family: "Livvic", sans-serif;
		cursor: pointer;
		user-select: none;
		font-size: 1.5rem;
		padding: 0.5rem 1rem;
		color: #008BCF;
	}
`;

	@property({type: Boolean, attribute: false})
		isFocused = false;

	@property({type: Number, reflect: true})
		value = 0;

	@property({type: Number, reflect: true})
		step = 1;

	@property({type: Number, reflect: true})
		min = 0;

	@property({type: Number, reflect: true})
		max = 99999999;

	@property({type: Boolean})
		readonly = false;

	@query('input')
		_input: HTMLInputElement;

	private readonly _handleFocus;
	private handleFocus() {
		this.isFocused = true;
	}

	private readonly _handleBlur;
	private handleBlur() {
		this.isFocused = false;
	}

	constructor(){
		super();
		this._handleFocus  = this.handleFocus.bind(this);
		this._handleBlur  = this.handleBlur.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('focus', this._handleFocus);
		this.addEventListener('blur', this._handleBlur);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEventListener('focus', this._handleFocus);
		this.removeEventListener('blur', this._handleBlur);
	}

	assignClampedValue(value: number) {
		const newValue = Math.min(this.max, Math.max(this.min, value));
		if(newValue !== this.value){
			this.value = newValue;
		}
		if(this._input){
			this._input.value = this.value.toString();
		}
	}

	_handleInput() {
		this.assignClampedValue(parseInt(this._input.value));
	}

	decrement() {
		this.assignClampedValue(this.value - this.step);
	}

	increment() {
		this.assignClampedValue(this.value + this.step);
	}

	render() {
		return html`
		<label class="${(!this.readonly && this.isFocused) ? "focus" : ""}">
			<span class="number-button" @click=${this.decrement}>-</span>
			${this.readonly
		? html`<input value="${this.value}" readonly/>`
		: html`<input value="${this.value}" @input="${this._handleInput}"/>`}
			<span class="number-button" @click=${this.increment}>+</span>
		</label>`;
	}
}
