import { IXAPIEntry, apiStudentXAPIGet, apiTeacherXAPIGet, apiXAPISet } from "@digitale-lernwelten/ugm-client-lib";
import { XAPIEvent } from "../content_types/tab-box/xapi";
import { getCourse, isLoggedIn, isStudent, isTeacher } from './user-data';
import { DataStore } from "./datastore";
import { isDevMode } from "../dev-tools";

export interface IXAPITaskEntryRaw {
	taskId: string;
	goalId: string;
	studentId: string;
	verb: string;
	xapi: any;
	createdAt: number;
}

const xAPIEvents: Map<string, IXAPITaskEntryRaw> = new Map();
(window as any).xAPIEvents = xAPIEvents;

const clearAll = () => xAPIEvents.clear();

export interface EventQueueEntry {
	key: string;
	taskId: string;
	value: any;
}
const eventQueue:Record<string, EventQueueEntry> = {};

const loadEventQueue = () => {
	try {
		const lsQueue = JSON.parse(window.localStorage.getItem("xAPIEventQueue") || "{}");
		for(const k in lsQueue){
			eventQueue[k] = lsQueue[k];
		}
	} catch {
		/* Nothing to do here */
	}
};

const storeEventQueue = () => window.localStorage.setItem("xAPIEventQueue", JSON.stringify(eventQueue));
const flushEventQueue = () => window.localStorage.setItem("xAPIEventQueue", "{}");

const queueEvent = (taskId: string, value: any) => {
	const key = 'xAPIEventQueue_' + new Date().getTime() + '_' + taskId;
	const entry:EventQueueEntry = {key, taskId, value};

	loadEventQueue();
	eventQueue[key] = entry;
	window.localStorage.setItem("xAPIEventQueue", JSON.stringify(eventQueue));
	storeEventQueue();
	emptyEventQueue();
};

const setEventQueueEntry = async (key: string) => {
	const entry = eventQueue[key];
	if(!entry){
		delete eventQueue[key];
		storeEventQueue();
		return;
	}
	if(key !== entry.key){
		console.error({key, entry});
		throw new Error("Invalid key");
	}
	await apiXAPISet(entry.taskId, entry.value);
	delete eventQueue[key];
	storeEventQueue();
	await store.poll();
};

const emptyEventQueue = async () => {
	loadEventQueue();

	for(const key in eventQueue) {
		try {
			setEventQueueEntry(key);
		} catch {
			/* Nothing to do here */
		}
	}
};



window.addEventListener("ugm-logged-out", flushEventQueue);

const set = async (taskId: string, value: any) => {
	if(!isLoggedIn()){
		return;
	}
	if(isTeacher()){
		return;
	}
	if(!value){
		return;
	}

	queueEvent(taskId, value);
};

const doPoll = async (after?: Date) => {
	if (isLoggedIn()) {
		if(isTeacher()){
			const course = getCourse();
			if(!course){
				return [];
			}
			return await apiTeacherXAPIGet(course.id, after);
		} else {
			return await apiStudentXAPIGet(after);
		}
	} else {
		return [];
	}
};

const xAPIAddOrUpdate = (e: any) => {
	if(e.exerciseId){
		e.taskId = e.exerciseId;
	}
	xAPIEvents.set(e.id, toTaskEntry(e));
};

const store: DataStore<IXAPIEntry> = new DataStore("ugm-xapi", xAPIAddOrUpdate, clearAll, doPoll);


const toTaskEntry = (e:any):IXAPITaskEntryRaw => {
	if(typeof e.studentId !== "string"){ throw new Error("Invalid XAPIEvent, missing studentId"); }
	if(typeof e.taskId !== "string"){ throw new Error("Invalid XAPIEvent, missing taskId"); }
	if(typeof e.value !== "object"){ throw new Error("Invalid XAPIEvent, missing rawdata"); }

	if(typeof e.value.learningGoal !== "string"){ throw new Error("Invalid XAPIEvent, missing learningGoal"); }
	if(typeof e.value.verb !== "string"){ throw new Error("Invalid XAPIEvent, missing verb"); }
	if(typeof e.createdAt !== "string"){ throw new Error("Invalid XAPIEvent, missing createdAt"); }
	const createdAt = Math.floor(Number(new Date(e.createdAt)) / 1000);

	return {
		taskId: e.taskId,
		studentId: e.studentId,
		goalId: e.value.learningGoal,
		verb: e.value.verb,
		xapi: e.value.rawdata,
		createdAt
	};
};

export const getAllXAPIEntries = ():IXAPITaskEntryRaw[] => {
	return Array.from(xAPIEvents.values());
};

export const syncXApi = async () => await store.poll();

document.addEventListener("xAPI", (raw:CustomEvent) => {
	const e = raw.detail as XAPIEvent;
	if (!e.verb || !e.taskId || !e.rawdata) {
		throw new Error("Invalid xAPI Event");
	}
	if(isDevMode()){
		console.log(`XApi ${e.verb}`, e);
	}
	switch (e.verb) {
	case "attempted":
		break;
	case "answered":
		if(e.rawdata && isStudent() && e.rawdata?.data?.statement?.result){
			set(e.taskId, e);
		}
		break;
	case "completed":
		if(e.rawdata && isStudent() && e.rawdata?.data?.statement?.result){
			set(e.taskId, e);
		}
		break;
	default:
		break;
	}
});
