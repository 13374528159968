import {customElement, property} from "lit/decorators.js";
import {css, html, LitElement} from "lit";
import {ugmLogout} from "../../user-group-management";
import {apiGetSelf} from "@digitale-lernwelten/ugm-client-lib";

@customElement("hamburg-logout")
export class HamburgLogout extends LitElement {

	static styles = css`
		h1 {
			color: white;
			margin-inline: auto;
		}

		section {
			display: flex;
			flex-flow: column nowrap;
		}

		button {
			font-family: "Livvic", sans-serif;
			font-size: 2rem;
			color: white;
			width: fit-content;
			margin-inline: auto;
			padding: 0.5rem 4rem;
			border: none;
			background-color: #E3000F;
			margin-top: 48px;
			cursor: pointer;
		}

		button:hover {
			background-color: #a0000a;
		}

		button:active {
			background-color: #ff2938;
		}
	`;

	@property()
		redirectTarget =  window.location.origin;

	@property()
		loginName = "";

	async connectedCallback() {
		super.connectedCallback();
		const user = await apiGetSelf();
		if(!user) {return;}
		this.loginName = user.loginName;
	}

	async _logoutUser() {
		await ugmLogout();
		window.location.reload();
	}

	_redirectToLanding() {
		window.location.replace(this.redirectTarget);
	}

	render() {
		return html`
			<section>
				<h1>Hallo ${this.loginName}! Du bist bereits eingeloggt.</h1>
				<button @click=${this._redirectToLanding}>Zur Startseite</button>
				<button @click=${this._logoutUser}>Abmelden</button>
			</section>

		`;
	}
}
