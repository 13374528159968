import { clearCookie, getCookie, setCookie } from "../_helper";
import "./devMode.css";

let devMode = false;
export const isDevMode = () => devMode;

document.querySelectorAll("h1").forEach(h => {
	let counter = 0;
	h.addEventListener('click', () => {
		if(++counter > 10){
			document.body.parentElement?.setAttribute("dev-mode", "1");
			setCookie("devMode", "1");
			devMode = true;
		}
		if(counter > 20){
			document.body.parentElement?.removeAttribute("dev-mode");
			clearCookie("devMode");
			devMode = false;
		}
	});
});

if(getCookie("devMode") === "1"){
	document.body.parentElement?.setAttribute("dev-mode", "1");
	devMode = true;
}
