import Configuration from "../../../configuration";
import { overlayCloseHandlers } from "../../overlay/overlay";
import { initGlossary } from "../glossary/glossary";

import "./gallery.css";

interface PhotoSwipeItem {
	src: string,
	msrc: string | null,
	title: string | null,
	w: number,
	h: number,
	figure?: HTMLElement,
	figcaption?: HTMLElement,
	imgTag?: HTMLImageElement,
	start?: boolean
}

declare const PhotoSwipeUI_Default: any;
declare const PhotoSwipe: {
	init: () => void,
	listen: (eventType:string, cb:() => void) => void,
	getCurrentIndex: () => number,
	new(pswpElement:HTMLElement, ui:any, items:PhotoSwipeItem[], options:any): typeof PhotoSwipe
};

let currentGallery:any = null;

export const closeCurrentGallery = () => {
	if(!currentGallery){return;}
	currentGallery.close();
	currentGallery = null;
};
overlayCloseHandlers.push(closeCurrentGallery);

const initPhotoswipe = () => {
	const pswpMarkup = '<div class="pswp__bg"></div><div class="pswp__scroll-wrap"><div class="pswp__container"> <div class="pswp__item"></div><div class="pswp__item"></div><div class="pswp__item"></div></div><div class="pswp__ui pswp__ui--hidden"> <div class="pswp__top-bar"> <div class="pswp__counter"></div><button class="pswp__button pswp__button--close" title="Close (Esc)"></button> <button class="pswp__button pswp__button--share" title="Share"></button> <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button> <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button> <div class="pswp__preloader"> <div class="pswp__preloader__icn"> <div class="pswp__preloader__cut"> <div class="pswp__preloader__donut"></div></div></div></div></div><div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"> <div class="pswp__share-tooltip"></div></div><button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"> </button> <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"> </button> <div class="pswp__caption"> <div class="pswp__caption__center"></div></div></div></div>';
	const ele = document.createElement("div");
	ele.id = "pswp";
	ele.classList.add("pswp");
	ele.setAttribute("tabindex",String(1));
	ele.setAttribute("role","dialog");
	ele.setAttribute("aria-hidden","true");
	ele.innerHTML = pswpMarkup;
	document.querySelector("body")?.append(ele);
};

export const initGallery = () => {
	const pswpElement = document.getElementById("pswp");
	const galleries = document.querySelectorAll<HTMLElement>('section[content-type="gallery"] > inner-content > details');
	galleries.forEach(gallery => {

		let captionWrap = gallery.querySelector("caption-wrap");
		if(!captionWrap){
			captionWrap = document.createElement("caption-wrap");
			gallery.after(captionWrap);
		}

		let fullscreenButton = gallery.querySelector<HTMLButtonElement>(`button[button-type="fullscreen"]`);
		if(!fullscreenButton){
			fullscreenButton = document.createElement("button");
			fullscreenButton.setAttribute("button-type","fullscreen");
			gallery.appendChild(fullscreenButton);
		}

		let previousSlideButton = gallery.querySelector<HTMLButtonElement>(`button[button-type="previous-slide"]`);
		if(!previousSlideButton){
			previousSlideButton = document.createElement("button");
			previousSlideButton.setAttribute("button-type","previous-slide");
			gallery.appendChild(previousSlideButton);
		}

		let nextSlideButton = gallery.querySelector<HTMLButtonElement>(`button[button-type="next-slide"]`);
		if(!nextSlideButton){
			nextSlideButton = document.createElement("button");
			nextSlideButton.setAttribute("button-type","next-slide");
			gallery.appendChild(nextSlideButton);
		}

		const appendFigCaptions = captionWrap.children.length === 0;

		const items:PhotoSwipeItem[] = [];
		for(const figure of Array.from(gallery.querySelectorAll('figure'))){
			const imgTag = figure.querySelector("img");
			if(!imgTag){continue;}
			const src   = imgTag.getAttribute("raw-src") || imgTag.src;
			const msrc  = imgTag.getAttribute("src");
			const w     = parseInt(imgTag.getAttribute("raw-width") || "0") || parseInt(imgTag.getAttribute("width") || "0");
			const h     = parseInt(imgTag.getAttribute("raw-height")|| "0") || parseInt(imgTag.getAttribute("height") || "0");
			const figcaption = figure.querySelector("figcaption");
			if (figcaption) {
				const title = figcaption ? figcaption.outerHTML : "";
				items.push({src, w, h, msrc, title, figure, figcaption, imgTag});
				if(appendFigCaptions){
					captionWrap.append(figcaption);
				}
				figure.classList.add("hidden");
				figcaption.classList.add("hidden");
			}
		}
		if(items.length === 0){return;}
		items[0].figure?.classList.remove("hidden");
		items[0].figcaption?.classList.remove("hidden");
		gallery.setAttribute("open", "true");
		const options = {
			index:0,
			loop: Configuration.galleryWrapAround,
			bgOpacity: Configuration.galleryBackgroundOpacity,
			closeOnScroll: false,
			shareEl: false,
			getThumbBoundsFn:()=>{
				const rect = items[options.index].imgTag?.getBoundingClientRect();
				const ret = {
					x: rect?.x||0,
					y:(rect?.y||0) + (document.children[0].scrollTop),
					w:rect?.width||0
				};
				return ret;
			}
		};

		const setSlideWrap = (i:number) => {
			if(i < 0){
				if(items.length <= 0){return;}
				setSlide(items.length-1);
				return;
			}else if(i >= items.length){
				setSlide(0);
				return;
			}

			let ci = 0;
			for(const slide of items){
				if(ci++ === i){
					slide.figure?.classList.remove("hidden");
					slide.figcaption?.classList.remove("hidden");
				}else{
					slide.figure?.classList.add("hidden");
					slide.figcaption?.classList.add("hidden");
				}
			}
			options.index = i;
		};

		const setSlideNoWrap = (i:number) => {
			if(Configuration.galleryWrapAround){
				if(i < 0){
					if(items.length <= 0){return;}
					setSlide(items.length-1);
					return;
				}else if(i >= items.length){
					setSlide(0);
					return;
				}
			}else{
				if(i < 0){
					if(items.length <= 0){return;}
					setSlide(0);
					return;
				}else if(i >= items.length){
					setSlide(items.length-1);
					return;
				}
			}

			let ci = 0;
			for(const slide of items){
				if(ci++ === i){
					slide.figure?.classList.remove("hidden");
					slide.figcaption?.classList.remove("hidden");
				}else{
					slide.figure?.classList.add("hidden");
					slide.figcaption?.classList.add("hidden");
				}
			}
			options.index = i;
		};

		const setSlide = Configuration.galleryWrapAround ? setSlideWrap : setSlideNoWrap;

		gallery.onclick = e => e.preventDefault();
		fullscreenButton.onclick = e => {
			e.preventDefault();
			if(currentGallery){currentGallery.close();}
			if(!pswpElement){return;}
			const gal = currentGallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gal.init();
			gal.listen('close', () => {
				fullscreenButton?.classList.remove("active");
				currentGallery = null;
			});
			gal.listen('beforeChange', () => {
				setSlide(gal.getCurrentIndex());
				initGlossary();
			});
			fullscreenButton?.classList.add("active");
		};
		previousSlideButton.onclick = () => setSlide(options.index - 1);
		nextSlideButton.onclick = () => setSlide(options.index + 1);
	});
};

const determineImageDimensions = (href:string):Promise<[number, number]> => new Promise(resolve => {
	const ele = new Image();
	ele.onload = () => {
		resolve([ele.naturalWidth, ele.naturalHeight]);
	};
	ele.src = href;
});

export const lightboxShowHref = async (hrefs:string[], startIndex:number) => {
	const pswpElement = document.getElementById("pswp");
	if(!hrefs.length){return;}
	if(!pswpElement){return;}
	const items:PhotoSwipeItem[] = [];
	for(const href of hrefs) {
		const [w,h] = await determineImageDimensions(href);
		const item = ({
			src: href,
			msrc: null,
			title: null,
			w,
			h
		});
		items.push(item);
	}

	const options = {
		index:startIndex,
		bgOpacity: Configuration.galleryBackgroundOpacity,
		closeOnScroll: false,
		shareEl: false
	};

	const gal = currentGallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items,options);
	gal.init();
	gal.listen('close', () => {
		currentGallery = null;
	});
};

const lightboxCreateItem = (single:HTMLElement, startFigure:HTMLElement) : PhotoSwipeItem | null => {
	const imgTag = <HTMLImageElement | null>single.querySelector("img");
	const figCaption = single.querySelector("figcaption");
	const license = single.querySelector("details");
	if (!license) { return null;}
	const licenceClone = license.cloneNode(true);
	const captionClone = figCaption?.cloneNode(true);
	const captionContainer = document.createElement("caption-container");
	captionContainer.append(licenceClone);
	captionContainer.append(captionClone ? captionClone : "");
	if(imgTag === null){return null;}
	const start = single === startFigure;
	const src   =  imgTag.getAttribute("raw-src") || imgTag.src;
	const msrc  =  imgTag.getAttribute("src");
	const w     = parseInt(imgTag.getAttribute("raw-width") || "0") || parseInt(imgTag.getAttribute("width") || "0");
	const h     = parseInt(imgTag.getAttribute("raw-height")|| "0") || parseInt(imgTag.getAttribute("height") || "0");
	const title = captionContainer ? captionContainer.innerHTML : "";
	return {start,src,msrc,w,h,title};
};

export const initLightbox = () => {
	const pswpElement = document.getElementById("pswp");
	const main = document.querySelector("main");
	if(main?.getAttribute("page-type") === "overview" || main?.getAttribute("page-type") === "overviewPinned"){
		return; // Don't initialize single lightboxes on overview pages, this is mainly so we can reuse the heroimage for the background
	}
	const singles = document.querySelectorAll<HTMLElement>('figure[figure-type="picture"], section[hero-type="header"] figure[figure-type="hero-image"], figure-row>[figure-type="gallery"]');
	const doNothingSpecial = (e:Event) => {e.stopPropagation();};
	singles.forEach(single => {
		const items:PhotoSwipeItem[] = [];
		const figCaption = single.querySelector<HTMLElement>("figcaption");
		const introduction = single.querySelector<HTMLElement>("gallery-introduction");
		const addItem = (itm:HTMLElement) => {
			const newItem = lightboxCreateItem(itm, single);
			if(newItem){items.push(newItem);}
		};
		if(single.parentElement?.tagName === 'FIGURE-ROW'){
			Array.from(single.parentElement?.parentElement?.children || []).filter(e => e.tagName === 'FIGURE-ROW').forEach(row => Array.from(row.children).forEach(addItem));
		}else{
			Array.from(single.parentElement?.children || []).filter(e => e.tagName === 'FIGURE').forEach(addItem);
		}
		const startIndex = items.filter(e => e).findIndex(e => e.start);

		const options = {
			index:Math.max(0,startIndex),
			bgOpacity: Configuration.galleryBackgroundOpacity,
			closeOnScroll: false,
			shareEl: false,
			getThumbBoundsFn:()=>{
				const rect = single.getBoundingClientRect();
				const ret = {
					x: rect.x|0,
					y:(rect.y|0) + (document.children[0].scrollTop),
					w:rect.width|0
				};
				return ret;
			}
		};
		if(items.length === 0){return;}

		let fullscreenButton = single.querySelector<HTMLButtonElement>(`button[button-type="fullscreen"]`);
		if(!fullscreenButton){
			fullscreenButton = document.createElement("button");
			fullscreenButton.setAttribute("button-type","fullscreen");
			single.insertBefore(fullscreenButton, introduction || figCaption);
		}
		single.classList.add("lightbox-bound");

		for(const link of Array.from(single.querySelectorAll("a"))){
			link.onclick = doNothingSpecial;
		}

		const startGallery = (e:MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();
			if (currentGallery) { currentGallery.close(); }
			if (!pswpElement) { return; }
			const gal = currentGallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items,options);
			gal.init();
			gal.listen('close', () => {
				fullscreenButton?.classList.remove("active");
				currentGallery = null;
			});
			initGlossary();
			fullscreenButton?.classList.add("active");
		};
		single.onclick = startGallery;
		fullscreenButton.onclick = startGallery;

		if (items.length > 1) {
			const parentContainer = single.parentElement;
			if(!parentContainer) {return;}
			if (parentContainer.nodeName === "FIGURE-ROW") { return;}
			parentContainer.setAttribute("index", "0");

			const updateFigures = (parentContainer:HTMLElement|null) => {
				if (!parentContainer) { return;}
				const allFigures = parentContainer.querySelectorAll("figure");
				allFigures.forEach((fig:HTMLElement, i:number) => {
					const curIndex =  parentContainer.getAttribute("index") as string;
					const cui = parseInt(curIndex);
					if (i === cui) {
						fig.classList.remove("hidden");
						fig.classList.add("show");
					} else {
						fig.classList.add("hidden");
						fig.classList.remove("show");
					}
				});
			};
			updateFigures(parentContainer);

			const setSlideWrap = (next:boolean) => {
				const curIndex =  parentContainer.getAttribute("index") as string;
				const cui = parseInt(curIndex);
				if (next) {
					if (cui + 1 >= items.length) {
						parentContainer.setAttribute("index", "0");
					} else {
						parentContainer.setAttribute("index", `${cui + (next? 1:-1)}`);
					}
				} else {
					if(cui - 1 <= 0) {
						parentContainer.setAttribute("index", `${items.length - 1}`);
					}
					else {
						parentContainer.setAttribute("index", `${cui + (next? 1:-1)}`);
					}
				}
			};

			const setSlideNoWrap = (next:boolean) => {
				const curIndex =  parentContainer.getAttribute("index") as string;
				const cui = parseInt(curIndex);
				if (next) {
					if (cui + 1 >= items.length) {
						parentContainer.setAttribute("index", `${items.length - 1}`);
					} else {
						parentContainer.setAttribute("index", `${cui + (next? 1:-1)}`);
					}
				} else {
					if(cui - 1 <= 0) {
						parentContainer.setAttribute("index", "0");
					}
					else {
						parentContainer.setAttribute("index", `${cui + (next? 1:-1)}`);
					}
				}
			};

			const setSlide = Configuration.galleryWrapAround
				? setSlideWrap
				: setSlideNoWrap;

			let previousSlideButton = single.querySelector<HTMLButtonElement>(`button[button-type="previous-slide"]`);
			if(!previousSlideButton){
				previousSlideButton = document.createElement("button");
				previousSlideButton.setAttribute("button-type","previous-slide");
				single.appendChild(previousSlideButton);
			}
			let nextSlideButton = single.querySelector<HTMLButtonElement>(`button[button-type="next-slide"]`);
			if(!nextSlideButton){
				nextSlideButton = document.createElement("button");
				nextSlideButton.setAttribute("button-type","next-slide");
				single.appendChild(nextSlideButton);
			}

			previousSlideButton.onclick = (e) => {
				e.preventDefault;
				e.stopPropagation();
				setSlide(false);
				updateFigures(parentContainer);
			};
			nextSlideButton.onclick = (e) => {
				e.preventDefault;
				e.stopPropagation();
				setSlide(true);
				updateFigures(parentContainer);
			};
		}
	});
};
setTimeout(initPhotoswipe,0);
setTimeout(initGallery,0);
setTimeout(initLightbox,0);

