import { IStudent } from "@digitale-lernwelten/ugm-client-lib";
import { LearningGoal, TrainingTask, getTrainingTaskMap } from "../../content_types/training/training";
import { IXAPITaskEntryRaw, getAllXAPIEntries } from "../xapi";
import { getUser, isTeacher } from "../user-data";
import { compareTaskId, normalizeTaskId } from "../../content_types";
import { isDevMode } from "../../dev-tools";

export interface IXAPITaskEntryScore {
	tryIndex: number;
	rawScore: number;
	weightedScore: number;
}

export interface IXAPITaskEntry {
	taskId: string;
	task?: TrainingTask;
	goal: LearningGoal;
	studentId: string;
	createdAt: number;
	score?: IXAPITaskEntryScore;
	verb: string;
	xapi: any;
}

export interface StudentScore {
	student: IStudent;
	level: number;
	trainingLevel: number;
	events: IXAPITaskEntry[];
}

export interface TaskScore {
	average: number;
	studentsFinished: number;
}

export interface UserTaskScore {
	average: number;
	level: number;
	tries: number;
}

const calcStudentScore = (student: IStudent, events: IXAPITaskEntry[]): StudentScore => {
	const level = getLevelFromResults(events);
	const trainingLevel = getTrainingLevelFromResults(events);
	return {
		student,
		level,
		trainingLevel,
		events
	};
};

export const getLearningGoalXAPIEntries = (goal: LearningGoal, events: IXAPITaskEntryRaw[]):IXAPITaskEntry[] => {
	const taskMap = getTrainingTaskMap(goal);
	return events
		.filter(e => (e.verb === "answered") || (e.verb === "completed"))
		.filter(e => e.goalId === goal.id)
		.sort((a,b) => a.createdAt - b.createdAt)
		.map(e => ({
			goal: goal,
			taskId: e.taskId,
			task: taskMap.get(e.taskId),
			studentId: e.studentId,
			createdAt: e.createdAt,
			verb: e.verb,
			xapi: e.xapi
		}));
};

const getSingleResultSuccessPercentage = (result: IXAPITaskEntry): number => {
	const raw = result.xapi.data;
	if(!raw){
		console.log("!raw", result);
		return 0;
	}
	const score = raw?.statement?.result?.score;
	if(!score){
		console.log("!score", result);
		return 0;
	}
	if(isDevMode()){
		console.log("score", score.scaled, result);
	}
	return score.scaled;
};

export const getTaskScore = (results: IXAPITaskEntry[]): number => {
	const sorted = results.sort((a,b) => a.createdAt - b.createdAt);
	let maxScore = 0;
	for(let i=0;i<sorted.length;i++){
		const multiplier = Math.max(0, 1 - i * 0.1);
		const curScore = getSingleResultSuccessPercentage(results[i]) * multiplier;
		maxScore = Math.max(curScore, maxScore);
	}
	return maxScore * 100;
};

export const getResultSuccessPercentage = (results: IXAPITaskEntry[]): number => {
	if(results.length === 0){
		return 0;
	}
	const p = results.map(getSingleResultSuccessPercentage);
	return Math.round((p.reduce((acc, r) => acc + r, 0) / p.length)*100);
};

export const getLevelFromResults = (results: IXAPITaskEntry[]): number => {
	const taskResultMap = new Map<string, IXAPITaskEntry[]>();
	for(const r of results){
		if(r.task){continue;}
		const taskId = normalizeTaskId(r.taskId);
		if(!taskResultMap.has(taskId)){
			taskResultMap.set(taskId, []);
		}
		taskResultMap.get(taskId)?.push(r);
	}
	const scores:number[] = [];
	for(const a of taskResultMap.values()){
		a.sort((a,b) => a.createdAt - b.createdAt);
		for(let i=0;i<a.length;i++){
			const rawScore = getSingleResultSuccessPercentage(a[i]);
			const multiplier = Math.min(1, Math.max(0, 1 - (i * 0.1)));
			const weightedScore = rawScore * multiplier;
			a[i].score = {
				tryIndex: i,
				rawScore,
				weightedScore
			};
		}
		const taskScore = a.reduce((acc, r) => Math.max(acc, r.score?.weightedScore || 0), 0);
		scores.push(taskScore);
	}
	if(scores.length === 0){
		return 1;
	}
	const avg = scores.reduce((acc, c) => acc + c, 0) / scores.length;
	if(avg >= 0.85){
		return 2;
	} else if(avg >= 0.5){
		return 1;
	} else {
		return 0;
	}
};

export const getTrainingLevelFromResults = (results: IXAPITaskEntry[]): number => {
	const trainingResults = results.filter(r => r.task);
	if(trainingResults.length <= 0){
		return getLevelFromResults(results);
	}
	const sorted = trainingResults.sort((a,b) => a.createdAt - b.createdAt);
	const newest = sorted[sorted.length-1];
	if(!newest.task){
		throw new Error("Invalid training result");
	}
	const newestScore = getSingleResultSuccessPercentage(newest);
	if(newestScore >= 0.85) {
		return Math.min(2, parseInt(newest.task.difficulty)+1);
	} else if(newestScore >= 0.5) {
		return parseInt(newest.task.difficulty);
	}else{
		return Math.max(0, parseInt(newest.task.difficulty)-1);
	}
};


const filterByUser = (results: IXAPITaskEntry[], student: IStudent) => {
	return results.filter(x => x.studentId === student.id);
};

const filterByUserId = (results: IXAPITaskEntry[], studentId: string) => {
	return results.filter(x => x.studentId === studentId);
};

const filterByTask = (results: IXAPITaskEntry[], taskID: string) =>
	results.filter(x => compareTaskId(x.taskId, taskID));

const filterByDate = (results: IXAPITaskEntry[], before?: Date):IXAPITaskEntry[] => {
	if(!before){
		return results;
	}
	return results;
};

export const getTaskResults = (goal: LearningGoal, taskID: string): TaskScore => {
	let results = getLearningGoalXAPIEntries(goal, getAllXAPIEntries());
	results = filterByTask(results, taskID);
	const userResults = new Map<string, IXAPITaskEntry>();
	for(const r of results){
		if(!userResults.has(r.studentId) || (r.createdAt > (userResults.get(r.studentId)?.createdAt || 0))){
			userResults.set(r.studentId, r);
		}
	}
	let average = 0;
	for(const r of userResults.values()){
		average += getSingleResultSuccessPercentage(r);
	}
	average = average / userResults.size;
	return {
		average,
		studentsFinished: userResults.size
	};
};

export const getUserTaskResults = (goal: LearningGoal, userId: string, taskID: string): UserTaskScore => {
	let results = getLearningGoalXAPIEntries(goal, getAllXAPIEntries());
	results = filterByUserId(results, userId);
	results = filterByTask(results, taskID);
	const tries = results.length;
	const userResults = new Map<string, IXAPITaskEntry>();
	for(const r of results){
		if(!userResults.has(r.studentId) || (r.createdAt > (userResults.get(r.studentId)?.createdAt || 0))){
			userResults.set(r.studentId, r);
		}
	}
	let average = 0;
	const allResults = getLearningGoalXAPIEntries(goal, getAllXAPIEntries());
	for(const r of userResults.values()){
		const events = filterByUserId(allResults, r.studentId).filter(e => compareTaskId(e.taskId, taskID));
		const taskScore = getTaskScore(events);
		average += taskScore;
	}
	average = average / userResults.size;
	const score = getLevelFromResults(results);
	return {
		average,
		level: score,
		tries
	};
};

export const getUserResults = (student: IStudent, goal: LearningGoal, before?: Date): StudentScore => {
	let results = getLearningGoalXAPIEntries(goal, getAllXAPIEntries());
	results = filterByUser(results, student);
	results = filterByDate(results, before);
	const score = calcStudentScore(student, results);
	if(isDevMode()){
		console.log({score, results});
	}
	return score;
};

export const getCurrentLevel = (goal: LearningGoal, before?: Date): number => {
	if (isTeacher()) {
		return 2;
	}
	const student = getUser() as IStudent;
	const res = getUserResults(student, goal, before);
	return res.level;
};

export const getCurrentTrainingLevel = (goal: LearningGoal, before?: Date): number => {
	if (isTeacher()) {
		return 2;
	}
	const student = getUser() as IStudent;
	const res = getUserResults(student, goal, before);
	return res.trainingLevel;
};

export const getHRLevel = (level:string):string => {
	switch(level){
	case "0":
		return `<span class="starIcon"></span>`;
	case "1":
		return `<span class="starIcon"></span><span class="starIcon"></span>`;
	case "2":
		return `<span class="starIcon"></span><span class="starIcon"></span><span class="starIcon"></span>`;
	default:
		return "UNKNOWN";
	}
};
