import { ICoursePad, apiDeleteCoursePad, apiGetCoursePads, apiJoinOrCreateCoursePad } from '@digitale-lernwelten/ugm-client-lib';
import configuration from '../../../configuration';
import { showModal } from '../../modal/modal';
import { getCourse, isLoggedIn, isTeacher } from '../user-data';
import { clearCookie, getCookie, getFirstParentSection, onEnterDo, setCookie } from '../../_helper';
import { DataStore } from '../datastore';
import t from '../../i18n';

import "./course-pad.css";

const addSessionID = (cookieName:string, newSessionId:string) => {
	const cookie = getCookie(cookieName) || '';
	const ids = cookie.split(",");
	const idSet = new Set(ids);
	idSet.add(newSessionId);
	const newIDs = Array.from(idSet.values()).filter(s => s !== '');
	setCookie(cookieName, newIDs.join(','));
};

const getPadInitialText = (padId:string):string => {
	const padMarker = document.querySelector<HTMLElement>(`pad-marker[pad-id="${padId}"]`);
	if(!padMarker){return '';}
	const padID = padMarker.getAttribute("pad-id");
	if(!padID){return '';}
	return getFirstParentSection(padMarker)?.querySelector<HTMLElement>("exercise-preset")?.innerText || '';
};

const showPad = async (exerciseId: string, name: string) => {
	const initialText = getPadInitialText(exerciseId);
	const data = await apiJoinOrCreateCoursePad(exerciseId, name, initialText, getCourse()?.id);
	addSessionID("sessionID", data.sessionID);
	const modal = showModal(`<br/><iframe src="${data.padUrl}" class="course-pad"></iframe>`);
	const content = modal.querySelector("modal-content");
	if(!content){
		return;
	}

	const h = document.createElement("h3");
	h.innerText = name;
	content.prepend(h);

	const back = document.createElement("button");
	back.classList.add("course-pad-back");
	back.innerText = 'Zurück zur Auswahl';
	back.onclick = () => {
		showPadChooserDialog(exerciseId);
	};
	content.prepend(back);
	await store.poll();
};

const showPadChooserDialog = async (exerciseId:string) => {
	const html = `
		<h3>${t().coursePadHeader}</h3>
		<br/>
		<div class="course-pad-wrap" exercise-id="${exerciseId}"></div>
		<div class="new-course-wrap">
			<input type="text" class="new-course-pad-name" required/>
			<button class="new-course-pad">Neue Gruppe</button>
			<div class="new-course-error"></div>
		</div>
	`;

	const modal = showModal(html);
	const errors = modal.querySelector<HTMLElement>(".new-course-error");
	if(!errors){
		throw new Error("Can't query error wrapper");
	}

	const newGroupName = modal.querySelector<HTMLInputElement>("input.new-course-pad-name");
	if(!newGroupName){
		throw new Error("Couldn't find new group name input");
	}
	newGroupName.focus();

	const onSubmit = () => {
		const name = String(newGroupName.value);
		if((name.length < 4) || (name.length > 64)) {
			newGroupName.classList.add("invalid");
			errors.innerText = t().coursePadError;
		} else {
			errors.innerText = "";
			newGroupName.classList.remove("invalid");
			showPad(exerciseId, name);
		}
	};

	newGroupName.addEventListener("keypress", onEnterDo((e:Event) => {
		e.preventDefault();
		onSubmit();
	}));

	const newGroupButton = modal.querySelector<HTMLButtonElement>("button.new-course-pad");
	if(!newGroupButton){
		throw new Error("Couldn't find new group button");
	}
	newGroupButton.onclick = onSubmit;
	refreshPad(exerciseId);
};

const refreshPad = (exerciseId:string) => {
	const padMarker = document.querySelector(`pad-marker[pad-id="${exerciseId}"]`);
	if(!padMarker){return;}

	const pads = Array.from(store.values()).filter(n => n.exerciseId === exerciseId).sort((a,b) => {
		const nameCmp = a.name.localeCompare(b.name);
		if(nameCmp === 0){
			return a.id.localeCompare(b.id);
		} else {
			return nameCmp;
		}
	});
	if (pads.filter(p => !p.deletedAt).length > 0) {
		padMarker.classList.add("filled");
		const padGroupWrap = document.querySelector<HTMLElement>(`.course-pad-wrap[exercise-id="${exerciseId}"]`);
		if(padGroupWrap){
			const staleButtons:Map<string, HTMLElement> = new Map();
			for(const but of padGroupWrap.children){
				if(but instanceof HTMLElement){
					const padName = but.getAttribute("pad-name");
					if(typeof padName === "string"){
						const oldButton = staleButtons.get(padName);
						if(oldButton){
							oldButton.remove();
						}
						staleButtons.set(padName, but);
					}
				}
			}
			pads.forEach(note => {
				if(note.deletedAt){
					return;
				}
				const but = staleButtons.get(note.name);
				if(but){
					return;
				}
				const ele = document.createElement("button");
				ele.classList.add("course-pad-group");
				ele.onclick = () => {
					showPad(exerciseId, note.name);
				};
				ele.innerText = note.name;

				const wrap = document.createElement("div");
				wrap.setAttribute("pad-name", note.name);
				wrap.classList.add("course-pad-group-wrap");
				wrap.append(ele);
				staleButtons.set(note.name, wrap);

				if(isTeacher()){
					const ele = document.createElement("button");
					ele.classList.add("course-pad-group-remove");
					ele.onclick = async () => {
						if(wrap.classList.contains("deleting")){
							return;
						}
						const course = getCourse();
						if(!course){
							return;
						}
						wrap.classList.add("deleting");
						await apiDeleteCoursePad(course.id, note.id);
						wrap.remove();
						await store.poll();
						refreshAllPads();
					};
					ele.title = "Entfernen";
					wrap.append(ele);
				}
				padGroupWrap.append(wrap);
			});
		}
	} else {
		padMarker.classList.remove("filled");
	}
};

const refreshAllPads = () => {
	document.querySelectorAll(`pad-marker`).forEach(e => {
		const padID = e.getAttribute("pad-id");
		if(padID){
			refreshPad(padID);
		}
	});
};

const doPoll = async (after?: Date) => {
	if(!configuration.enableCoursePad){
		return [];
	}
	if(!isLoggedIn()){
		return [];
	} else {
		return await apiGetCoursePads(getCourse()?.id, after);
	}
};

const addOrUpdatePad = (pad:ICoursePad) => {
	if(pad.deletedAt){
		const oldPad = store.get(pad.id);
		// Only remove the note if the id is the same, otherwise and old deleted notes
		// will keep us from ever adding another note to that particular exercise
		if(oldPad && (oldPad.id === pad.id)){
			//deletePad(oldPad.id);
		}
	} else {
		refreshPad(pad.exerciseId);
	}
};

const doFlush = () => {
	clearCookie("sessionID");
	refreshAllPads();
};

const store:DataStore<ICoursePad> = new DataStore("ugm-course-pad", addOrUpdatePad, doFlush, doPoll);
window.addEventListener("ugm-course-changed", () => {
	doFlush();
	doPoll();
});

const initPad = () => {
	document.querySelectorAll<HTMLElement>("pad-marker").forEach(padMarker => {
		const padID = padMarker?.getAttribute("pad-id") || window.location.pathname;

		padMarker.onclick = () => showPadChooserDialog(padID);
		refreshPad(padID);
	});
};

setTimeout(initPad, 0);
