import { getFirstParentSection, hideElementContentHandler, randomItem } from "../../_helper";
import { getAllXAPIEntries, syncXApi } from "../../user-group-management/xapi";
import { showTrainingResults } from "./resultOverview";
import { getCurrentLevel, getCurrentTrainingLevel } from '../../user-group-management/scoring/scoring';
import { initMedia } from "../media/media";

import "./training.css";
import { isTeacher } from "../../user-group-management";

import startTrainingAnimation from "../../svg/working-task-loading-animation.mp4";
import endTrainingAnimation from "../../svg/working-task-success-animation.mp4";
import { isDevMode } from "../../dev-tools";

const difficultyToStars = (difficulty: "0"|"1"|"2"):string => {
	switch(difficulty){
	case "0":
		return `<span class="starIcon"></span>`;
	case "1":
		return `<span class="starIcon"></span><span class="starIcon"></span>`;
	case "2":
		return `<span class="starIcon"></span><span class="starIcon"></span><span class="starIcon"></span>`;
	default:
		return difficulty;
	}
};

export interface TrainingTask {
	difficulty: "0"|"1"|"2";
	introduction: string;
	audio: {
		url: string
	}[];
	goal: LearningGoal;
	taskUrl: string;
	socialForm: string;
	targetGroup: string;
	time: string;
	metadata:unknown;
}

export interface LearningGoal {
	id: string;
	level: number;
	slug: string;
	title: string;
	lft: number;
	editLink?: string;
	parent?: LearningGoal;
	children: LearningGoal[];
	tasks: TrainingTask[];
	childDepth?: number;
}

export const getTrainingTaskMap = (goal: LearningGoal) => {
	const tasks = new Map<string, TrainingTask>();
	for(const goal of Object.values(learningGoals)){
		for(const task of goal.tasks){
			tasks.set(wordPressH5PUrlToIframeSrc(task.taskUrl), task);
		}
	}
	for(const task of goal.tasks){
		tasks.set(wordPressH5PUrlToIframeSrc(task.taskUrl), task);
	}
	return tasks;
};

let learningGoals:Record<string, LearningGoal> = {};
let learningGoalPromise:Promise<void> | null = null;
const taskSet = new Set<string>();

export const getLearningGoal = async (cat: string): Promise<LearningGoal | undefined> => {
	await getLearningGoals();
	return learningGoals[cat];
};

export const getLearningGoals = async () => {
	// We do it this way so that even if we request learningGoals multiple times before the fetch
	// succeeded we only ever fetch the json file once
	if(learningGoalPromise){
		await learningGoalPromise;
		if(!Object.values(learningGoals).length){
			throw new Error("No learning goals even though we awaited learningGoalPromise");
		}
		return learningGoals;
	}

	try {
		let resolvePromise:((value: void | PromiseLike<void>) => void) | undefined;
		learningGoalPromise = new Promise(resolve => {
			resolvePromise = resolve;
		});
		learningGoals = await (await fetch("/learning-goals.json")).json();
		for(const id in learningGoals){
			const goal = learningGoals[id];
			// It's probably more convenient to have an actual reference instead of the id in the parent field
			if(typeof goal.parent === "string"){
				goal.parent = learningGoals[goal.parent];
				if(goal.parent){
					if(!goal.parent.children){
						goal.parent.children = [];
					}
					goal.parent.children.push(goal);
				}
			}
			if(!goal.children){
				goal.children = [];
			}
			// Here we clean up the structure of the metadata, might be nicer to move this to the backend though.
			for(const task of goal.tasks){
				const metadata = Array.isArray(task?.metadata) ? task?.metadata : [];
				task.socialForm = metadata[0]?.socialForm || "";
				task.targetGroup = metadata[0]?.targetGroup || "";
				task.time = metadata[0]?.time || "";
				task.goal = goal;
			}
		}
		if(resolvePromise){
			resolvePromise();
		}
		learningGoalPromise = null;
	} catch(e) {
		console.error("Error while fetching training tasks");
		console.error(e);
	}
	return learningGoals;
};

const getGoalTasks = (goal: LearningGoal): Set<TrainingTask> => {
	const ret = new Set<TrainingTask>();
	const merge = (g:LearningGoal) => {
		for(const t of g.tasks){
			ret.add(t);
		}
		g.children.forEach(merge);
	};
	merge(goal);
	return ret;
};

const filterWorkedThroughTasks = (tasks:Set<TrainingTask>, pastTaskList:TrainingTask[]):Set<TrainingTask> => {
	const lastDoneMap = new Map();
	for(const x of getAllXAPIEntries()){
		lastDoneMap.set(x.taskId, Math.max(x.createdAt, lastDoneMap.get(x.taskId) || 0));
	}
	const blacklist = new Set<string>();
	for(const t of pastTaskList){
		blacklist.add(t.taskUrl);
	}

	const cutoff = Math.floor(Date.now() / 1000) - (24*60*60);
	const ret = new Set<TrainingTask>();
	for(const t of tasks.values()){
		if(blacklist.has(t.taskUrl)){
			continue;
		}
		const lastTime = lastDoneMap.get(wordPressH5PUrlToIframeSrc(t.taskUrl)) || 0;
		if(lastTime < cutoff){
			ret.add(t);
		}
	}
	return ret;
};

const filterTasksByDifficulty = (tasks:Set<TrainingTask>):Set<TrainingTask> => {
	const scoreMap = new Map<LearningGoal, number>();
	const ret = new Map<number, Set<TrainingTask>>();
	let minPrio = 999;
	for(const t of tasks.values()){
		let score = 0;
		if(scoreMap.has(t.goal)){
			score = scoreMap.get(t.goal) as number;
		} else {
			score = getCurrentTrainingLevel(t.goal);
			scoreMap.set(t.goal, score);
		}
		const delta = (parseInt(t.difficulty)) - score;
		const prio = (delta >= 0) ? delta * 2 : (delta * -2)-1;
		minPrio = Math.min(prio, minPrio);
		if(!ret.has(prio)){
			ret.set(prio, new Set());
		}
		ret.get(prio)?.add(t);
	}

	for(let i=0;i<8;i++){
		if(ret.has(i)){
			return ret.get(i) as Set<TrainingTask>;
		}
	}
	return new Set();
};

const filterTasksByGoalSpecificity = (tasks:Set<TrainingTask>):Set<TrainingTask> => {
	const ret = new Map<number, Set<TrainingTask>>();
	let maxLevel = -1;
	for(const t of tasks.values()){
		if(!ret.has(t.goal.level)){
			ret.set(t.goal.level, new Set());
		}
		ret.get(t.goal.level)?.add(t);
		maxLevel = Math.max(maxLevel, t.goal.level);
	}

	for(let i=maxLevel;i>=0;i--){
		if(ret.has(i)){
			return ret.get(i) as Set<TrainingTask>;
		}
	}
	return new Set();
};

const filterTasksByLastWorkedThrough = (tasks:Set<TrainingTask>):Set<TrainingTask> => {
	const lastDoneMap = new Map();
	for(const x of getAllXAPIEntries()){
		lastDoneMap.set(x.taskId, Math.max(x.createdAt, lastDoneMap.get(x.taskId)));
	}

	let oldestDone = Number.MAX_SAFE_INTEGER;
	for(const t of tasks.values()){
		const lastDone = lastDoneMap.get(t.taskUrl) || Number.MAX_SAFE_INTEGER;
		oldestDone = Math.min(oldestDone, lastDone);
	}
	const ret = new Set<TrainingTask>();
	for(const t of tasks.values()){
		const lastDone = lastDoneMap.get(t.taskUrl) || Number.MAX_SAFE_INTEGER;
		if(lastDone <= oldestDone){
			ret.add(t);
		}
	}
	return ret;
};

const getNextTrainingTasks = async (cat:string, pastTaskList:TrainingTask[]):Promise<TrainingTask[]> => {
	await syncXApi();
	const goal = (await getLearningGoals())[cat];
	if(!goal){
		throw new Error(`Can't find learningGoal with id=${cat}`);
	}
	let tasks = getGoalTasks(goal);
	tasks = filterWorkedThroughTasks(tasks, pastTaskList);
	tasks = filterTasksByDifficulty(tasks);
	tasks = filterTasksByGoalSpecificity(tasks);
	tasks = filterTasksByLastWorkedThrough(tasks);
	const taskArr = Array.from(tasks.values()).sort((a,b) => a.taskUrl.localeCompare(b.taskUrl));

	return taskArr;
};

export const wordPressH5PUrlToIframeSrc = (rawurl:string) => {
	const url = new URL(rawurl);
	const id = url.searchParams.get("id");
	if(!id){
		throw new Error(`Couldn't determine H5P ID for ${rawurl}`);
	}
	const ret = `${url.origin}/wp-admin/admin-ajax.php?action=h5p_embed&id=${id}`;
	if(ret.startsWith("http://")){
		return 'https://'+ret.substring(7);
	} else {
		return ret;
	}
};

const initSingleTrainingTask = async (wrap:HTMLElement) => {
	const section = getFirstParentSection(wrap);
	const id = section?.getAttribute('content-type-id') || "";
	const cat = wrap.getAttribute('task-cat') || "";
	const count = parseInt(wrap.getAttribute('task-count') || "") || 0;
	if(!cat || !count){
		console.error(`Couldn't initialize training ${id}`);
	}

	const previousButton = document.createElement("button");
	previousButton.classList.add("training-task-button");
	previousButton.classList.add("working-task-button");
	previousButton.classList.add("working-task-prev");

	const nextButton = document.createElement("button");
	nextButton.classList.add("training-task-button");
	nextButton.classList.add("working-task-button");
	nextButton.classList.add("working-task-next");

	const startScreen = document.createElement("training-task-start-screen");
	wrap.append(startScreen);

	const startButton = document.createElement("button");
	startButton.innerHTML = isTeacher()
		? "Klassenergebnisse auswerten"
		: "Passende Übungen<br/>für mich zusammenstellen";
	startButton.classList.add("training-task-start-button");
	startScreen.append(startButton);

	const brainButton = document.createElement("button");
	brainButton.classList.add("training-task-brain-button");
	startScreen.append(brainButton);

	const taskElementList:HTMLElement[] = [];
	const taskList:TrainingTask[] = [];
	const taskPool:TrainingTask[][] = [];
	let currentTaskIndex = -1;

	const showTask = (i:number) => {
		startScreen.remove();
		taskElementList.forEach((task:HTMLElement) => {
			task.classList.add("hidden");
			hideElementContentHandler(task);
		});
		if(!taskElementList[i]){
			const task = taskList[i];
			const iframe = document.createElement("iframe");
			iframe.setAttribute("frameborder","0");
			iframe.setAttribute("allowfullscreen","allowfullscreen");
			iframe.classList.add("h5p-iframe");
			iframe.src = wordPressH5PUrlToIframeSrc(task.taskUrl);

			const audio = (task.audio.length <= 0) ? '' : task.audio.map(a => `<audio controls src="${a.url}"></audio>`).join('');
			const taskWrap = document.createElement("training-task");
			const devInfo = `<dev-info>
				Aufgaben Level: ${difficultyToStars(task.difficulty)}<br/>
				Erarbeitungs Level: ${difficultyToStars(String(getCurrentLevel(task.goal)|0) as any)}<br/>
				&Uuml;bungs Level: ${difficultyToStars(String(getCurrentTrainingLevel(task.goal)|0) as any)}<br/>
				Lernziel: ${task.goal.title}<br/>
				Aufgaben Pool[${taskPool[i].length}]: ${taskPool[i].map((t,i) => `<a href="${t.taskUrl}">${i}</a>`).join(", ")}<br/>
				<br/>
			</dev-info>`;
			const html = devInfo + task.introduction + audio;
			taskWrap.innerHTML = html;
			taskWrap.append(iframe);

			wrap.append(taskWrap);
			taskElementList[i] = taskWrap;
			taskWrap.querySelectorAll<HTMLAudioElement>("audio").forEach(a => initMedia(a));
		}
		taskElementList[i].classList.remove('hidden');
	};

	const refreshPrevNextButtons = () => {
		if(hasPreviousTask()){
			previousButton.classList.remove("hidden");
		} else {
			previousButton.classList.add("hidden");
		}
		if(hasNextTask() && (taskList.length > 0)){
			nextButton.classList.remove("hidden");
		} else {
			nextButton.classList.add("hidden");
		}
	};

	const playStartAnimation = ():Promise<void> => {
		if(isDevMode()){
			return new Promise(resolve => resolve());
		}
		return new Promise(resolve => {
			const vid = document.createElement("video");
			vid.muted = true;
			vid.playsInline = true;
			vid.autoplay = true;
			vid.src = startTrainingAnimation;
			startScreen.classList.add("start-animation-playing");
			startScreen.append(vid);
			let finished = false;
			const finish = () => {
				if(finished){
					return;
				}
				vid.classList.add("fade-out");
				setTimeout(() => {
					vid.remove();
				}, 400);
				finished = true;
				resolve();
			};
			vid.onpause = vid.onended = vid.onerror = finish;
			setTimeout(finish, 5000);
		});
	};

	const getEndTraining = () => {
		startScreen.remove();
		for (const task of wrap.children) {
			if (!task.classList.contains("training-task-button")) {
				task.classList.add("hidden");
			}
			if (task.classList.contains("end-training")) {
				task.remove();
			}
		}
		nextButton.classList.add("hidden");
		previousButton.classList.add("hidden");
		const endTraining = document.createElement("end-training");
		const vid = document.createElement("video");
		vid.muted = true;
		vid.playsInline = true;
		vid.autoplay = true;
		vid.src = endTrainingAnimation;
		endTraining.append(vid);

		const restartButton = document.createElement("button");
		restartButton.innerHTML = "Weitere Übungen generieren";
		restartButton.classList.add("training-task-reset-button");
		restartButton.onclick = async () => {
			endTraining.remove();
			currentTaskIndex = -1;
			taskElementList.forEach((task:HTMLElement) => {
				task.remove();
			});
			taskElementList.length = 0;
			taskList.length = 0;
			taskPool.length = 0;
			nextTask();
			restartButton.remove();
		};
		wrap.append(endTraining);
		wrap.append(restartButton);
	};

	const hasNextTask = () => taskList.length - 1 < count;
	const nextTask = async () => {
		if (taskList.length < count) {
			if((currentTaskIndex+1) >= taskList.length){
				if(taskList.length === 0){
					await playStartAnimation();
				}
				const pool = (await getNextTrainingTasks(cat, taskList)).filter(t => !taskSet.has(t.taskUrl));
				if(pool.length > 0){
					const pick = randomItem(pool) as TrainingTask;
					taskList.push(pick);
					taskPool.push(pool);
					taskSet.add(pick.taskUrl);
				} else {
					getEndTraining();
					return;
				}
			}
			showTask(++currentTaskIndex);
			refreshPrevNextButtons();
		} else {
			getEndTraining();
		}

	};

	if(isTeacher()){
		startButton.onclick = () => showTrainingResults(cat);
		brainButton.onclick = () => showTrainingResults(cat);
	} else {
		wrap.append(previousButton);
		wrap.append(nextButton);
		nextButton.onclick = nextTask;
		startButton.onclick = nextTask;
		brainButton.onclick = nextTask;
	}

	const hasPreviousTask = () => currentTaskIndex > 0;
	const previousTask = () => {
		if(hasPreviousTask()){
			showTask(--currentTaskIndex);
		}
		refreshPrevNextButtons();
	};
	previousButton.onclick = previousTask;
	refreshPrevNextButtons();
};


setTimeout(() => document.querySelectorAll('training-tasks').forEach(initSingleTrainingTask), 0);
