import {customElement} from "lit/decorators.js";
import {css, html, LitElement} from "lit";

import hamburgLogo from "../../svg/hamburg-logo.svg";
import mailIcon from "../../svg/hamburg-mail.svg";
import phoneIcon from "../../svg/hamburg-phone.svg";

@customElement("hamburg-login-header")
export class HamburgLoginHeader extends LitElement {
	static styles = css`
		header {
			display: flex;
			justify-content: space-between;
			align-content: center;
			margin-inline: 3rem;
			padding-bottom: calc(56px / 2);
			color: #2AB0D7;
		}

		header > div {
			flex-shrink: 0;
			flex-basis: 300px;
		}

		a {
			text-decoration: none;
			color: #2AB0D7;
		}

		p {
			margin-top: 0;
			margin-bottom: 8px;
		}

		.header-text {
			font-family: "Livvic", sans-serif;
			font-size: 23px;
			font-weight: bolder;
		}

		.header-icon-text {
			font-family: "Montserrat", sans-serif;
			font-size: 20px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 12px;
		}

		.header-icon-text > span {
			height: 32px !important;
		}

		.hamburg-logo {
			width: 300px;
		}

		@media screen and (max-width: 900px) {
			header {
				display: flex;
				justify-content: space-between;
				margin-inline: 0.25rem;
				border-bottom: 1px solid black;
			}

			.hamburg-logo {
				width: 128px;
			}

			header > div {
				flex-shrink: 0;
				flex-basis: 200px;
			}

			header > div:first-child {
				display: none;
			}

			span {
				font-size: 26px;
			}
		}

		@media screen and (max-width: 500px) {
			.header-text {
				font-size: 16px;
			}

			.header-icon-text {
				font-size: 16px;
			}

			.hamburg-logo {
				width: 64px;
			}
		}
	`;
	render() {
		return html`
			<header>
				<div></div>
				<div class="hamburg-logo"><img src="${hamburgLogo}" /></div>
				<div>
					<p class="header-text">Fragen? Hinweise?</p>
					<p class="header-text">Gesprächsbedarf?</p>
					<p class="header-icon-text"><span><img src="${mailIcon}" /></span><a href="mailto:info@dilewe.de">info@dilewe.de</a></p>
					<p class="header-icon-text"><span><img src="${phoneIcon}" /></span><a href="tel:+4984219862148">08421 / 9862148</a></p>
				</div>
			</header>
		`;
	}
}
