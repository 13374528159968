import {customElement, property} from "lit/decorators.js";
import {html, css, LitElement} from "lit";


@customElement("netbook-license-card")
export class NetbookLicenseCard extends LitElement {
	static styles = css`
	 	* {
			box-sizing: border-box;
		}

		.card {
			cursor: pointer;
			border: 5px solid white;
			background-color: white;
			padding: 2.5rem;
			text-align: center;
			transition: border 150ms, background-color 300ms;
		}

		.card[data-open="1"] {
			border: 5px solid #7EB29C;
			background: #dbf1e8;
		}

		h3 {
			color: #008BCF;
			font-size: 37px;
		}

		.price {
			color: #008BCF;
			font-size: 27px;
			line-height: 1.4em;
		}

		.price-wrapper {
			display: flex;
			flex-flow: row;
			align-items: center;
			gap: 24px;
		}

		span:first-of-type {
			font-family: "Segoe UI", sans-serif;
			font-size: 3rem;
			font-weight: bold;
			line-height: 3rem;
			white-space: nowrap;
		}

		span:last-of-type {
			text-align: left;
		}

		@media screen and (max-width: 500px) {
			.card {
				padding: 1.5rem;
			}

			h3 {
				font-size: 1.6rem;
			}

			.price {
				font-size: 1.4rem;
			}

			span:first-of-type {
				font-size: 1.8rem;
			}
		}

		@media screen and (max-width: 400px) {
			h3 {
				font-size: 1.5rem;
			}

			.price {
				font-size: 1.3rem;
			}

			span:first-of-type {
				font-size: 1.6rem;
			}
		}
	`;

	@property()
		isOpen: boolean = false;

	@property()
		duration: number = 0;

	@property()
		price: number = 0;

	@property()
		count: number = 1;

	render() {
		return html`
			<div class="card" data-open=${this.isOpen ? 1 : 0}>
				<h3>${this.duration} Monate</h3>
				<div class="price-wrapper">
					<span>${this.price} €</span><span>Monat<br/>Klassenlizenz</span>
				</div>
				<p class="price">Gesamtpreis: ${this.duration * this.price * this.count} €</p>
			</div>

		`;
	}
}
