import { ugmLogin, ugmLogout } from "../user-group-management";
import {showModal} from "../../modal/modal";
import { forceHideOverlay } from "../../overlay/overlay";
import configuration from "../../../configuration";
import { getParam, onEnterDo } from "../../_helper";
import t, { getLang } from "../../i18n";

import "./login.css";
import { isLoggedIn } from "../user-data";

export const showRegisterModal = async (userCantClose = false, institutionCode = "") => {
	try {
		await ugmLogout();
	} catch {
		// ignore
	}
	const registerHtml = `
		<register-modal institutionCode="${institutionCode}"></register-modal>
	`;
	showModal(registerHtml, "centered-box", userCantClose);
};

export const showLoginModal = (userCantClose = false) => {
	const pwResetLink = `<a href="${configuration.ugmEndpoint}/reset-password" target="_blank">${t().loginInfoPasswordLink}</a>`;

	const registerText = configuration.target === "geografie"
		? t().registerTextGeo
		: configuration.target === "netbook"
			? t().registerTextNetbook
			: configuration.enforceUGMLogin
				? t().registerTextEnforced
				: t().registerText;

	const resetLink = (configuration.languages.length > 1) && (getLang().toLowerCase() !== 'de')
		? configuration.ugmEndpoint + getLang().toLowerCase() + '/reset-password'
		: configuration.ugmEndpoint + 'reset-password';

	const loginInfoUsername = configuration.target === "geografie"
		? t().loginInfoUsername365
		: t().loginInfoUsername;

	const loginInfoPassword = configuration.target === "geografie"
		? t().loginInfoPassword365(pwResetLink)
		: t().loginInfoPassword;

	const login365Hint = configuration.enableMS365Login
		? `<span class="login-modal-hint">${t().login365Hint}</span>`
		: '';

	const showVidis = configuration.enableVidisLogin && getParam('show-vidis');
	const loginVidisHint = showVidis
		? `<span class="login-modal-hint">${t().loginVidisHint}</span>`
		: '';

	const showEduplaces = configuration.eduplacesLogin && (getParam('show-eduplaces') || configuration.target === "module");
	const loginEduplacesHint = showEduplaces
		? `<span class="login-modal-hint">${t().loginEduplacesHint}</span>`
		: '';

	const openRegisterModal = () => showRegisterModal(userCantClose);

	const eduplacesButton = `<button type="button" class="edu-login-btn edu-login-purple">
	<svg height="65" viewBox="0 0 115.28 81" width="120" xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 30px; fill: currentcolor; vertical-align: -5px; padding-right: 5px;">
		<g fill="currentcolor">
			<path
				d="m115.28 33.48c-.51-7.52-10.05-10.35-22.19-11.13-6.38-14.04-20.97-23.36-37.27-22.26-16.24 1.1-29.4 12.21-33.88 26.9-12.62 2.36-22.45 6.47-21.92 14.29.52 7.75 10.69 11.1 23.4 12.32 3.79 8.9 10.98 15.97 19.88 19.76-.08.38-.12.78-.12 1.18 0 3.27 2.65 5.93 5.93 5.93 2.68 0 4.94-1.78 5.67-4.21 2.03.19 4.1.22 6.2.07 16.74-1.13 30.21-12.74 34.23-27.83 11.71-2.94 20.58-7.55 20.08-15s0 0 0 0zm-54.83 35.08c-11.69.79-22.29-5.12-28.04-14.45.98.03 1.97.04 2.97.05 8.15.08 16.44-.35 23.35-.82 7.32-.5 16.17-1.23 24.68-2.55.99-.15 1.97-.31 2.94-.48-4.41 10.09-14.15 17.45-25.91 18.24zm43.45-35.24c.16 2.41-2.8 4.29-7.46 5.79-2.22.71-4.84 1.33-7.68 1.88-9.28 1.78-21.05 2.75-29.94 3.35-8.89.6-20.68 1.23-30.12.71-2.89-.16-5.57-.42-7.86-.83-4.81-.85-8.01-2.32-8.17-4.73-.17-2.47 2.97-4.28 7.86-5.63-.14 1.25-.22 2.51-.23 3.79 1.93.69 4.57 1.14 7.61 1.43-.53-16.35 11.99-30.3 28.44-31.41s30.73 11.03 32.4 27.3c2.98-.69 5.53-1.49 7.35-2.44-.19-1.27-.44-2.51-.74-3.73 5.03.68 8.38 2.05 8.55 4.52z" />
			<path
				d="m66.33 33.18c-.03-.13-.05-.27-.09-.4-.01-.04-.03-.08-.04-.11-.04-.13-.08-.26-.13-.39-.01-.03-.03-.05-.04-.08-.06-.14-.12-.27-.19-.4 0-.02-.02-.03-.03-.05-.08-.14-.16-.27-.24-.41 0 0-.01-.01-.02-.02-.09-.14-.19-.27-.3-.4-.22-.27-.47-.51-.74-.73-.25-.2-.52-.38-.8-.54-.14-.07-.28-.15-.43-.21-.01 0-.03-.01-.04-.02-.14-.06-.27-.11-.41-.16-.03-.01-.06-.02-.1-.03-.13-.04-.25-.08-.38-.11-.05-.01-.11-.02-.16-.03-.11-.02-.22-.04-.33-.06-.09-.01-.18-.02-.27-.02-.08 0-.16-.02-.25-.02-.18 0-.35 0-.53 0-.18.01-.36.03-.53.06-.08.01-.17.03-.25.05-.09.02-.17.03-.26.05-.11.03-.22.06-.32.1-.05.02-.11.03-.16.05-.13.04-.25.1-.37.15-.03.01-.06.02-.09.04-.13.06-.27.13-.39.21-.01 0-.03.01-.04.02-.14.08-.27.17-.4.26-.26.19-.51.4-.73.63-.24.25-.46.52-.65.82-.09.14-.17.28-.25.43 0 0 0 .02-.01.03-.07.14-.13.28-.19.43 0 .02-.02.04-.02.06-.05.14-.1.28-.14.42 0 .03-.02.06-.03.09-.03.13-.06.27-.09.4 0 .04-.02.08-.02.12-.02.13-.03.27-.04.4 0 .04-.01.09-.01.13v.54c.17 2.86 2.69 5.03 5.62 4.86s5.17-2.63 5-5.49c-.01-.18-.03-.36-.06-.54 0-.04-.02-.08-.03-.13z" />
			<path
				d="m80.25 32.42c-.03-.13-.05-.27-.09-.4-.01-.04-.02-.07-.04-.11-.04-.13-.08-.26-.13-.39 0-.02-.02-.05-.03-.07-.06-.14-.12-.27-.19-.41 0-.01-.02-.03-.02-.04-.08-.14-.16-.28-.24-.41 0 0 0 0 0-.01-.29-.43-.63-.81-1.03-1.14s0 0 0 0c-.38-.31-.8-.57-1.25-.77 0 0-.02 0-.03-.01-.14-.06-.29-.12-.44-.17-.02 0-.05-.01-.08-.02-.13-.04-.27-.08-.41-.11-.05-.01-.1-.02-.15-.03-.11-.02-.23-.05-.35-.06-.09-.01-.18-.02-.26-.02-.09 0-.17-.02-.26-.02-.18 0-.35 0-.54 0-.18.01-.35.03-.52.06-.08.01-.15.03-.23.05-.09.02-.18.03-.27.06-.1.03-.2.06-.3.09-.06.02-.12.04-.18.06-.11.04-.22.09-.33.13-.04.02-.08.03-.12.05-.12.06-.24.12-.36.18-.02.01-.04.02-.06.03-.13.07-.25.15-.37.23 0 0-.02.01-.03.02-.13.09-.25.18-.36.28-.12.1-.24.21-.35.32-.13.13-.24.26-.35.39-.11.13-.21.27-.3.41 0 0 0 .01-.01.02-.09.14-.17.28-.25.42 0 .02-.02.03-.03.05-.07.14-.13.27-.19.42-.01.03-.02.05-.03.08-.05.13-.09.27-.13.4-.01.04-.02.07-.03.11-.03.13-.06.26-.09.39 0 .05-.02.09-.03.14-.02.13-.03.26-.04.4 0 .05-.01.09-.01.14v.55c.17 2.86 2.69 5.03 5.62 4.86s5.17-2.63 5-5.49c-.01-.18-.03-.36-.06-.54 0-.04-.02-.08-.03-.12z" />
		</g>
	</svg>
	Mit Eduplaces anmelden
</button>`;

	const html = `<div class="login-modal">
		<form>
			<span class="errors"></span>
			<label>
				<span>${t().userName}
					<details class="hint">
						<summary title="Info">i</summary>
						<div style="text-align:left;">${loginInfoUsername}</div>
					</details>
				</span>
				<input tabindex="1" type="text" name="username" value="" placeholder="${t().userName}"/>
			</label>
			<label>
				<span>${t().password}
					<details class="hint">
						<summary title="Info">i</summary>
						<div style="text-align:left;">${loginInfoPassword}</div>
					</details>
				</span>
				<input tabindex="2" type="password" name="password" value="" placeholder="${t().password}"/>
			</label>
			<input type="submit" value="${t().login}"/>
			${ showVidis ? `<a class="button vidis-button" href="/api/auth/login-ms365" type="submit">${t().loginVidisLabel}</a>` : "" }
			${ loginVidisHint }
			${ showEduplaces ? eduplacesButton : "" }
			${ loginEduplacesHint }
			${ configuration.enableMS365Login ? `<a class="button" href="/api/auth/login-ms365" type="submit">${t().login365Label}</a>` : "" }
			${ login365Hint}
			<br/>
			<a id="register-link" class="ugm-register-link">${registerText}</a><br />
			<a class="ugm-register-link" href="${resetLink}">${t().loginInfoPasswordLink}</a>
		</form>
	</div>`;
	const modal = showModal(html, "centered-box", userCantClose);

	for(const button of modal.querySelectorAll<HTMLElement>(".edu-login-btn")){
		button.addEventListener("click", () => {
			window.location.href = '/api/auth/login-oidc';
		});
	}
	const errorNode = modal.querySelector(".errors");
	const form = <HTMLFormElement | null>modal.querySelector("form");
	if(!form || !errorNode){return;}
	const usernameElement = <HTMLInputElement | null>form.querySelector('input[name="username"]');
	const passwordElement = <HTMLInputElement | null>form.querySelector('input[name="password"]');
	if(!usernameElement || !passwordElement){return;}
	usernameElement.focus();
	const onSubmit = async (e:Event) => {
		e.preventDefault();
		const username = usernameElement.value;
		const password = passwordElement.value;
		try {
			const loginStatus = await ugmLogin(username, password);
			if(loginStatus){
				errorNode.innerHTML = '';
				forceHideOverlay();
			}else{
				errorNode.innerHTML = `${t().loginError}<br/><br/>`;
			}
		}catch(e) {
			errorNode.innerHTML = `${t().loginError}<br/><br/>`;
		}
	};
	usernameElement.addEventListener("keypress", onEnterDo((e:Event) => {
		e.preventDefault();
		passwordElement.focus();
	}));
	passwordElement.addEventListener("keypress", onEnterDo(onSubmit));

	form.addEventListener("submit", onSubmit);

	const registerButton = modal.querySelector<HTMLAnchorElement>('#register-link');
	if (configuration.target === "netbook") {
		registerButton?.addEventListener("click", (event) => {
			event.preventDefault();
			window.open('https://netbook-deutsch.de/inhalt/netbook-bestellen', '_blank');
		});
	} else {
		registerButton?.addEventListener("click", openRegisterModal);
	}
};

export const initUGMLoginButton = () => {
	const loginButton = document.querySelectorAll<HTMLElement>("login-button");
	if(!loginButton){return;}
	loginButton.forEach(btn =>{
		btn.addEventListener("click", e => {
			e.stopPropagation();
			showLoginModal();
		});
	});
};
setTimeout(initUGMLoginButton,0);

if(configuration.ugmEndpoint){
	setTimeout(async () => {
		if(isLoggedIn()){
			return;
		}
		const urlParams = new URLSearchParams(window.location.search);
		const loginModal = urlParams.get("login-modal");
		if (loginModal){
			showLoginModal();
		}
	},100);
}
