import { findIframe, getFirstParentElement } from "../../_helper";
import { isDevMode } from "../../dev-tools";

export interface XAPIEvent {
	verb: string;
	taskId: string;
	learningGoal: string;

	rawdata:any;
}

export const getPageLearningGoal = ():string => {
	return document.querySelector("main")?.getAttribute("learning-goal") || "";
};

const transformImageMultipleHotspotTries = new Map();
const transformImageMultipleHotspot = (taskId: string, e: any) => {
	const max = e?.rawdata?.data?.statement?.result?.score?.max;
	const score = e?.rawdata?.data?.statement?.result?.score?.raw;
	if(!max || !score){
		return false;
	}
	if(score < max){
		const newTries = 1 + (transformImageMultipleHotspotTries.get(taskId) || 0);
		transformImageMultipleHotspotTries.set(taskId, newTries);
		return false;
	}

	const tries = (transformImageMultipleHotspotTries.get(taskId) || 0);
	e.rawdata.data.statement.result.score.max = max + tries;
	e.rawdata.data.statement.result.score.raw = score;
	e.rawdata.data.statement.result.score.scaled = score / ((max + tries) || 1);
	if(isDevMode()){
		console.log(e);
	}

	return e;
};

export const transformXAPI = (taskId: string, e: any) => {
	const cat = e?.rawdata?.data?.statement?.context?.contextActivities?.category;
	if(Array.isArray(cat) && String(cat[0]?.id || '').includes("http://h5p.org/libraries/H5P.ImageMultipleHotspotQuestion")){
		return transformImageMultipleHotspot(taskId, e);
	}

	return e;
};

const initXApiDispatcher = () => {
	const pageLearningGoal = getPageLearningGoal();

	const parseXApi = (rawdata:any, iframe:HTMLIFrameElement):XAPIEvent => {
		const trainingTasks = getFirstParentElement(iframe, "TRAINING-TASKS");
		const learningGoal = trainingTasks?.getAttribute("task-cat") || pageLearningGoal;
		const rawVerb = rawdata?.data?.statement?.verb?.id || 'unknown';
		const verb = rawVerb.split("/").pop();
		const taskId = rawdata?.data?.statement?.object?.id || '';
		return {
			verb,
			taskId,
			learningGoal,
			rawdata
		};
	};

	window.addEventListener("message", event => {
		if (typeof event.data  === "string") {
			try {
				const rawdata = JSON.parse(event.data);
				if ((rawdata.type !== 'xAPI') && (!rawdata.T)){
					return;
				}
				const iframe:HTMLIFrameElement | null = findIframe(event.source);
				if(!iframe){ return; }

				const detail = parseXApi(rawdata, iframe);
				const data = transformXAPI(detail.taskId, detail);
				if(isDevMode()){
					console.log(data);
				}
				if(!data){
					return;
				}

				const e = new CustomEvent("xAPI", {bubbles: true, cancelable: true, detail});
				iframe.dispatchEvent(e);
			} catch (error) {
				if(isDevMode()){
					console.log(error);
				}
			}
		}
	}, true);
};
setTimeout(initXApiDispatcher, 0);
