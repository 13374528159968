import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import mailIconWhite from "../../svg/netbook-mail.svg";
import phoneIconWhite from "../../svg/netbook-phone.svg";
import mailIconBlue from "../../svg/hamburg-mail.svg";
import phoneIconBlue from "../../svg/hamburg-phone.svg";

@customElement('netbook-contact')
export class NetbookContact extends LitElement {
	static styles = css`
		div {
			font-family: "Livvic", sans-serif;
			color: #2AB0D7;
		}

		div.white {
			color: white;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		header > div {
			flex-shrink: 0;
			flex-basis: 300px;
		}

		.text {
			font-family: "Livvic", sans-serif;
			font-size: 23px;
			font-weight: bolder;
		}

		.icon-text {
			font-family: "Montserrat", sans-serif;
			font-size: 20px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 12px;
		}

		.icon-text > span {
			height: 32px !important;
		}

		p {
			margin-top: 0;
			margin-bottom: 8px;
		}
		`;

	@property()
		color: 'blue' | 'white' = 'white';

	render() {
		const phoneIcon = (this.color === 'blue')
			? phoneIconBlue
			: phoneIconWhite;
		const mailIcon = (this.color === 'blue')
			? mailIconBlue
			: mailIconWhite;
		return html`
			<div class="${this.color === 'blue' ? 'blue' : 'white'}">
				<p class="text">Fragen? Hinweise?</p>
				<p class="text">Gesprächsbedarf?</p>
				<p class="icon-text"><span><img src="${mailIcon}" /></span><a href="mailto:info@dilewe.de" target="_blank">info@dilewe.de</a></p>
				<p class="icon-text"><span><img src="${phoneIcon}" /></span><a href="tel:+4984219862148" target="_blank">08421 / 9862148</a></p>
			</div>
		`;
	}
}
