import {customElement} from "lit/decorators.js";
import {css, html, LitElement} from "lit";

import mailIcon from "../../svg/netbook-mail.svg";
import phoneIcon from "../../svg/netbook-phone.svg";
import netbookLogo from "../../svg/netbook-deutsch-logo.svg";

@customElement("netbook-shop-header")
export class NetbookShopHeader extends LitElement {
	static styles = css`
		header {
			display: flex;
			justify-content: space-between;
			align-content: center;
			margin-inline: 3rem;
			padding-top: 16px;
			padding-bottom: 16px;
			font-family: "Livvic", sans-serif;
			color: white;
		}

		header > div {
			flex-shrink: 0;
			flex-basis: 300px;
		}

		p {
			margin-top: 0;
			margin-bottom: 8px;
		}

		.header-text {
			font-family: "Livvic", sans-serif;
			font-size: 23px;
			font-weight: bolder;
		}

		.header-icon-text {
			font-family: "Montserrat", sans-serif;
			font-size: 20px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 12px;
		}

		.header-icon-text > span {
			height: 32px !important;
		}

		.netbook-logo {
			width: 300px;
		}

		@media screen and (max-width: 900px) {
			header {
				display: flex;
				justify-content: space-between;
				margin-inline: 0.25rem;
				border-bottom: 1px solid black;
			}

			.netbook-logo {
				width: 128px;
			}

			header > div {
				flex-shrink: 0;
				flex-basis: 200px;
			}

			header > div:first-child {
				display: none;
			}

			span {
				font-size: 26px;
			}
		}

		@media screen and (max-width: 500px) {
			.header-text {
				font-size: 16px;
			}

			.header-icon-text {
				font-size: 16px;
			}

			.hamburg-logo {
				width: 64px;
			}
		}
	`;

	render() {
		return html`
			<header>
				<div></div>
				<div class="netbook-logo"><img src="${netbookLogo}"/></div>
				<div>
					<p class="header-text">Fragen? Hinweise?</p>
					<p class="header-text">Gesprächsbedarf?</p>
					<p class="header-icon-text"><span><img src="${mailIcon}" /></span>info@dilewe.de</p>
					<p class="header-icon-text"><span><img src="${phoneIcon}" /></span>08421 / 9862148</p>
				</div>
			</header>
		`;
	}
}
