import { IKvStoreEntry, apiKVGet, apiKVSet } from "@digitale-lernwelten/ugm-client-lib";
import { DataStore } from "./datastore";
import { isLoggedIn } from "./user-data";
import configuration from "../../configuration";

const KvMap: Map<string, any> = new Map();
const KvWatchers: Map<string, (value: any) => void> = new Map();

const clearAll = () => {
	for(const cb of KvWatchers.values()){
		cb(null);
	}
	KvMap.clear();
};

const doPoll = async (after?: Date) => {
	if(configuration.enableUGMKeyValueStore && isLoggedIn()){
		return await apiKVGet(after);
	} else {
		return [];
	}
};

const userKVAddOrUpdate = (e: IKvStoreEntry) => {
	if(e.deletedAt){
		KvMap.delete(e.key);
	} else {
		set(e.key, JSON.parse(e.value));
	}
};

const store: DataStore<IKvStoreEntry> = new DataStore("ugm-kv-store", userKVAddOrUpdate, clearAll, doPoll);

const set = (key: string, value: any):boolean => {
	const oldValue = KvMap.get(key);
	if(oldValue === value){return false;}

	KvMap.set(key, value);
	const cb = KvWatchers.get(key);
	cb && cb(value);
	return true;
};


export const loadAllKVEntries = () => store.load();

/* Register a Callback that get's called whenever a particular key get's set */
export const KvWatch = (key: string, cb: (value: any) => void) => KvWatchers.set(key, cb);

export const KvGet = (key: string): any => KvMap.get(key);
export const KvGetAll = (): any => KvMap.entries();

export const KvSet = async (key: string, value: any) => {
	const v = JSON.stringify(value);
	if(set(key, JSON.parse(v))){
		if(configuration.enableUGMKeyValueStore){
			await apiKVSet(key, v);
			await store.poll();
			await store.save();
		}
	}
};
