import type { TranslationMap } from "./";

const en:TranslationMap = {
	mvet: {
		myMvet: "<b>My</b> mVet",
		goToShop: "Go to Shop",
		goToLibrary: "Go to Library",
		goToAccount: "Go to Account",
		yourOrders: "Your Orders"
	},
	home: "Home",
	index: "Index",
	announcements: "Announcements",
	delete: "Delete",
	save: "Save",
	download: "Download",
	editTask: "edit Task",
	title: "Title",
	addNote: "add Note",
	files: "Files",
	perFile: "per file",
	supportedFiles: "Unterstützte Dateiformate",
	login: "Login",
	logout: "Logout",
	starlevel: "On which level do you want to work in this chapter?",
	lastVisitedPages: "Last visited pages:",
	author: "author",
	upload: "Datei upload",
	uploadError: "Unfortunately, the selected file could not be saved, maybe it is too large.",
	uploadTooBig: "Unfortunately these files do not fit into the local memory, please try again with smaller or fewer files.",
	notesPublish: "Publish for the class",
	notesLogin: "Please log in to write notes.",
	notesAdd: "Create new marker",
	notesRemove: "Remove marking",
	notesClose: "Close marking menu",

	coursePadHeader: "Group work",
	coursePadNew: "New group",
	coursePadError: "Please use a name that is between 4 and 64 characters long.",

	saveCorrection: "Save",
	submitCorrection: "Submit for correction",
	correctionSubmitted: "correction submitted",
	correctionSubmittedModal: "The task was submitted for correction.",
	editLocked: "Further processing is therefore not possible at present.",
	//optout
	allowExternalEmbeds: "Permission to load external content",
	darkMode: "darkmode",
	page_view_history: "last saved Sites",
	LANG: "language Settings",
	local_only_ugm_notes: "local notes",
	user_star_level: "global difficulty settings",
	star_data: "stored differentiation",
	local_only_ugm_correction: "locally stored inputs of your exercises",
	//dynamic Pages
	glossary: "Glossary List (Collection)",
	//Contenttypes
	//embeds / privacy
	onlyAllowEmbedsForLoggedInUsers: "To use this interactive content, please login first.",
	externalEmbed: "External content, click here to continue",
	externalEmbedModal:`<p>At this point you will find external content. If you enable such content, personal data may be transmitted to third-party platforms.</p><p>More about this in our <a href="./datenschutz/" target="_blank">data protection declaration</a>.</p>`,
	accept: "Accept",
	cancel: "Cancel",
	privacyContainer: "Here you can find your currently saved settings",
	//ugm login fields
	activationAccess: "Activate your access for",
	activationSubmit: "Let's go!",
	activationSuccessHeader: "Your identifier",
	activationSuccessText: "Please write down your password and remember it well! If you forget your password, your teacher can reset it for you.",
	activationFailedHeader: "Error during user activation",
	activationFailedText:"Unfortunately, an error occurred during user activation, please check the link and try again later.",
	activationError: "Activation failed, please check your entry and try again.",

	registerText: "Don't have access for your class yet? Register here!",
	registerTextNetbook: "Don't have access yet?? Buy the netBook here!",
	registerTextEnforced: "Don't have an account yet? Register here!",
	registerTextGeo: "All other regions: You do not yet have access for your class(es)? Please register here!",

	userName: "Username",
	password: "Password",
	passwordInfo: "The password requires a minimum length of 8 characters.",
	passwordRepeat: "Repeat password",
	passwordEnter: "Enter password",
	passwordReset: "Reset password for",
	passwordError: "The password is too short",
	passwordDiffers:"Die Passwörter stimmt nicht überein",

	loginError: "Login failed, please check your entry and try again.",
	loginInfoUsername: "Als Lehrperson melden Sie sich mit Ihrer E-Mail-Adresse an.<br /> Als Schülerin oder Schüler melden Sie sich mit Ihrer Kürzel im Format \"xxxx-farbetier\" an.",
	loginInfoUsername365: "<p>Ostbelgien: Nutzen Sie bitte den Button “Weiter mit Office 365” unten.</p><p>Alle übrigen Regionen der Euregio Maas-Rhein: </p><ul><li><p>Als Lehrperson melden Sie sich mit Ihrer Dienst-E-Mail  an. </p></li><li><p>Schülerinnen oder Schüler nutzen Ihr Kürzel im Format “xxxx-farbetier”.</p></li></ul><p></p>",
	loginInfoPassword: `If you as a student have forgotten your password, please contact the responsible teacher. They can reset your password and inform you of your temporary password</br></br>If you have forgotten your password as a teacher,,`,
	loginInfoPasswordLink: "Reset password",
	loginInfoPassword365: (link:string) => `<p>Für Ostbelgien: Setzen Sie Ihr Passwort bitte ggf. über MS Office 365 zurück.&nbsp;</p>
	<p>Alle übrigen Regionen der Euregio Maas-Rhein:&nbsp;</p>
	<ul>
		<li>Wenn Sie als Lehrperson Ihr Passwort vergessen haben, ${link}.</li>
		<li>Wenn Sie als Schülerin oder Schüler Ihr Passwort vergessen haben, wenden Sie sich bitte an Ihre Lehrperson. Diese kann Ihr Passwort zurücksetzen und Ihnen ein temporäres Passwort mitteilen.</li>
	</ul>`,
	login365Label: "Sign Up with Office 365",
	login365Hint: "Hinweis: in Ostbelgien ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit Office 365\".",

	loginVidisLabel: "Weiter mit",
	loginVidisHint: "Hinweis: mit VIDIS ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit VIDIS\".",

	loginEduplacesLabel: "Weiter mit",
	loginEduplacesHint: "Hinweis: mit Eduplaces ist keine Registrierung nötig, klicken Sie direkt auf \"Weiter mit Eduplaces\".",

	classList: "Class list",
	classManagement: "Class management",
	correctSubmissions: "Correct submissions",
	sendMessage: "Send a message",
	messages: "News",

	invalidOIDCEmailTitle: "Invalid E-Mail",
	invalidOIDCEmailBody: "Please use an official E-Mail address from your school",
	//privacy policy
	acceptAgb: (link:string) => `I have read and accepted the ${link}`,
	privacyPolicy: "privacy policy",
};
export default en;
