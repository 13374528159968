import de from "./de";
import en from "./en";
import fr from './fr';
import nl from './nl';

export * from './typography';

export type TranslationMap = typeof de;

const maps:Record<string, TranslationMap> = {de, en, fr, nl};

export const hrLang = (l:string) => {
	switch(l.toLowerCase()){
	case "nl": return "Nederlands";
	case "en": return "English";
	case "fr": return "Français";
	case "de": return "Deutsch";
	default: return l;
	}
};

export const getTranslation = (lang:string):TranslationMap => {
	const map = maps[lang];
	if(!map){
		throw new Error(`Can't find translation map for '${lang}'`);
	}
	return map;
};
export default getTranslation;

