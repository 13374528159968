import configuration from "../../configuration";
import { clearCookie, setCookie } from "../_helper";
import "./projectSwitcher.css";

const setProject = (targetName: string) => {
	clearCookie("activeTheme");
	setCookie("targetName", targetName);
	location.reload();
};

export const initProjectSwitcher = () => {
	const button = document.createElement("button");
	button.id = "project-switch-button";
	const header = document.querySelector("#header-left");
	if(!header){
		throw new Error("Can't query header");
	}
	header.append(button);
	let projectPanel:HTMLElement | null = null;

	const toggleProjectSwitcher = () => {
		if(!projectPanel){
			projectPanel = document.createElement("aside");
			projectPanel.id = "project-panel";
			projectPanel.innerHTML = configuration.projects
				.sort((a,b) => a.title.localeCompare(b.title))
				.map(l => {
					const links:string[] = [];
					links.push(`<a href="${l.releaseHref}" class="project-release-link ${l.releaseHref === "" ? "disabled" : ""}" target="_blank" title="Veroeffentliche Version anschauen"></a>`);
					links.push(`<a href="${l.previewHref}" class="project-preview-link ${l.previewHref === "" ? "disabled" : ""}" target="_blank" title="Vorschau anschauen"></a>`);
					links.push(`<a href="${l.editHref}" class="project-edit-link ${l.editHref === "" ? "disabled" : ""}" target="_blank" title="Redaktionssystem anschauen"></a>`);
					return `<project-switch ${l.targetName !== configuration.target ? "" : 'class="active"'} target-project="${l.targetName}" title="${l.targetName}">${l.title}${links.join("")}</project-switch>`;
				})
				.join("");
			document.body.append(projectPanel);

			projectPanel.querySelectorAll<HTMLElement>("project-switch").forEach(btn=>{
				const proj = btn.getAttribute("target-project");
				if(!proj){ return; }
				btn.onclick = (e:Event) => {
					e.preventDefault();
					setProject(proj);
				};
			});
			projectPanel.querySelectorAll<HTMLElement>("project-switch a").forEach(a => {
				a.onclick = (e) => {
					e.stopPropagation();
				};
			});
		}

		projectPanel.classList.toggle("active");
	};
	button.onclick = toggleProjectSwitcher;
};

setTimeout(() => {
	if(configuration.projects.length > 1){
		initProjectSwitcher();
	}
},0);
