import { showModal } from "../../modal/modal";
import { LearningGoal, getLearningGoal } from "./training";
import { getUser, isTeacher } from "../../user-group-management/user-data";
import { StudentScore, getHRLevel, getResultSuccessPercentage, getUserResults } from "../../user-group-management/scoring/scoring";
import { getPageLearningGoal } from "../tab-box/xapi";

import './resultOverview.css';
import { getStudentResults, getWorkingTaskTasks } from "../tab-box";
import { getCourseStudent, getCourseStudents } from "../../user-group-management";
import { isDevMode } from "../../dev-tools/devMode";
import configuration from "../../../configuration";

const renderTaskCompletedHeader = (): string => `<tr><th></th><th>Level</th><th><span class="starIcon"></span></th><th><span class="starIcon"></span><span class="starIcon"></span></th><th><span class="starIcon"></span><span class="starIcon"></span><span class="starIcon"></span></th>`;

const scoreTaskCompletedSingle = (r: StudentScore) => {
	const exerciseResults = r.events.filter(e => e.task);
	const easyResults = exerciseResults.filter(e => e.task?.difficulty === "0");
	const mediumResults = exerciseResults.filter(e => e.task?.difficulty === "1");
	const hardResults = exerciseResults.filter(e => e.task?.difficulty === "2");
	return {
		level: r.level,

		count: exerciseResults.length,
		percent: getResultSuccessPercentage(exerciseResults),

		easyCount: easyResults.length,
		easyPercent: getResultSuccessPercentage(easyResults),

		mediumCount: mediumResults.length,
		mediumPercent: getResultSuccessPercentage(mediumResults),

		hardCount: hardResults.length,
		hardPercent: getResultSuccessPercentage(hardResults)
	};
};

const renderTasksCompletedSingle = (r: StudentScore): string => {
	const score = scoreTaskCompletedSingle(r);
	const easyText = score.easyCount > 0 ? `${score.easyCount}&nbsp;(${Math.round(score.easyPercent)}&nbsp;%)` : "";
	const mediumText = score.mediumCount > 0 ? `${score.mediumCount}&nbsp;(${Math.round(score.mediumPercent)}&nbsp;%)` : "";
	const hardText = score.hardCount > 0 ? `${score.hardCount}&nbsp;(${Math.round(score.hardPercent)}&nbsp;%)` : "";
	const changeIndicator = r.trainingLevel !== r.level
		? r.trainingLevel > r.level
			? `<span class="studentScoreUp"></span>`
			: `<span class="studentScoreDown"></span>`
		: "";
	return `<tr>
		<th>${r.student?.reminder || r.student?.loginName || ""}</th>
		<td>${getHRLevel(String(r.trainingLevel))}&nbsp;${changeIndicator}</td>
		<td class="${mapResultPercentage(score.easyCount, score.easyPercent)}">${easyText}</td>
		<td class="${mapResultPercentage(score.mediumCount, score.mediumPercent)}">${mediumText}</td>
		<td class="${mapResultPercentage(score.hardCount, score.hardPercent)}">${hardText}</td>
	</tr>`;
};

/*
const renderTasksCompletedAverage = (results: Map<string, StudentScore>): string => {
	const res = Array.from(results.values()).map(scoreTaskCompletedSingle);
	const studentCount = res.reduce((acc, r) => acc + Math.min(1,r.count) ,0);
	if(res.length === 0){
		return "";
	}
	const score = res.reduceRight((acc:any, cur:any) => {
		const ret:any = {};
		for(const k in acc){
			ret[k] = acc[k] + cur[k];
		}
		return ret;
	});
	const anyAvg = score as any;
	for(const k in score){
		anyAvg[k] = anyAvg[k] / studentCount;
	}
	const easyText = score.easyCount > 0 ? `${score.easyCount}&nbsp;(${Math.round(score.easyPercent)}%)` : "";
	const mediumText = score.mediumCount > 0 ? `${score.mediumCount}&nbsp;(${Math.round(score.mediumPercent)}%)` : "";
	const hardText = score.hardCount > 0 ? `${score.hardCount}&nbsp;(${Math.round(score.hardPercent)}%)` : "";

	return `<tr>
		<th>Durchschnitt</th>
		<td>${isFinite(score.level) && !isNaN(score.level) ? score.level : ""}</td>
		<td class="${mapResultPercentage(score.easyCount, score.easyPercent)}">${easyText}</td>
		<td class="${mapResultPercentage(score.mediumCount, score.mediumPercent)}">${mediumText}</td>
		<td class="${mapResultPercentage(score.hardCount, score.hardPercent)}">${hardText}</td>
	</tr>`;
};
*/

const renderTasksCompleted = (results: Map<string, StudentScore>): string =>
	`<table class="workingTaskResultOverview">
	<thead>
		${renderTaskCompletedHeader()}
	</thead>
	<tbody>
		${Array.from(results.values()).map(renderTasksCompletedSingle).join("\n")}
	</tbody>
	</table>`;

const renderTasksCompletedStudent = (result: StudentScore): string =>
	`<table>
	<thead>${renderTaskCompletedHeader()}</thead>
	<tbody>${renderTasksCompletedSingle(result)}</tbody>
	</table>`;

const showTeacherResultOverview = async (goal:LearningGoal) => {
	const students = await getCourseStudents();
	const results = new Map<string, StudentScore>();
	for(const student of students){
		results.set(student.id, getUserResults(student, goal));
	}
	if(isDevMode()){
		console.log(results);
	}

	const html = `<h5>Ergebnisse zum Lernziel ${goal.title}</h5>
		${renderTasksCompleted(results)}
	`;
	showModal(html, "wide-box");
};

const showStudentResultOverview = async (goal:LearningGoal) => {
	const html = `<h5>Deine Ergebnisse zum Lernziel ${goal.title}</h5>
		${renderTasksCompletedStudent(getUserResults(getUser(), goal))}
	`;
	showModal(html, "wide-box");
};

export const showTrainingResults = async (cat: string) => {
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}
	if(isTeacher()){
		showTeacherResultOverview(goal);
	} else {
		showStudentResultOverview(goal);
	}
};

export const mapResultPercentage = (tries:number, percent:number):string => {
	if(tries <= 0){
		return "studentResultNil";
	} else if(percent < 50){
		return "studentResultLow";
	} else if(percent < 90){
		return "studentResultMedium";
	} else {
		return "studentResultHigh";
	}
};

export const showTeacherStudentMultipleTaskDetails = async (studentId: string, taskIds: string[], taskNames: string[], backCB: () => void) => {
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}

	const s = await getCourseStudent(studentId);
	if(!s){
		throw new Error("Can't find student in current course");
	}

	let maxTries = 0;
	const rows = taskIds.map((taskId,i) => {
		const raw = getUserResults(s, goal);
		const events = raw.events.filter(e => compareTaskId(e.taskId, taskId));
		maxTries = Math.max(events.length, maxTries);
		return {
			name: taskNames[i],
			percentages: events.map(e => getResultSuccessPercentage([e]))
		};
	});

	const attempts:number[] = [];
	for(let i=0;i<maxTries;i++){
		attempts.push(i);
	}

	const rowsHTML = rows.map(s =>
		`<tr><th>${s.name}</th>`
		+ attempts.map(i => {
			if(i >= s.percentages.length){
				return `<td class="task-out-of-bounds"></td>`;
			}
			const p = s.percentages[i];
			return `<td class="${mapResultPercentage(1, p)}">${Math.round(p)}&nbsp;%</td>`;
		}).join("") + "</tr>")
		.join("");

	const html = `<h5>Ergebnisse von ${s.reminder || s.loginName}</h5>
		<table class="workingTaskResultOverview">
			<thead><tr>
				<th>Aufgabe</th>
				${attempts.map(i => `<th>${i+1}. Versuch</th>`).join("")}
			</tr></thead>
			<tbody>
				${ rowsHTML }
			</tbody>
		</table>
		<button class="back-button">Zur&uuml;ck zur &Uuml;bersicht</button>
	`;
	const modal = showModal(html, "wide-box");
	modal.querySelectorAll(".back-button").forEach(b => b.addEventListener("click", backCB));
};

export const showTeacherStudentTaskDetails = async (studentIds: string[], taskId: string, taskName: string, backCB: () => void) => {
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}

	const students = await getCourseStudents();
	let maxTries = 0;
	const rows = studentIds.map(studentId => {
		const s = students.find(s => s.id === studentId);
		if(!s){
			throw new Error("Can't find student in current course");
		}

		const raw = getUserResults(s, goal);
		const events = raw.events.filter(e => compareTaskId(e.taskId, taskId));
		maxTries = Math.max(events.length, maxTries);
		return {
			name: s.reminder || s.loginName,
			percentages: events.map(e => getResultSuccessPercentage([e]))
		};
	});

	const attempts:number[] = [];
	for(let i=0;i<maxTries;i++){
		attempts.push(i);
	}

	const rowsHTML = rows.map(s =>
		`<tr><th>${s.name}</th>`
		+ attempts.map(i => {
			if(i >= s.percentages.length){
				return `<td class="task-out-of-bounds"></td>`;
			}
			const p = s.percentages[i];
			return `<td class="${mapResultPercentage(1, p)}">${Math.round(p)}&nbsp;%</td>`;
		}).join("") + "</tr>")
		.join("");

	const html = `<h5>Ergebnisse f&uuml;r ${taskName}</h5>
		<table class="workingTaskResultOverview">
			<thead><tr>
				<th></th>
				${attempts.map(i => `<th>${i+1}. Versuch</th>`).join("")}
			</tr></thead>
			<tbody>
				${ rowsHTML }
			</tbody>
		</table>
		<button class="back-button">Zur&uuml;ck zur &Uuml;bersicht</button>
	`;
	const modal = showModal(html, "wide-box");
	modal.querySelectorAll(".back-button").forEach(b => b.addEventListener("click", backCB));
};

const fixBadSearchParams = (url: string) => {
	const qPos = url.indexOf("?");
	const nextPos = url.indexOf("?", qPos + 1);
	if((qPos > 0) && (nextPos > 0) && (nextPos > qPos)){
		return url.substring(0,nextPos);
	}
	return url;
};

export const normalizeTaskId = (taskId: string) => {
	taskId = fixBadSearchParams(taskId);
	try {
		const taskUrl = new URL(taskId);
		return `${taskUrl.hostname}/${taskUrl.pathname}?id=${taskUrl.searchParams.get('id') || "unknown"}`;
	} catch {
		/* Doesn't matter */
	}
	return taskId;
};

export const compareTaskId = (eventTaskId: string, taskId: string) => {
	eventTaskId = fixBadSearchParams(eventTaskId);
	taskId = fixBadSearchParams(taskId);
	if(eventTaskId === taskId){
		return true;
	}
	try {
		const eventUrl = new URL(eventTaskId);
		const taskUrl = new URL(taskId);

		if(!eventUrl || !taskUrl){
			return false;
		}
		if(eventUrl.hostname !== taskUrl.hostname){
			return false;
		}
		if(eventUrl.pathname !== taskUrl.pathname){
			return false;
		}
		if(eventUrl.searchParams.get('id') === taskUrl.searchParams.get('id')){
			return true;
		}
	} catch {
		/* Doesn't matter */
	}
	return false;
};

export const showTeacherWorkingTaskOverview = async (wrap: HTMLElement) => {
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}
	const taskData = getWorkingTaskTasks(wrap);
	const students = await getCourseStudents();
	const results = students.map(s => getStudentResults(s, goal, taskData.tasks));

	const taskSuffix = configuration.target === "netbook" ? ". Schritt" : ". Teil";
	const thead = "<th></th>" + taskData.tasks.map((t,i) => `<th task-id="${t.taskId}" task-name="A${i+1}">${i+1}${taskSuffix}</th>`).join(""); // + "<th>Gesamt</th>";
	const tbody = results.map(s => {
		let ret = '<tr>';
		ret += `<th student-id="${s.id}">${s.name}</th>`;
		ret += s.res.map((e,i) => {
			if(!e){
				return "<td></td>";
			}
			switch(e.type){
			default:
			case "embedding": {
				if(e.tries <= 0){
					return `<td student-id="${s.id}" task-id="${e.taskId}" task-name="A${i+1}" class="${mapResultPercentage(e.tries, e.taskScore)}"></td>`;
				}
				//return `<td student-id="${s.id}" task-id="${e.taskId}" task-name="A${i+1}" class="${mapResultPercentage(e.tries, e.percent)}">${Math.round(e.percent)}% ${e.tries}&nbsp;Versuch${e.tries > 1 ? "e" : ""}</td>`;
				const title = `${Math.round(e.percent)}% ${e.tries} Versuch${e.tries > 1 ? "e" : ""}`;
				return `<td student-id="${s.id}" task-id="${e.taskId}" task-name="A${i+1}" task-score="${e.taskScore}" class="${mapResultPercentage(e.tries, e.taskScore)}" title="${title}"></td>`;
			}
			case "exercise":
				return `<td class="task-exercise">Text</td>`;
			case "info":
				return `<td class="task-info">Info</td>`;
			}
		}).join("");
		//ret += s.totalTries > 0 ? `<td class="${mapResultPercentage(s.totalTries, s.totalPercent)}">${Math.round(s.totalPercent)}%</td>` : "<td></td>";
		ret += '</tr>';
		return ret;
	}).join("");

	const html = `<h5>Ergebnisse der Erarbeitungsaufgaben f&uuml;r die einzelnen Schritte</h5>
		<table class="workingTaskResultOverview">
			<thead><tr>
				${thead}
			</tr></thead>
			<tbody>
				${tbody}
			</tbody>
		</table>
	`;
	const modal = showModal(html, "wide-box");
	const backCB = () => showTeacherWorkingTaskOverview(wrap);
	modal.querySelectorAll(".workingTaskResultOverview [student-id],.workingTaskResultOverview [task-id]").forEach(ele => {
		ele.addEventListener("click", () => {
			const studentId = ele.getAttribute("student-id");
			const taskId = ele.getAttribute("task-id");
			const taskName = ele.getAttribute("task-name");
			if(taskId && studentId && taskName){
				showTeacherStudentTaskDetails([studentId], taskId, taskName, backCB);
			} else if(taskId && taskName) {
				showTeacherStudentTaskDetails(students.map(s => s.id), taskId, taskName, backCB);
			} else if(studentId) {
				showTeacherStudentMultipleTaskDetails(studentId, taskData.tasks.map(v => v.taskId), taskData.tasks.map((_,i) => `${i+1}`), backCB);
			}
		});
	});
};

export const showStudentWorkingTaskOverview = async (wrap: HTMLElement) => {
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}
	const taskData = getWorkingTaskTasks(wrap);
	const students = await getCourseStudents();
	const results = students.map(s => {
		const raw = getUserResults(s, goal);
		const res = taskData.tasks.map(task => {
			const t = task.taskId;
			const events = raw.events.filter(e => compareTaskId(e.taskId, t));
			if(events.length <= 0){
				return null;
			}
			const event = events[events.length-1];
			const percent = getResultSuccessPercentage([event]);
			return {
				percent,
				tries: events.length,
				taskId: t
			};
		});
		const resLen = res.reduce((a,b) => a + (b ? 1 : 0),0);
		const totalPercent = res.reduce((a,b) => a + (b?.percent || 0), 0) / resLen;
		const totalTries = res.reduce((a,b) => a + (b?.tries || 0), 0);
		const finished = res.every(c => c?.tries);
		return {
			name: s.reminder || s.loginName,
			id: s.id,
			finished,
			res,
			totalPercent,
			totalTries
		};
	}).sort((a,b) => a.name.localeCompare(b.name));

	const thead = "<th></th>" + taskData.tasks.map((v,i) => `<th>A${i+1}</th>`).join("") + "<th>Gesamt</th>";
	const tbody = results.map(s => {
		let ret = '<tr>';
		ret += `<th>${s.name}</th>`;
		ret += s.res.map((e,i) => {
			const t = taskData.tasks[i];
			switch(t.type){
			default:
			case "embedding":
				if(e){
					if(e.tries <= 0){
						return `<td class="${mapResultPercentage(e.tries, e.percent)}"></td>`;
					} else {
						return `<td class="${mapResultPercentage(e.tries, e.percent)}">${Math.round(e.percent)}&nbsp;% ${e.tries}&nbsp;Versuch${e.tries > 1 ? "e" : ""}</td>`;
					}
				} else {
					return `<td class="studentResultNil"></td>`;
				}
			case "exercise":
				return `<td class="task-exercise">Text</td>`;
			case "info":
				return `<td class="task-info">Info</td>`;
			}
		}).join("");

		ret += s.totalTries > 0 ? `<td class="${mapResultPercentage(s.totalTries, s.totalPercent)}">${Math.round(s.totalPercent)}&nbsp;%</td>` : "<td></td>";
		ret += '</tr>';
		return ret;
	}).join("");

	const html = `<h5>Ergebnisse der Erarbeitungsaufgaben</h5>
		<table class="workingTaskResultOverview">
			<thead><tr>
				${thead}
			</tr></thead>
			<tbody>
				${tbody}
			</tbody>
		</table>
	`;
	showModal(html, "wide-box");
};

export const showWorkingTaskResults = async (wrap: HTMLElement) => {
	if(isTeacher()){
		await showTeacherWorkingTaskOverview(wrap);
	} else {
		await showStudentWorkingTaskOverview(wrap);
	}
};
