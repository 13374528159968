import { showModal } from "../../modal/modal";
import { hideOverlay } from "../../overlay/overlay";
import { initRTE } from "../../rte";
import { INote, apiAddNote, apiDeleteNote, apiUpdateNote, apiGetStudentNotes, apiGetCourseNotes, IUploadedFile, apiAddFileToNote, apiDeleteFileFromNote, SUPPORTED_FILE_EXTENSIONS, SUPPORTED_MIME_TYPES } from "@digitale-lernwelten/ugm-client-lib";
import { getCourse, isLoggedIn, isStudent, isTeacher } from '../user-data';
import configuration from '../../../configuration';
import { DataStore } from "../datastore";
import { lightboxShowHref } from "../../content_types/gallery/gallery";
import { t } from "../../i18n";

import "./notes.css";

const getNoteData = (exerciseId:string) => store.find((t:INote) => t.exerciseId === exerciseId);
const deleteNote = async (id:string) => {
	const note = store.get(id);
	if(!note || !note.id){
		return;
	}
	store.delete(note.id);
	if(configuration.ugmEndpoint) {
		if(!note.deletedAt){
			await apiDeleteNote(note.id);
		}
	}
};

const deleteNoteFile = async(noteId:string, fileId:string, courseId?:string) => {
	if(configuration.ugmEndpoint) {
		const r = await apiDeleteFileFromNote(noteId, fileId, courseId);
		addOrUpdateNote(r);
	} else {
		const note = store.get(noteId);
		if(!note) { return; }
		note.files = note.files.filter(file => file.id !== fileId);
		addOrUpdateNote(note);
	}
};

const base64EncodeFile = (file:File):Promise<string> => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.onerror = reject;
	reader.onload = () => {
		resolve((reader.result || "").toString());
	};
	reader.readAsDataURL(file);
});

const addNoteFile = async (file:File, noteId:string, courseId?:string) => {
	if(configuration.ugmEndpoint) {
		const formData = new FormData();
		formData.append("file", file, file.name);
		const r = await apiAddFileToNote(formData, noteId, courseId);
		addOrUpdateNote(r);
	} else {
		const note = store.get(noteId);
		if(!note) { return; }
		const ext = file.name.split(".").pop() || "";
		const attachment:IUploadedFile = {
			id: noteId + "-" + new Date().toISOString() + "-" + note.files.length,
			createdAt: new Date(),
			updatedAt: new Date(),
			hash: "",
			ext,
			mimeType: file.type,
			originalFileName: file.name,
			url: await base64EncodeFile(file),
			thumbnailUrl: "",
			bucket: "",
			fileName: file.name,
			thumbnailFileName: file.name
		};
		note.files.push(attachment);
		addOrUpdateNote(note);
	}
};

const saveNote = async (note:INote) => {
	const formData = new FormData();
	formData.append("exerciseId", note.exerciseId);
	formData.append("body", note.body);
	formData.append("subject", note.subject);
	if(isTeacher()){
		formData.append("isPublished", note.isPublished ? "true" : "false");
	}

	const courseId = (isTeacher() && getCourse()?.id) || undefined;
	if(configuration.ugmEndpoint) {
		const newNote:INote = note?.id
			? await apiUpdateNote(formData, note.id, courseId)
			: await apiAddNote(formData, courseId);
		if((!newNote.isPublished) && (note.isPublished)){
			newNote.isPublished = note.isPublished;
		}
		store.set(newNote);
	} else {
		try {
			store.set(note);
		} catch(e) {
			showModal(t().uploadTooBig);
		}
	}
};

const getNoteGallery = (note: INote, fileId:string):[string[], number] => {
	const tmp = note.files.filter(f => f.url && f.mimeType.startsWith("image/"));
	const startIndex = tmp.findIndex(f => f.id === fileId);
	const hrefs = tmp.map(f => f.url || "");
	return [
		hrefs,
		startIndex
	];
};

export const createAttachmentNode = (note: INote, file: IUploadedFile, add_delete_link:boolean):HTMLElement => {
	const wrap = document.createElement("div");
	wrap.classList.add("note-attachment");
	wrap.setAttribute("file-mime", file.mimeType);
	wrap.setAttribute("file-mime-type", file.mimeType.split("/")[0]);

	if(add_delete_link){
		const deleteAttachment = document.createElement("div");
		deleteAttachment.classList.add("attachment-delete");
		deleteAttachment.onclick = async (e) => {
			e.preventDefault();
			e.stopPropagation();
			const courseId = getCourse()?.id;
			await deleteNoteFile(note.id, file.id, courseId);
			wrap.remove();
		};
		wrap.append(deleteAttachment);
	}

	const link = document.createElement("a");
	link.setAttribute("href", file.url || "");
	link.setAttribute("target", "_blank");
	wrap.append(link);

	if(file.mimeType.startsWith("image/") && file.url) {
		// Photoswipe
		link.onclick = async e => {
			e.preventDefault();
			const [hrefs, startIndex] = getNoteGallery(note, file.id);
			await lightboxShowHref(hrefs, startIndex);
		};
	} else if(configuration.localNotes) {
		link.setAttribute("download", file.fileName);
	}

	const filename = document.createElement("span");
	filename.innerText = file.originalFileName;
	filename.classList.add("attachment-filename");
	link.append(filename);

	const thumbnailUrl = file.thumbnailUrl || (file.mimeType.startsWith("image/") && file.url);
	if (thumbnailUrl) {
		const thumb = document.createElement("img");
		thumb.classList.add("thumbnail");
		thumb.setAttribute("src", thumbnailUrl || "");
		link.append(thumb);
	}

	return wrap;
};

const refreshAttachments = (wrap: HTMLElement, exerciseId:string, note: INote | undefined, save:(() => Promise<void>)) => {
	if(configuration.ugmNoFileUploads){
		return;
	}
	wrap.innerHTML = "";
	if(note?.files) {
		note.files.sort((a,b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).forEach(file => {
			wrap.append(createAttachmentNode(note, file, true));
		});
	}
	const label = document.createElement("label");
	label.classList.add("note-attachment");
	label.classList.add("new-attachment");
	label.innerHTML = `
	<span>${t().upload}</span>
	<input
		type="file"
		name="new-attachment"
		multiple
		hidden
		accept="${SUPPORTED_MIME_TYPES.join(', ')}"
	/>`;
	const input = label.querySelector<HTMLInputElement>("input");
	if(input){
		input.onchange = async () => {
			await save();
			const newNote = getNoteData(exerciseId);
			if(!newNote?.id){
				return;
			}

			const courseId = getCourse()?.id;
			const files = Array.from(input.files || []);
			for(const file of files){
				try {
					await addNoteFile(file, newNote.id, courseId);
					await save();
				} catch (error) {
					continue;
				}
			}
			refreshAttachments(wrap, exerciseId, getNoteData(exerciseId), save);
		};
	}
	wrap.append(label);
};

const showNote = async (exerciseId:string, noteMarker:HTMLElement) => {
	if(!configuration.localNotes && !isLoggedIn()){
		showModal(t().notesLogin);
		return;
	}
	const data = getNoteData(exerciseId);
	const noteUploads = configuration.ugmNoFileUploads ? "" : `
	<div class="note-attachment-wrap"></div>
	<p class="note-upload-hint">${t().supportedFiles}: ${SUPPORTED_FILE_EXTENSIONS.join(' | ')}</p>
	<p class="note-upload-hint">Max. 8 ${t().files}, max. 8 Mb ${t().perFile}</p>`;

	const form = `<input type="text" name="title" placeholder="${t().title}"/>
		<textarea name="content"></textarea>
		${noteUploads}
		<button type="reset" value="Löschen">${t().delete}</button>
		<button type="submit" value="Speichern">${t().save}</button>`
		+ (isTeacher() && !configuration.ugmNoNotePublishing ? `<label class="public-note"><span>${t().notesPublish}</span><input type="checkbox" name="publish"/><span class="check-mark"></span></label>` : "");
	const wrap = showModal(`<note-modal>${form}</note-modal>`);
	const attachments = wrap.querySelector<HTMLElement>(`.note-attachment-wrap`);
	const titleInp = wrap.querySelector<HTMLInputElement>(`input[name="title"]`);
	const textarea = wrap.querySelector<HTMLTextAreaElement>(`textarea[name="content"]`);
	const publishInp = wrap.querySelector<HTMLInputElement>(`input[name="publish"]`);
	const deleteBut = wrap.querySelector<HTMLButtonElement>(`button[type="reset"]`);
	const saveBut = wrap.querySelector<HTMLButtonElement>(`button[type="submit"]`);
	if(!titleInp || !textarea){
		throw new Error("Error while showing note");
	}
	titleInp.value = data?.subject || "";
	titleInp.focus();
	if(publishInp) {
		publishInp.checked = isTeacher() && data?.isPublished || false;
	}

	const saveCurrentNote = async () => {
		const defaultData = {
			id: configuration.localNotes && !isLoggedIn() ? exerciseId : "",
			createdAt: new Date(),
			updatedAt: new Date(),
			files: [] as IUploadedFile[],
			deletedAt: null
		};
		const newData = {
			exerciseId,
			subject: String(titleInp.value),
			body: String(editor.getData()),
			isPublished: isTeacher() && publishInp?.checked
		};
		const note:INote = {...defaultData, ...getNoteData(exerciseId), ...newData};
		await saveNote(note);
		noteMarker.classList.add("filled");
		noteMarker.setAttribute("note-status", publishInp?.checked ? "public" : "draft");
	};

	const editor = await initRTE(textarea, data?.body || "", `${t().addNote}`);
	if(attachments){
		refreshAttachments(attachments, exerciseId, data, saveCurrentNote);
	}

	deleteBut?.addEventListener("click", e => {
		e.preventDefault();
		const note = getNoteData(exerciseId);
		if(note && note.id){
			deleteNote(note.id);
		}
		noteMarker.classList.remove("filled");
		hideOverlay();
	});

	saveBut?.addEventListener("click", async e => {
		e?.preventDefault();
		await saveCurrentNote();
		hideOverlay();
	});
};

const refreshNoteMarker = (exerciseId:string) => {
	const marker = document.querySelector(`section[content-type-id="${exerciseId}"] > markers-left > .marker`);
	if(!marker){return;}

	const note = getNoteData(exerciseId);
	if(!note){
		marker.classList.remove("filled");
		marker.removeAttribute("note-status");
	}else{
		marker.classList.add("filled");
		marker.setAttribute("note-status", note?.isPublished ? "public" : "draft");
	}
};

const initNoteMarker = (marker:HTMLElement) => {
	const exerciseId = marker.parentElement?.getAttribute("content-type-id");
	if(!exerciseId){return;}
	const numberedMarker = marker.querySelector<HTMLElement>(".marker");
	if(!numberedMarker){return;}
	numberedMarker.onclick = () => showNote(exerciseId, numberedMarker);
	refreshNoteMarker(exerciseId);
};

const addOrUpdateNote = (note:INote) => {
	if(note.deletedAt){
		const oldNote = store.get(note.id);
		// Only remove the note if the id is the same, otherwise and old deleted notes
		// will keep us from ever adding another note to that particular exercise
		if(oldNote && (oldNote.id === note.id)){
			deleteNote(oldNote.id);
		}
	} else {
		refreshNoteMarker(note.exerciseId);
	}
};

const refreshAllNotes = () => {
	document.querySelectorAll(`section`).forEach((e) => {
		const exerciseId = e.getAttribute("content-type-id");
		if(!exerciseId){return;}
		refreshNoteMarker(exerciseId);
	});
};

const doPoll = async (after?: Date) => {
	if(isStudent()){
		return await apiGetStudentNotes(after);
	} else if(isTeacher()) {
		const courseId = getCourse()?.id;
		if(courseId){
			return await apiGetCourseNotes(courseId,after);
		}
	}
	return [];
};

const store:DataStore<INote> = new DataStore("ugm-notes", addOrUpdateNote, refreshAllNotes, doPoll);

export const initAllNoteMarkers = () => {
	if(configuration.ugmEndpoint || configuration.localNotes){
		setTimeout(() => {
			document.querySelectorAll<HTMLElement>("markers-left").forEach(initNoteMarker);
		},0);
	}
};
initAllNoteMarkers();
