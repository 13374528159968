import configuration from "../../../configuration";
import { getFirstParentElement, isMobile } from "../../_helper";
import { showModal } from "../../modal/modal";
import { showOverlay, hideOverlay, overlayCloseHandlers, isOverlayActive, canUserCloseOverlay } from "../../overlay/overlay";
import { getUser } from "../../user-group-management";
import "./navigation.css";

const initNavBar = () => {
	const navBar = document.querySelector<HTMLElement>("body > aside");
	const navButtons = document.querySelectorAll<HTMLElement>("#button-navigation, #mobile-button-navigation");

	if((!navBar) || (!navButtons)){return;}

	const navCloseButton = document.createElement("NAV-CLOSE-BUTTON");
	navBar.append(navCloseButton);
	navCloseButton.addEventListener("click", () => {
		navBar.classList.remove("active");
		navBar.setAttribute('aria-hidden', "true");
		hideOverlay();
	});
	navBar.setAttribute('aria-hidden', "true");

	const navButtonEvents = (btn:HTMLElement) => {
		btn.addEventListener("click", () => {
			if(isOverlayActive() && !canUserCloseOverlay()){
				return;
			}
			if (navBar.classList.contains("active")) {
				navBar.classList.remove("active");
				navBar.setAttribute('aria-hidden', "true");
				hideOverlay();
			} else {
				navBar.classList.add("active");
				showOverlay(navBar);
				navBar.setAttribute('aria-hidden', "false");
				if(configuration.enableSearch){
					const searchBar = navBar.querySelector<HTMLInputElement>('input[name="sword"]');
					if(searchBar){
						if(!isMobile){
							searchBar.focus();
						}
					}
				}
			}
		});
	};
	navButtons.forEach((btn:HTMLElement)=>navButtonEvents(btn));

	overlayCloseHandlers.push(() => {
		navBar.classList.remove("active");
		navBar.setAttribute('aria-hidden', "true");
	});
};
setTimeout(initNavBar, 0);

const initNavigation = () => {
	const showNavigationActive = (ele:HTMLElement, openUl = true) => {
		if((ele === null) || ((ele.tagName !== "UL") && (ele.tagName !== "LI"))){return;}
		if(ele.tagName === "UL"){
			ele.classList.remove("hidden");
		}else{
			ele.querySelector("nav-toggle")?.classList.add("active");
		}
		if(openUl){
			for(const chi of Array.from(ele.children)){
				if(chi.tagName === "UL"){
					chi.classList.remove("hidden");
					chi.setAttribute('role', 'group');
					openUl = false;
				}
			}
		}
		if(ele.parentElement){
			showNavigationActive(ele.parentElement, openUl);
		}
	};

	document.querySelectorAll<HTMLAnchorElement>("aside#navigation a").forEach(a => {
		const li = getFirstParentElement(a, "li");
		const lockState = li?.getAttribute("lock-state");
		if(lockState === "free"){return;}
		a.onclick = e => {
			const user = getUser();
			if(user){
				return;
			}
			e.preventDefault();
			showModal("Um dieses Kapitel anzuschauen müssen Sie sich vorher anmelden oder registrieren.");
		};
	});

	const toggleNav = (toggle:HTMLElement) => {
		const parentLi = toggle.parentElement?.parentElement;
		if(!parentLi){return;}
		const ul = Array.from(parentLi.children).find(t => t.tagName === "UL");
		if(!ul){return;}

		if(ul.classList.contains("hidden")){
			ul.classList.remove("hidden");
			parentLi.setAttribute('aria-expanded', "true");
			(Array.from(toggle.parentElement?.children || [])).forEach(t => {
				if((t.tagName === "A") || (t.tagName === "NAV-TOGGLE")){
					t.classList.add("active");
				}
			});
		} else {
			ul.classList.add("hidden");
			parentLi.setAttribute('aria-expanded', "false");
			(Array.from(toggle.parentElement?.children || [])).forEach(t => {
				if((t.tagName === "A") || (t.tagName === "NAV-TOGGLE")){
					t.classList.remove("active");
				}
			});
		}
	};

	const refreshNavigationList = () => {
		for(const ele of document.querySelectorAll("nav ul")){
			ele.classList.add("hidden");
		}
		for(const ele of document.querySelectorAll("nav")){
			if(ele.firstElementChild){
				ele.firstElementChild.classList.remove("hidden");
			}
		}
		for(const ele of document.querySelectorAll("nav-toggle.active")){
			ele.classList.remove("active");
		}
		const activeEle = document.querySelector<HTMLElement>("nav li.active");
		if(activeEle){
			showNavigationActive(activeEle);
		}

		if(configuration.openFirstNavigationLevel){
			const firstLevel = document.querySelectorAll("nav > ul > li > ul > li");
			for (let i = 0; i < firstLevel.length; i++) {
				firstLevel[i].firstElementChild?.firstElementChild?.classList.add("active");
				const ulHidden = firstLevel[i].querySelector("ul");
				if (ulHidden !== null) {
					ulHidden.classList.remove("hidden");
				}
			}
			const topLevel = document.querySelectorAll("nav > ul > li");
			for (let i = 0; i < firstLevel.length; i++) {
				if (!topLevel[i] || !topLevel[i].firstElementChild) { break; }
				topLevel[i].firstElementChild?.firstElementChild?.classList.add("active");
				const ulHidden = topLevel[i].querySelector("ul");
				if (ulHidden !== null) {
					ulHidden.classList.remove("hidden");
				}
			}
		}

		const activeEntry = document.querySelector("nav a.active");
		const activeTopEntry = document.querySelector("nav li.active");
		if (activeEntry !== null) {
			activeEntry.scrollIntoView({behavior: "instant", block: "center", inline: "center"});
		}
		if (activeTopEntry !== null) {
			activeTopEntry.scrollIntoView({behavior: "instant", block: "center", inline: "center"});
		}

		for(const id of configuration.navigationEntriesThatAreOpenByDefault){
			const ele = document.querySelector<HTMLElement>(`nav li[page-id="${id}"]`);
			if(ele){
				showNavigationActive(ele, true);
			}
		}
	};

	for (const link of document.querySelectorAll<HTMLElement>(`[wrap-page-type="overview"] a[page-type="overview"], nav-toggle`)) {
		link.addEventListener("click", (e:MouseEvent) => {
			e.preventDefault();
			toggleNav(link);
		});
	}

	refreshNavigationList();
	overlayCloseHandlers.push(() => {
		refreshNavigationList();
	});
};
setTimeout(initNavigation, 0);
