import type { IndexEntryJSON } from '../../../common/types';
import { fromEvent, debounceTime } from "rxjs";
import configuration from '../../configuration';

export * from './swordHighlight';
import "./search.css";
import { onEnterDo } from '../_helper';

export interface IndexEntry {
	id: string;
	url: string;
	language: string;
	contentType: string;
	subType: string;
	categories: Set<string>;
	teaser: string;
	relevance: number;
	navEntry: HTMLElement | null;
	resultWrap: HTMLElement | null;
	link: HTMLAnchorElement | null;
	title: string;
	keywords: Record<string, number>;
	parent?: IndexEntry;
	children: IndexEntry[];
}

const contentTypeRelevanceMultiplier:Record<string, number> = {
	"Glossary": 32,
	"Operators": 32,
	"Content": 4096,
	"Headline.h1": 256,
	"Headline.h2": 128,
	"Headline.h3": 64,
	"Headline.h4": 32,
	"Headline": 16,
	"TextAndImage": 3,
	"HeroImage": 2
};

const wordBlacklist = new Set<string>([
	"und",
	"oder",
	"in",
	"ein",
	"eine",
	"einen",
	"einer",
	"der",
	"die",
	"das",
	"dass"
]);

let searchIndex: Map<string, IndexEntry> | null = null;

let indexFetching = false;
let currentSword = '';
let lastSword = '';

const opened: string[] = [];

const transformIndex = (entry: IndexEntryJSON, parent?:IndexEntry): IndexEntry => {
	const navEntry = document.querySelector<HTMLElement>(`li[page-id="${entry.id}"]`);
	const resultWrap = navEntry && navEntry.querySelector<HTMLElement>(":scope > .nav-link-wrap > .search-result-wrap");
	const link = navEntry && navEntry.querySelector<HTMLAnchorElement>(":scope > .nav-link-wrap > a");
	const title = link?.innerText || "";
	const ret:IndexEntry = {
		id: entry.id,
		url: entry.url,
		language: entry.language,
		contentType: entry.contentType,
		subType: entry.subType,
		categories: new Set(entry.categories),
		teaser: entry.teaser,
		navEntry,
		resultWrap,
		link,
		title,
		keywords: entry.keywords,
		relevance: 0,
		parent,
		children: []
	};
	if(resultWrap && link){
		resultWrap.onclick = () => link.click();
	}
	ret.children = entry.children.map(c => transformIndex(c, ret));
	ret.teaser = entry.teaser + ' ' + entry.children.map(c => c.teaser).join(' ');
	return ret;
};

const fetchIndex = async () => {
	if(indexFetching){return;}
	indexFetching = true;
	const raw:IndexEntryJSON[] = await (await fetch("/search-index.json")).json();
	searchIndex = new Map();
	for(const entry of raw){
		const e = transformIndex(entry);
		searchIndex.set(e.id, e);
	}
	setTimeout(updateSearch, 0);
};

const countKeywordOccurence = (entryKeywords: Record<string, number>, searchKeywords: string[]): number => {
	return searchKeywords.reduce((acc, kw) => {
		let cur = 1;
		for(const e in entryKeywords){
			cur += (e.indexOf(kw) >= 0) ? entryKeywords[e] : 0;
		}
		const maxCur = wordBlacklist.has(kw) ? 3 : 12;
		return acc * Math.min(maxCur, cur);
	}, 1) - 1;
};

const calculateRelevance = (e: IndexEntry, searchKeywords: string[]): number => {
	const childRelevance = e.children.reduce((acc, e) => acc + calculateRelevance(e, searchKeywords), 0);
	const multiplier = contentTypeRelevanceMultiplier[`${e.contentType}.${e.subType}`] || contentTypeRelevanceMultiplier[e.contentType] || 1;
	e.relevance = Math.min(12,countKeywordOccurence(e.keywords, searchKeywords)) * multiplier + childRelevance;
	return e.relevance;
};

const highlightTeaser = (teaser:string, keywords: string[]):string => {
	let passage = '';
	for(const kw of keywords){
		if(passage){continue;}
		const i = teaser.toLowerCase().indexOf(kw);
		if(i >= 0){
			let start = i - 80;
			let end = i + 80;
			while((start > 0) && teaser.charAt(start) !== ' '){start--;}
			while((end < teaser.length) && teaser.charAt(end) !== ' '){end++;}
			start = Math.max(0, start);
			end = Math.min(teaser.length, end);
			passage = (start > 0 ? '…' : '') + teaser.substring(start,end).trim() + (end < (teaser.length-1) ? '…' : '');
		}
	}
	for(const kw of keywords){
		passage = passage.replace(new RegExp('('+kw+')', 'gi'), `<mark>$1</mark>`);
	}
	return passage;
};

const calcTreeRelevance = (results:Map<string, IndexEntry>):Map<string, number> => {
	const map = new Map();
	for(const e of results.values()){
		let i:IndexEntry | undefined = e;
		const r = e.relevance;
		while(i){
			/* Minor optimization, but mainly to prevent circular references resulting in infinite loops */
			if(r <= (map.get(i.id) || 0)){
				break;
			} else {
				map.set(i.id, r);
				const navNode = navigationMap.get(i.id);
				i = results.get(navNode?.parent?.id || "");
			}
		}
	}
	return map;
};

const updateSearch = async () => {
	await fetchIndex();
	if(!searchIndex){ return; }

	if(currentSword === lastSword){ return; }
	const sword = (currentSword || '').toLowerCase().replace(/\s+/g, ' ').trim();
	lastSword = currentSword;
	if(!sword){
		for(const entry of searchIndex.values()){
			if(entry.navEntry){
				entry.navEntry.removeAttribute('search-relevance');
			}
			if(entry.resultWrap){
				entry.resultWrap.style.maxHeight = '0';
			}
		}

		for(const openedSection of opened) {
			closeSection(openedSection);
		}
		return;
	}

	const keywords = sword.split(' ').filter(w => w.length > 2);
	Array.from(searchIndex.values()).forEach(e => e.navEntry && calculateRelevance(e, keywords));
	const maxRelevance = Math.max(1,Array.from(searchIndex.values()).reduce((acc, e) => Math.max(acc, e.relevance), 0));
	if(maxRelevance <= 0){
		return;
	}
	const treeRelevance = calcTreeRelevance(searchIndex);

	for(const entry of searchIndex.values()){
		if(entry.navEntry){
			const relevance = Math.round((entry.relevance / maxRelevance) * 4);
			entry.navEntry.setAttribute('search-relevance', String(relevance));
			entry.navEntry.setAttribute('search-relevance-raw', String(entry.relevance));
			entry.navEntry.setAttribute('search-relevance-tree', String(treeRelevance.get(entry.id) || 0));
			//entry.navEntry.setAttribute('search-relevance-raw', String(entry.relevance));
			if(entry.resultWrap) {
				if(relevance > 0){
					entry.resultWrap.innerHTML = highlightTeaser(entry.teaser, keywords);
					if(entry.link){
						entry.link.innerHTML = highlightTeaser(entry.title, keywords);
					}
					entry.resultWrap.style.maxHeight = '7em';
					expandParent(entry.id);
				} else {
					entry.resultWrap.style.maxHeight = '0';
					if(entry.link){
						entry.link.innerHTML = entry.title;
					}
				}
				const tr = Math.round(((treeRelevance.get(entry.id) || 0) / maxRelevance) * 4);
				if(tr > 0){
					expandParent(entry.id);
				} else {
					closeSection(entry.id);
				}
			}
		}
	}
};

interface NavigationNode {
	id: string,
	parent: NavigationNode | undefined,
	children: NavigationNode[],
	ul: HTMLUListElement,
	navToggle: HTMLElement
}

const navigationMap = new Map<string, NavigationNode>();

const initSearchExpand = () => {
	document.querySelectorAll(`nav[role="navigation"] li`).forEach((entry) => {
		const id = entry.getAttribute("page-id");
		if(!id) { return; }
		const parentEntryId = entry.parentElement?.parentElement?.getAttribute("page-id");
		let parent = null;
		if(parentEntryId) { parent = navigationMap.get(parentEntryId); }
		else { parent = undefined; }
		const children: NavigationNode[] = [];
		const ul = entry.getElementsByTagName("ul")[0];
		const navToggle = entry.getElementsByTagName("nav-toggle")[0];

		const navNode: NavigationNode ={
			id: id,
			parent: parent,
			children: children,
			ul: ul,
			navToggle: navToggle as HTMLElement
		};

		navigationMap.set(id, navNode);

		// Append to parents children list

		if(!parent) { return; }
		const parentNode = navigationMap.get(parent.id);

		if(!parentNode) { return; }
		parentNode.children.push(navNode);
	});
};

const expandParent = (id: string) => {
	const node = navigationMap.get(id);

	if(!node) { return; }
	openSection(node.ul, node.navToggle);
	opened.push(id);

	if(node.parent) { expandParent(node.parent.id); }
};
const openSection = (ul: HTMLUListElement, navToggle: Element) => {
	if(!ul || !ul.classList.contains("hidden")) {return;}
	ul.classList.remove("hidden");
	navToggle.classList.add("active");
};

const closeSection = (id: string) => {
	const node = navigationMap.get(id);

	if(!node || node.parent === undefined) {return;}

	if(node.ul) {
		node.ul.classList.add("hidden");
	}
	if(node.navToggle) {
		node.navToggle.classList.remove("active");
	}

};

const initSearchInput = (input: HTMLInputElement) => {
	fromEvent(input, "keyup")
		.pipe(debounceTime(50))
		.subscribe(async () => {
			currentSword = input.value;
			await updateSearch();
		});
	input.addEventListener("keydown", onEnterDo(e => e.preventDefault()));
};

const initSearchNavLink = (a: HTMLAnchorElement) => {
	a.addEventListener('click', () => {
		if(currentSword){
			if(a.href.indexOf("sword=") < 0){
				if(a.href.indexOf("?") < 0){
					a.href = a.href + `?sword=${encodeURIComponent(currentSword)}`;
				} else {
					a.href = a.href + `&sword=${encodeURIComponent(currentSword)}`;
				}
			}
		}
	});
};

const initSearch = () => {
	document.querySelectorAll(`nav[role="navigation"] a`).forEach(initSearchNavLink);
	document.querySelectorAll(`input[name="sword"]`).forEach(initSearchInput);
};
if(configuration.enableSearch){
	setTimeout(initSearch, 0);
	setTimeout(initSearchExpand, 0);
	setTimeout(fetchIndex, 100); // avoids a slow first search
}
