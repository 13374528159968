// This file contains the abstract datastore used throughout the UGM clientside
import { IBaseEntity } from "@digitale-lernwelten/ugm-client-lib";
import { isLoggedIn } from "./user-data";
import configuration from "../../configuration";

const VERSION = "2"; // Should be changed whenever we need to invalidate all old caches.

// In this interval we poll one particular endpoint after another.
// That way we lower the risk of fetching too many endpoints at once.
const stores:DataStore<IBaseEntity>[] = [];
(window as any).ugmStores = stores;

if(configuration.ugmEndpoint){
	let intervalSpinner = 0;
	let polls = 0;
	setInterval(() => {
		if(!isLoggedIn()){return;}
		if(!document.hasFocus()){
			if((++polls % 30) !== 0) { // Only poll once every minute if the tab is inactive
				return;
			}
		}
		const ds = stores[++intervalSpinner % stores.length];
		try {
			ds.poll();
		} catch {
			// Could have many reasons why this call failed, probably the wrong user type.
		}
	}, 1000);
}

export const flushAll = () => stores.forEach(t => t.flush());
export const fetchAll = () => setTimeout(() => {stores.forEach(async t => await t.poll());}, 5);
window.addEventListener("ugm-logged-in", fetchAll);
window.addEventListener("ugm-logged-out", flushAll);
window.addEventListener("ugm-course-changed", flushAll);

export class DataStore<T extends IBaseEntity> {
	private m: Map<string, T>;
	private newest_saved_entry?: Date;
	private newest_entry?: Date;

	// Here we update the newest_entry field, if necessary, and
	// then evaluate the callback provided.
	private add_or_update(t:T, mayUpdateLocalStore = true): void {
		let d: Date | undefined = undefined;
		if(t.updatedAt){
			d = new Date(t.updatedAt);
			if (!this.newest_entry || (d > this.newest_entry)) {
				this.newest_entry = d;
			}
		}
		const old = this.get(t.id);
		if(!old || !old.updatedAt || (d && (d > (new Date(old.updatedAt))))){
			this.m.set(t.id, t);
			if(mayUpdateLocalStore){
				this.save();
			}
		}
		this.add_or_update_fn(t);
	}

	// Return the key to be used for caching/storing data in localStorage.
	// This mainly changes based on whether the user is logged in or not.
	private ls_key(): string {
		return isLoggedIn() ? this.local_storage_key : 'local_only_'+this.local_storage_key;
	}

	forceSave() {
		try {
			const o = {
				version: VERSION,
				entries: Array.from(this.m.values())
			};
			localStorage.setItem(this.ls_key(), JSON.stringify(o));
		} catch(e) {
			console.error(e);
		}
	}

	// Store whatever is currently in the datastore into localstorage,
	// wrapping it in an object with the version number, so that we know
	// when to flush it.
	save(): void {
		if(this.newest_saved_entry && this.newest_entry && (this.newest_saved_entry >= this.newest_entry)){
			return;
		}
		this.forceSave();
	}

	load(): void {
		const json = localStorage.getItem(this.ls_key());
		if(json){
			try {
				const data = JSON.parse(json);
				if (!data || !data.version || !data.entries) {
					throw new Error("Incorrect cache format");
				}
				if (data.version !== VERSION) {
					throw new Error("Incorrect cache version");
				}
				// Should check the version here, and throw if different
				const entries = data.entries as T[];
				const oldNewestEntry = this.newest_entry;
				this.newest_entry = undefined as Date | undefined;
				for(const t of entries){
					this.add_or_update(t, false);
				}
				this.newest_saved_entry = this.newest_entry;
				if((oldNewestEntry instanceof Date) && (this.newest_entry instanceof Date)) {
					if(oldNewestEntry > this.newest_entry){
						this.newest_entry = oldNewestEntry;
					}
				}
			} catch (e) {
				console.error(e);
				this.flush();
			}
		}
	}

	// Poll for new data, and then fill the cache as well as call the apropriate callbacks
	async poll(): Promise<void> {
		if(!this.newest_saved_entry){
			this.load();
		}
		(await this.polling_fn(this.newest_entry)).forEach(t => {
			this.add_or_update(t, false);
		});
		this.save();
	}

	// Flush the entire cache
	flush(): void {
		this.deleteAll();
	}

	// Flush the entire cache as well as all local data
	deleteAll(): void {
		this.m.clear();
		this.newest_entry = undefined;
		this.newest_saved_entry = undefined;
		localStorage.removeItem(this.local_storage_key);
		localStorage.removeItem('local_only_'+this.local_storage_key);
		this.flush_fn();
	}

	// Get an entry by ID
	get(id:string): T | undefined {
		return this.m.get(id);
	}

	// Find an entry by using a predicate
	find(λ:((t:T) => boolean)): T | undefined {
		return Array.from(this.m.values()).find(λ);
	}

	// Delete by ID
	delete(id:string): void {
		this.m.delete(id);
		this.save();
	}

	// Store v
	set(v:T): void {
		this.m.set(v.id, v);
		this.save();
	}

	values(): IterableIterator<T> {
		return this.m.values();
	}

	size(): number {
		return this.m.size;
	}

	forEach(λ:((t:T) => void)): void {
		this.m.forEach(λ);
	}

	// Create a new cached datastore, the local_storage_key must be unique
	// since otherwise they will overwrite each others data.
	//
	// The add_or_update_fn is being called for every new datum that arrives over the network,
	// but also during initialization for everything in cache.
	//
	// The flush_fn is being called when the cache needs to be flushed, mainly because of login/logout
	// events.
	//
	// The polling_fn is responsible for getting data from the backend
	constructor(
		private local_storage_key: string,
		private add_or_update_fn: ((t:T) => void),
		private flush_fn: (() => void),
		private polling_fn: ((after?: Date) => Promise<T[]>)
	) {
		this.newest_entry = undefined;
		this.m = new Map();
		stores.push(this);
	}
}
