import {customElement, property, query} from "lit/decorators.js";
import {html, css, LitElement} from "lit";
import { hideModal } from "../../modal/modal";

@customElement("netbook-license-confirmation")
export class NetbookLicenseConfirmation extends LitElement {
	static styles = css`
		button {
			border: none;
			background-color: #2AB0D7;
			padding: 0.5rem 2rem;
			color: white;
			font-weight: bold;
			cursor: pointer;
		}

		h1 {
			line-height: 1.1em;
		}
	`;

	@property()
		redirectTarget = window.location.origin;

	@query("#redirect")
		btn: HTMLButtonElement;

	private _backClick() {
		hideModal();
		window.location.replace(this.redirectTarget);
	}

	render() {
		return html`
			<h1>Vielen Dank für Ihre Bestellung!</h1>
			<p>Wir versenden in Kürze eine Rechnung an die von Ihnen angegebene E-Mail-Adresse.<br>Wir wünschen Ihnen viel Freude mit dem netBook Deutsch.</p>
			<button @click="${this._backClick}" id="redirect">Zurück zum netBook Deutsch</button>
		`;
	}
}
