// We have to preserve the old scrollTop because we disable scrolling
// while an overlay is acive.
let oldScrollTop   = 0;
let overlayActive  = false;
let overlayElement:HTMLElement | null = null;
let overlayUserCantClose = false;
export const overlayCloseHandlers:Array<() => void> = [];
import "./overlay.css";

export const showOverlay = (blurException:HTMLElement, userCantClose = false) => {
	if(!overlayElement){return;}
	overlayElement.setAttribute("open","open");
	overlayElement.classList.add("active");
	overlayUserCantClose = userCantClose;
	if(userCantClose){
		document.body.classList.add("unclosable-modal-active");
	}

	if(!overlayActive){
		oldScrollTop = document.documentElement.scrollTop|0;
		document.body.style.top = (-oldScrollTop)+"px";
	}
	overlayActive = true;
	// This needs to be last, because as soon as we add this class scrolling is disabled, setting scrollTop to 0
	document.body.classList.add("modal-active");
	document.querySelectorAll("main > *").forEach(child => {
		if(child === blurException){
			child.classList.remove("overlay-blur");
		}else{
			child.classList.add("overlay-blur");
		}
	});
};

export const isOverlayActive = () => overlayActive;
export const canUserCloseOverlay = () => !overlayUserCantClose;

export const callOverlayCloseHandlers = () => {
	for(const cb of overlayCloseHandlers){cb();}
};

export const forceHideOverlay = () => {
	if((!overlayActive) || (!overlayElement)){return;}
	overlayElement.removeAttribute("open");
	callOverlayCloseHandlers();

	document.querySelectorAll("main > *").forEach(child => {
		child.classList.remove("overlay-blur");
	});

	// Now it needs to be first, because otherwise there is nowhere to scroll to
	document.body.classList.remove("modal-active");
	document.documentElement.scrollTop = oldScrollTop;
	document.body.style.top = "";
	overlayActive = false;
	overlayUserCantClose = false;
	document.body.classList.remove("unclosable-modal-active");
};

export const hideOverlay = () => {
	if(overlayActive && overlayUserCantClose){
		return;
	}
	forceHideOverlay();
};

const initOverlay = () => {
	overlayElement = document.querySelector("PAGE-OVERLAY");
	if(!overlayElement){
		throw new Error("Can't query <page-overlay>");
	}
	overlayElement.addEventListener("click", hideOverlay);
	// This is important so we can set scrollTop before leaving the site
	// because most browsers save the scrollTop position and restore it when using the history
	addEventListener("beforeunload",hideOverlay);

	// active class should be removed last, since it sets the z-index
	overlayElement.addEventListener("transitionend",e => {
		if(e.propertyName !== 'opacity')                 {return;}
		if(!overlayElement){return;}
		if(overlayElement.getAttribute("open") !== null) {return;}
		overlayElement.classList.remove("active");
	});
};
setTimeout(initOverlay, 0);

