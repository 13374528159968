import { showModal } from "../../modal/modal";
import { showEmbeddingSections } from "../embedding";
import { hideElementContentHandler, isFullscreen, closeFullscreen, openFullscreen } from "../../_helper";

import "./support-slider.css";

export const initSupportSlider = (box:HTMLElement) => {
	const tabContentWrap = box.querySelector<HTMLElement>("tab-box-content-wrap");
	if(!tabContentWrap){return;}
	const tabContent     = Array.from(tabContentWrap.querySelectorAll<HTMLElement>("tab-box-content"));
	const startSlide     = document.createElement("START-SLIDE");
	const endSlide       = document.createElement("END-SLIDE");
	const controlWrap    = document.createElement("CONTROL-WRAP");

	const showLicense = () => {
		const html:string[] = [];
		for(let i=0;i<tabContent.length;i++){
			const licenses = Array.from(tabContent[i].querySelectorAll("details.license"))
				.map(c => `<div>${c.querySelector("license-content")?.innerHTML}</div>`)
				.join("");

			const sources = Array.from(tabContentWrap.querySelectorAll(`tab-box-source[tab-index="${i}"]`))
				.map(source => `<div>${source.innerHTML}</div>`)
				.join("");

			if(licenses || sources){
				html.push(`<h2>${i+1}</h2>${licenses}${sources}`);
			}
		}
		showModal(html.join("<hr/>") || `<div>Urheber: Digitale Lernwelten GmbH</div>`);
	};

	const button = document.createElement("BUTTON");
	button.setAttribute("button-type","license");
	button.addEventListener("click",showLicense);
	tabContentWrap?.parentElement?.append(button);


	startSlide.addEventListener("click", e => {
		e.stopPropagation();
		showSlide(0);
	});

	endSlide.addEventListener("click",e => {
		e.stopPropagation();
		showSlide(-1);
	});

	controlWrap.addEventListener("click",e => {
		e.stopPropagation();
		if(curSlide === -1){
			showSlide(0);
		} else if(curSlide === tabContent.length){
			showSlide(-1);
		}
	});

	const controlExit = document.createElement("BUTTON");
	controlExit.setAttribute("button-function","exit");
	controlExit.addEventListener("click", e => {
		e.stopPropagation();
		showSlide(-1);
	});
	controlWrap.append(controlExit);

	const controlMedia = document.createElement("BUTTON");
	controlMedia.innerText = "Vorlesen";
	controlMedia.setAttribute("button-function","media");
	controlMedia.addEventListener("click", e => {
		e.stopPropagation();
		playMedia(curSlide);
	});
	controlWrap.append(controlMedia);

	const controlPrevious = document.createElement("BUTTON");
	controlPrevious.setAttribute("button-function","previous");
	controlPrevious.addEventListener("click",e => {
		e.stopPropagation();
		showSlide(curSlide - 1);
	});
	controlWrap.append(controlPrevious);

	const sliderInfo = document.createElement("SLIDER-INFO");
	function refreshPageInfo(){
		sliderInfo.innerHTML = `${curSlide + 1} / ${tabContent.length}`;
	}
	controlWrap.append(sliderInfo);

	const controlNext = document.createElement("BUTTON");
	controlNext.setAttribute("button-function","next");
	controlNext.addEventListener("click",e => {
		e.stopPropagation();
		showSlide(curSlide + 1);
	});
	controlWrap.append(controlNext);

	tabContentWrap.prepend(startSlide);
	tabContentWrap.append(endSlide);
	tabContentWrap.append(controlWrap);
	let curSlide = -1;
	let mediaPlaying:HTMLMediaElement | null = null;

	const playMedia = (i:number) => {
		if((i < 0) || (i >= tabContent.length)){return;}
		if(mediaPlaying){
			if(mediaPlaying.paused){
				mediaPlaying.play();
				controlMedia.classList.add('playing');
			}else{
				mediaPlaying.pause();
				controlMedia.classList.remove('playing');
			}
			return;
		}
		const slide = tabContent[i];
		const audio = document.createElement("audio");
		audio.setAttribute("src",slide.getAttribute('tab-media') || "");
		audio.addEventListener("ended", () => {
			audio.remove();
			controlMedia.classList.remove('playing');
			mediaPlaying = null;
		});
		controlMedia.classList.add('playing');
		audio.play();
		mediaPlaying = audio;
	};

	const removeActiveClassFromAllSlides = () => {
		for(const cContent of tabContent){
			cContent.classList.remove('active');
			hideElementContentHandler(cContent);
		}
		startSlide.classList.remove('active');
		endSlide.classList.remove('active');
		box.classList.remove("start-slide-active");
		box.classList.remove("end-slide-active");
		box.classList.remove("has-media");
		if(mediaPlaying){
			mediaPlaying.pause();
			mediaPlaying.remove();
			mediaPlaying = null;
			controlMedia.classList.remove('playing');
		}
	};

	const centerSlide = (i:number) => {
		const content = tabContent[i];
		content.style.paddingTop = '';
		const maxHeight = (tabContentWrap.clientHeight - controlWrap.offsetHeight);
		const curHeight = content.scrollHeight;
		if(curHeight >= maxHeight){
			content.style.paddingTop = '';
		}else{
			content.style.paddingTop = `${(maxHeight - curHeight) / 2}px`;
		}
	};

	const resizeSlide = (i:number) => {
		if((i < 0) || (i >= tabContent.length)){return;}
		const content = tabContent[i];
		const maxHeight = (tabContentWrap.clientHeight - controlWrap.offsetHeight) * 0.9;
		let fs = 2.55;
		for(let step = 1.0; step > 0.01; step *= 0.5){
			content.style.fontSize = `${fs}em`;
			const curHeight = content.scrollHeight;
			if(curHeight > maxHeight){
				fs -= step;
			}else{
				fs += step;
			}
		}
		if(fs < 1.1){fs = 1;}
		if(fs > 4.0){fs = 4;}
		content.style.fontSize = `${fs}em`;
		content.querySelectorAll<HTMLElement>(`figure[figure-type="picture"][figure-width="100"] img`)
			.forEach(img => {img.style.maxHeight = `${maxHeight}px`;});
		centerSlide(i);
	};

	const showStartSlide = () => {
		startSlide.classList.add('active');
		box.classList.add("start-slide-active");
		closeFullscreen();
		curSlide = -1;
	};

	const showEndSlide = () => {
		endSlide.classList.add('active');
		box.classList.add("end-slide-active");
		closeFullscreen();
		curSlide = tabContent.length;
	};

	const showSlide = (i:number) => {
		removeActiveClassFromAllSlides();
		if(i < 0){
			showStartSlide();
		}else if(i >= tabContent.length){
			showEndSlide();
		}else{
			if(curSlide < 0){openFullscreen(tabContentWrap);}
			const cContent = tabContent[i];
			cContent.classList.add('active');
			showEmbeddingSections(cContent);
			const media = cContent.getAttribute("tab-media");
			if(media && media !== ""){
				box.classList.add("has-media");
			}
			curSlide = i;
			resizeSlide(curSlide);
		}
		refreshPageInfo();
	};
	showSlide(-1);

	/* Reset to start-slide when leaving fullscreen mode */
	document.addEventListener("fullscreenchange", () => {
		if((curSlide < 0) || (curSlide >= tabContent.length)){return;}
		if(isFullscreen()){
			resizeSlide(curSlide);
			return;
		}
		if(curSlide === tabContent.length){return;}
		showSlide(-1);
	});
	document.addEventListener("resize", () => {
		if((curSlide < 0) || (curSlide >= tabContent.length)){return;}
		resizeSlide(curSlide);
	});
	tabContent.forEach( (content,index) => {
		content.addEventListener("content-resize", e => {
			e.stopPropagation();
			if(index === curSlide){
				resizeSlide(curSlide);
			}
		});
	});
};
