import { apiGetCourseById, apiGetSelf, apiJoinCourse, apiLogout, apiUpdateStudentAccessKey, TCourse } from "@digitale-lernwelten/ugm-client-lib";
import configuration from "../../../configuration";
import { getCourse, isTeacher } from "../user-data";

import './teacherHeader.css';
import { showModal } from "../../modal/modal";
import { getParam } from "../../_helper";
import { hideOverlay } from "../../overlay/overlay";
import { ugmCheckStatus } from "../user-group-management";
import QRCode from "qrcode";

const genJoinCourseLink = (course: TCourse, url?: string): string => {
	if (!course.studentAccessKey) {
		throw Error("Course is missing an access key");
	}
	if (!url) {
		return genJoinCourseLink(course, document.location.origin + document.location.pathname);
	}
	if (url.indexOf("?") >= 0) {
		return genJoinCourseLink(course, url.substring(0, url.indexOf("?")));
	}
	return `${url}?joinCourseId=${encodeURIComponent(course.id)}&joinCourseKey=${encodeURIComponent(course.studentAccessKey)}&courseName=${encodeURIComponent(course.name)}`;
};

const initCopyButton = (e:HTMLElement) => {
	e.onclick = () => {
		const input = e.getAttribute("data-input");
		if(!input){
			return;
		}
		navigator.clipboard.writeText(input);
		e.classList.add("copied");
	};
};

export const initAllCopyButtons =
	() => document.querySelectorAll<HTMLElement>(".copy-button").forEach(initCopyButton);

export const showJoinCourseModal = async () => {
	if(!isTeacher()){
		throw Error("Can't show join course modal when not logged in as a teacher");
	}
	let course = getCourse();
	try {
		if(course){
			course = await apiGetCourseById(course.id);
		} else {
			throw Error("No course found");
		}
		if(!course.studentAccessKey){
			course = await apiUpdateStudentAccessKey(course.id);
		}
		if(!course.studentAccessKey){
			throw Error("Can't update studentAccessKey");
		}
	} catch {
		showModal(`
			<div class="join-course-teacher-modal join-course-modal">
		<h3>Bitte erstellen Sie zuerst eine Klasse</h3>
		<p>Um mit dem unterrichten zu beginnen brauchen Sie zuerst eine Klasse, bitte erstellen Sie diese mittels der Klassenverwaltung.</p>
		</div>`);
		return;
	}
	const url = genJoinCourseLink(course);

	const qrcode = await QRCode.toDataURL(url);

	showModal(`<div class="join-course-teacher-modal join-course-modal">
		<h3>Unterricht jetzt beginnen</h3>
		<p>Mit folgendem QR-Code k&ouml;nnen Ihre Sch&uuml;lerinnen und Sch&uuml;ler der Stunde beitreten.</p>
		<img class="qrcode" src="${qrcode}"/>

		<p>Alternativ k&ouml;nnen Sie auch folgenden Link verwenden:</p>
		<div class="join-course-modal-copy-wrap">
			<fancy-text-input type="text" readonly value="${url}"></fancy-text-input>
			<button class="copy-button" data-input="${url}">Kopieren</button>
		</div>
		</div>
	`);
	initAllCopyButtons();
};

const joinCourse = async (id:string, key:string, studentName:string, password: string) => {
	try {
		if(await apiGetSelf()){
			await apiLogout();
		}
	} catch {
		/* If we weren't logged in in the first place it doesn't matter */
	}
	localStorage.setItem("ugm-join-course-name", studentName);
	try {
		await apiJoinCourse(id, key, studentName, password);
		await ugmCheckStatus();
		return true;
	} catch {
		return false;
	}
};

const joinCourseURL = async () => {
	const id = getParam("joinCourseId");
	const key = getParam("joinCourseKey");
	const name = getParam("courseName");

	if(!id || !key || !name){
		throw new Error("Invalid join course url");
	}

	const modal = showModal(`<div class="join-course-student-modal join-course-modal">
		<h3><span class="className"><span></h3>
		<p>Um der Stunde beizutreten, musst du noch deinen Namen eingeben, damit deine Lehrkraft weiß wer du bist. Wähle dann ein Passwort, mit dem du dich in Zukunft einloggen möchtest.</p>
		<form>
			<input placeholder="Dein Name" type="text" name="studentName"/>
			<input placeholder="Passwort" type="password" name="password"/>
			<p class="errorMessages"></p>
			<input type="submit" value="Beitreten"/>
		</form>
		</div>
	`);
	modal.querySelectorAll<HTMLElement>(".className").forEach(e => {
		e.innerText = name;
	});
	const form = modal.querySelector<HTMLFormElement>("form");
	const studentNameInput = modal.querySelector<HTMLInputElement>(`input[name="studentName"]`);
	const passwordInput = modal.querySelector<HTMLInputElement>(`input[name="password"]`);
	const errorMessagesElement = modal.querySelector<HTMLElement>(`p.errorMessages`);
	if(!studentNameInput || !passwordInput || !errorMessagesElement || !form){
		throw new Error("Cant find studentName input field or password input field or form");
	}

	const localStorageName = localStorage.getItem("ugm-join-course-name") || '';
	if(localStorageName){
		studentNameInput.value = localStorageName;
	}
	form.onsubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		const studentName = studentNameInput.value;
		const password = passwordInput.value;
		if(!studentName){
			errorMessagesElement.innerText = "Bitte geb deinen Namen ein.";
			return;
		}
		if(!password){
			errorMessagesElement.innerText = "Bitte geb dein Password ein.";
			return;
		}

		errorMessagesElement.innerText = "";
		const success = await joinCourse(id, key, studentName, password);
		if(success){
			hideOverlay();
		} else {
			errorMessagesElement.innerText = "Fehler beim Beitreten, bitte stelle sicher, dass dein Passwort korrekt ist.";
		}
	};
};

export const initPasswordlessStudentsTeacherHeader = () => {
	if(!configuration.studentsWithoutPasswords){
		return;
	}
	document.querySelectorAll<HTMLElement>("button.join-course-button").forEach(but => {
		but.onclick = showJoinCourseModal;
	});

	if(getParam("joinCourseId")){
		joinCourseURL();
	}
};
setTimeout(initPasswordlessStudentsTeacherHeader, 0);
