import type { IStudent } from '@digitale-lernwelten/ugm-client-lib';
import { getLastParentSection, hideElementContentHandler, scrollToElement } from '../../_helper';
import { getResultSuccessPercentage, getUser, getUserResults, isLoggedIn, isTeacher } from '../../user-group-management';
import { XAPIEvent, getPageLearningGoal, getWorkingTaskTasks } from '../tab-box';
import { compareTaskId, getLearningGoal } from '../training';
import { mapResultPercentage, showTeacherWorkingTaskOverview } from '../training/resultOverview';

import './working-task.css';
import { isDevMode } from '../../dev-tools';

interface ScaffoldingElements {
	wrap: HTMLElement;
	innerWrap: HTMLElement;
}

interface WorkingTask {
	i: number;
	wrap: HTMLElement;
	header: HTMLElement;
	content: HTMLElement;
	innerContent: HTMLElement;
	taskId: string;
	results: HTMLElement | null;
	scaffolding: ScaffoldingElements[];
	scaffoldingWrap: HTMLElement;
	scaffoldingLevel: number;
	scaffoldingPrev: HTMLElement;
	scaffoldingNext: HTMLElement;
}

const uncompleteableContentTypes = new Set([
	"H5P.Dialogcards",
	"H5P.ImageJuxtaposition",
	"H5P.AudioRecorder",
	"H5P.ImageHotspots",
	"H5P.BlankTable",
	"H5P.Accordion",
	"H5P.Dialogcards",
	"H5P.CoursePresentation"
]);

const refreshCompletionScreenTeacher = async (ele: HTMLElement) => {
	ele.innerHTML = `<button class="working-task-result-button">Klassenergebnisse auswerten</button>`;
	const but = ele.querySelector<HTMLButtonElement>("button.working-task-result-button");
	if(!but){
		throw new Error("Can't query button.working-task-result-button");
	}
	const wrap = getLastParentSection(ele);
	if(!wrap){
		throw new Error("Can't determine last parent section");
	}
	but.onclick = () => showTeacherWorkingTaskOverview(wrap);
};

const refreshCompletionScreenStudent = async (ele: HTMLElement, tasks: string[], wrap: HTMLElement) => {
	const cat = getPageLearningGoal();
	const goal = await getLearningGoal(cat);
	if(!goal){
		throw new Error(`Unknown Learning Goal ${cat}`);
	}
	const user = getUser() as IStudent;
	const raw = getUserResults(user, goal);
	const taskData = getWorkingTaskTasks(wrap);
	const res = tasks.map(t => {
		const events = raw.events.filter(e => compareTaskId(e.taskId, t));
		if(events.length <= 0){
			return null;
		}
		const event = events[events.length-1];
		const percent = getResultSuccessPercentage([event]);
		return {
			percent,
			tries: events.length,
			taskId: t
		};
	});
	const resLen = res.reduce((a,b) => a + (b ? 1 : 0),0);
	const totalPercent = res.reduce((a,b) => a + (b?.percent || 0), 0) / (resLen || 1);
	const totalTries = res.reduce((a,b) => a + (b?.tries || 0), 0);
	const finished = res.every(c => c?.tries);
	const userResult = {
		name: user.reminder || user.loginName,
		id: user.id,
		finished,
		res,
		totalPercent,
		totalTries
	};

	const thead = tasks.map((v,i) => `<th>${i+1}</th>`).join("") + "<th>Gesamt</th>";
	const tbody = '<tr>' + tasks.map((v,i) => {
		const r = res[i];
		const t = taskData.tasks[i];

		switch(t?.type){
		default:
		case "embedding":
			if(r){
				if(r.tries <= 0){
					return `<td class="${mapResultPercentage(r.tries, r.percent)}"></td>`;
				} else {
					return `<td class="${mapResultPercentage(r.tries, r.percent)}">${Math.round(r.percent)}&nbsp;% ${r.tries}&nbsp;Versuch${r.tries > 1 ? "e" : ""}</td>`;
				}
			} else {
				return `<td class="studentResultNil"></td>`;
			}
		case "exercise":
			return `<td class="task-exercise">Text</td>`;
		case "info":
			return `<td class="task-info">Info</td>`;
		}
	}).join("") + `<td>${Math.round(userResult.totalPercent)}&nbsp;%</td></tr>`;

	const html = `<h5>Dein Ergebnis:</h5>
		<table class="workingTaskResultOverview">
			<thead><tr>
				${thead}
			</tr></thead>
			<tbody>
				${tbody}
			</tbody>
		</table>
	`;
	ele.innerHTML = html;
};

const refreshCompletionScreen = async (ele: HTMLElement, tasks: string[], wrap: HTMLElement) => {
	ele.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
	if(!isLoggedIn()){
		ele.innerHTML = "<h3>Super, du hast alle Aufgaben erledigt!</h3><p>Um eine genauere Auswertung zu sehen, logge dich bitte ein.</p>";
		return;
	}
	if(isTeacher()){
		refreshCompletionScreenTeacher(ele);
	} else {
		refreshCompletionScreenStudent(ele, tasks, wrap);
	}
};

const initWorkingTask = (workingTaskWrap:HTMLElement) => {
	const tasks:WorkingTask[] = [];
	let activeTask:WorkingTask | null = null;
	const boxType = workingTaskWrap.getAttribute("box-type") || "workingtasks";

	const refreshScaffolding = (task:WorkingTask) => {
		task.scaffoldingLevel = Math.max(0, Math.min(task.scaffolding.length, task.scaffoldingLevel));
		for(let i=0;i<task.scaffolding.length;i++){
			if(i === (task.scaffoldingLevel-1)){
				task.scaffolding[i].wrap.classList.add("active");
				const h = task.scaffolding[i].innerWrap.scrollHeight;
				task.scaffolding[i].wrap.style.height = `${h}px`;
			} else {
				task.scaffolding[i].wrap.classList.remove("active");
				task.scaffolding[i].wrap.style.height = "0";
			}
		}

		if(task.scaffoldingLevel <= 1){
			task.scaffoldingPrev.classList.add("hidden");
		} else {
			task.scaffoldingPrev.classList.remove("hidden");
		}
		if(task.scaffoldingLevel >= task.scaffolding.length){
			task.scaffoldingNext.classList.add("hidden");
		} else {
			task.scaffoldingNext.classList.remove("hidden");
		}

		task.scaffoldingWrap.setAttribute("active-level", String(task.scaffoldingLevel));
	};

	const showTask = async (task:WorkingTask, scroll=true) => {
		if(activeTask === task){
			activeTask = null;
		} else {
			activeTask = task;
		}

		tasks.forEach(async (t,i) => {
			if(t === activeTask){
				if(task.results){
					await refreshCompletionScreen(task.results, tasks.filter(t => !t.results).map(t => t.taskId), workingTaskWrap);
				}
				task.wrap.classList.remove("locked");
				task.wrap.classList.add("active");
				if(tasksFinished[i]){
					task.wrap.classList.add("done");
				}
				if(scroll){
					scrollToElement(task.header, 0);
					const newHeight = task.content.scrollHeight;
					task.content.style.height = `${newHeight}px`;
					setTimeout(() => {
						task.content.style.height = `auto`;
					}, 1000);
				} else {
					task.content.style.height = `auto`;
				}
				refreshScaffolding(task);
			} else {
				const newHeight = t.content.scrollHeight;
				t.content.style.height = `${newHeight}px`;
				t.content.getBoundingClientRect();
				t.content.style.height = '0';
				t.wrap.classList.remove("active");
				t.scaffoldingLevel = 0;
				refreshScaffolding(t);
				hideElementContentHandler(t.scaffoldingWrap);
			}
		});
	};

	const switchToTask = (i:number) => {
		const task = tasks[Math.max(0, Math.min(tasks.length, i))];
		showTask(task);
	};

	const scaffoldingShowPrev = () => {
		if(!activeTask){return;}
		activeTask.scaffoldingLevel--;
		refreshScaffolding(activeTask);
	};

	const scaffoldingShowNext = () => {
		if(!activeTask){return;}
		activeTask.scaffoldingLevel++;
		refreshScaffolding(activeTask);
	};

	for(const wrap of workingTaskWrap.children){
		const header = wrap.querySelector<HTMLElement>("working-task-header");
		const content = wrap.querySelector<HTMLElement>("working-task-content");
		const innerContent = wrap.querySelector<HTMLElement>("working-task-inner-content");
		if(!header || !content || !innerContent){
			throw new Error("Invalid working task DOM");
		}

		const scaffoldingWrap = document.createElement("scaffolding-wrap");
		scaffoldingWrap.setAttribute("active-level", "0");
		innerContent.prepend(scaffoldingWrap);
		const results = wrap.querySelector<HTMLElement>("working-task-results");
		const i = tasks.length;
		header.onclick = () => switchToTask(i);
		const taskId = content.querySelector<HTMLElement>(`section[content-type="embedding"] lazy-iframe`)?.getAttribute("src") || "";

		const scaffoldingMap:Map<string, HTMLElement[]> = new Map();
		for (const c of content.querySelectorAll(`section[scaffolding]`)) {
			const level = c.getAttribute("scaffolding") || "0";
			if(level === "0"){continue;}
			if(!scaffoldingMap.has(level)){
				scaffoldingMap.set(level, []);
			}
			scaffoldingMap.get(level)?.push(c as HTMLElement);
		}

		const scaffolding = Array.from(scaffoldingMap.entries())
			.sort((a,b) => a[0].localeCompare(b[0]))
			.map(m => {
				const wrap = document.createElement("scaffolding-content");
				wrap.setAttribute("scaffolding-level", m[0]);
				const innerWrap = document.createElement("scaffolding-inner-content");
				wrap.append(innerWrap);
				for(const c of m[1]){
					innerWrap.append(c);
				}
				scaffoldingWrap.append(wrap);
				return {wrap,innerWrap};
			});
		const scaffoldingButtonWrap = document.createElement("scaffolding-button-wrap");
		scaffoldingWrap.append(scaffoldingButtonWrap);

		const scaffoldingPrev = document.createElement("scaffolding-prev");
		scaffoldingPrev.onclick = scaffoldingShowPrev;
		scaffoldingPrev.innerText = "vorherige Hilfe";
		scaffoldingButtonWrap.append(scaffoldingPrev);

		const scaffoldingNext = document.createElement("scaffolding-next");
		scaffoldingNext.onclick = scaffoldingShowNext;
		scaffoldingNext.innerText = "Hilfe";
		scaffoldingButtonWrap.append(scaffoldingNext);
		wrap.classList.add("locked");

		tasks.push({
			i,
			wrap:wrap as HTMLElement,
			header,
			content,
			innerContent,
			taskId,
			results,
			scaffolding,
			scaffoldingLevel: 0,
			scaffoldingPrev,
			scaffoldingNext,
			scaffoldingWrap
		});
	}
	if(tasks.length <= 0){
		return;
	}

	const tasksFinished = tasks.map((t) => {
		return isTeacher() || boxType === "akkordion" ||  !t.content.querySelector("lazy-iframe, iframe"); // If there's no iframe then we won't get any xAPI Events
	});

	if(boxType === "akkordion"){
		tasks.forEach(task => {
			task.wrap.classList.add("done");
			task.wrap.classList.remove("locked");
		});
	}

	for(let i=0;i<tasks.length;i++){
		const task = tasks[i];
		task.content.addEventListener("xAPI", (raw:CustomEvent) => {
			const e = raw.detail as XAPIEvent;
			if((e.verb === "answered") || (e.verb === "completed")){
				tasksFinished[i] = true;
				task.wrap.classList.add("done");
				//refreshButtonVisibility();
			}
		});
		task.content.addEventListener("iframeMessage", (raw:CustomEvent) => {
			const msg = raw.detail?.data || {};
			if(msg.T === "ActiveContentTypes"){
				for(const t of msg.types || []){
					if(isDevMode()){
						console.log(t);
					}
					if(uncompleteableContentTypes.has(t)){
						tasksFinished[i] = true;
						break;
					}
				}
			}
		});
	}

	showTask(tasks[0],false);
};

export const initAllWorkingTasks = () => {
	document.querySelectorAll<HTMLElement>("working-task-wrap").forEach(initWorkingTask);
};
setTimeout(initAllWorkingTasks, 0);
