import {customElement, property, query} from "lit/decorators.js";
import {css, html, LitElement} from "lit";
import {apiCreateTeacher} from "@digitale-lernwelten/ugm-client-lib";
import {hideModal, showModal} from "../../modal/modal";
import {showLoginModal} from "../../user-group-management";
import configuration from "../../../configuration";

@customElement("register-modal")
export class RegisterModal extends LitElement {
	static styles = css`
		form {
			width: 100%;
			display: block;
			max-width: 400px;
			margin: 0 auto;
		}

		label {
			margin-bottom: 0.25rem;
			display: block;
			user-select: none;
		}

		input {
			display: block;
			width: 100%;
			padding: 0.25em 0.5em;
			background: #fff;
			font-size: 1em;
			margin-bottom: 2rem;

			box-sizing: border-box;
		}

		input[type="submit"] {
			display: block;
			cursor: pointer;
			background-color: var(--dlw-blue);
			color: #fff;
			border-radius: 0.25rem;
			font-size: 1em;
			padding: 0.5rem 1rem;
			width: 100%;
			appearance: none;
			border: none;
			transition: background-color 250ms;
			margin-bottom: 1rem;
			text-align: center;
			text-decoration: none;
			font-weight: regular;
			font-family: var(--font-content);
		}

		input[type="submit"]:focus {
			background-color: var(--dlw-blue-light);
		}

		a {
			font-size:0.7em;
			text-decoration: underline;
			color: rgb(var(--highlight-color));
			cursor: pointer;
		}

		#error-message {
			color: #E3000F;
		}
	`;

	@property()
		email = '';

	@property()
		loginName = '';

	@property()
		validEmail = true;

	@property()
		institutionCode = '';

	@property()
		validLoginName = true;

	@property()
		registerNameHint = html`Wählen Sie ein Kürzel für Ihre Schule/Institution, bestehend aus drei bis acht Zeichen (Buchstaben und/oder Zahlen).<br/>Sie können sich das Kürzel frei aussuchen. Es ist Teil der Nutzernamen, mit denen sich Ihre Schülerinnen und Schüler anmelden.<br/>Es kann nachträglich nicht mehr geändert werden.<br/>Wir empfehlen ein Kürzel, das mit Ihrem Namen, Ihren Schulfächern oder Ihrer Schule in Verbindung steht.`;

	@query('input[id="email"]')
		_email: HTMLInputElement;

	@query('input[id="username"]')
		_username: HTMLInputElement;

	@query('#error-message')
		_error: HTMLSpanElement;

	_handleInput() {
		this.email = this._email.value;
		this.loginName = this._username.value;
	}

	async _registerUser(e: Event) {
		e.preventDefault();

		if(!this.email && this.email.includes("@")) {
			this.validEmail = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			return;
		}

		if(!this.loginName) {
			this.validLoginName = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			return;
		}

		try {
			await apiCreateTeacher(this.email, this.loginName, undefined, this.institutionCode ? this.institutionCode : undefined);
			showModal(`<h1>Vielen Dank für Ihre Registrierung!</h1><p>Wir haben eine E-Mail an <strong>${this.email}</strong> geschickt.<br/>Klicken Sie auf den Link in der E-Mail, um die Registrierung abzuschließen.</p>`, "centered-box", false);
		} catch (err) {
			console.error(err);
			let errorMessage = "";
			switch (err.errorCode) {
			case "emailInUse":
				errorMessage = "Die E-Mail-Adresse ist bereits vergeben.";
				break;
			case "loginNameInUse":
				errorMessage = "Das Kürzel ist bereits vergeben.";
				break;
			case "teacherAlreadyExists":
				errorMessage = "Der Lehrer existiert bereits.";
				break;
			default:
				errorMessage = "Die Registrierung war nicht erfolgreich, bitte versuchen Sie es erneut.";
			}
			this._error.innerHTML = errorMessage;
			this._error.classList.remove("hide");
		}
	}

	_switchToLogin() {
		hideModal();
		showLoginModal();
	}

	render() {
		const emailPlaceholder = configuration.activeTheme === "shaky_netbook"
			? `E-Mail-Adresse`
			: `Schulportal-E-Mail-Adresse`;

		const registerEmailHint = configuration.activeTheme === "shaky_netbook"
			? html`Bitte geben Sie Ihre E-Mail-Adresse an.<br/><br/>Es wird nur Ihre E-Mail-Adresse erfasst, Schülerinnen und Schüler benötigen keine E-Mail-Adresse.`
			: html`Bitte geben Sie Ihre Schulportal-E-Mail-Adresse an.<br/>Dies erlaubt uns, automatisiert zu überprüfen, ob Sie eine Lehrkraft an einer Schule oder Institution in Sachsen sind.<br/>Es wird nur Ihre E-Mail-Adresse erfasst, Schülerinnen und Schüler benötigen keine E-Mail-Adresse.`;

		const prefix = this.institutionCode ? "Bitte geben Sie ihre E-Mail und einen Benutzernamen an um sich für Ihre Institution zu registrieren." : "";
		return html`
			<p>${prefix}</p>
			<form @submit=${this._registerUser}>
				<span id="error-message" class="hide"></span>
				<label for="email">
					E-Mail
					<hint-label tabindex="-1"><span>${registerEmailHint}</span></hint-label>
				</label>
				<input id="email" type="email" placeholder="${emailPlaceholder}" value=${this.email} @input=${this._handleInput} data-valid=${this.validEmail}/>

				<label for="username">
					Benutzername
					<hint-label tabindex="-1"><span>${this.registerNameHint}</span></hint-label>
				</label>
				<input id="username" type="text" placeholder="Ihr selbst gewähltes Kürzel" value=${this.loginName} @input=${this._handleInput} data-valid=${this.validLoginName}/>
				<input type="submit" value="Registrieren" />

				<br>
				<a @click="${this._switchToLogin}">Sie haben bereits einen Account ? Melden Sie sich hier an!</a>
			</form>

		`;
	}
}
